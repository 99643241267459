@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "primeflex/primeflex.css";
@import 'material-icons/iconfont/material-icons.css';
:root {
  --surface-a:#ffffff;
  --surface-b:#efefef;
  --surface-c:#e9ecef;
  --surface-d:#dee2e6;
  --surface-e:#ffffff;
  --surface-f:#ffffff;
  --text-color:#8A8A8A;
  --text-color-secondary:#6c757d;
  --primary-color:#4394D8;
  --primary-color-text:#ffffff;
  --font-family:-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

/* Add your variable customizations of theme here */
* {
  box-sizing: border-box;
}

.p-component {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.15s;
}

.p-disabled, .p-component:disabled {
  opacity: 0.65;
}

.p-error {
  color: #dc3545;
}

.p-text-secondary {
  color: #6c757d;
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: 4px;
}

.p-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-autocomplete .p-autocomplete-loader {
  right: 0.75rem;
}

.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 3.107rem;
}

.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.25rem 0.75rem;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: #ced4da;
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  border-color: #4394D8;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.25rem 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #8A8A8A;
  padding: 0;
  margin: 0;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: #4394D8;
  color: #ffffff;
  border-radius: 4px;
}

.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #dc3545;
}

.p-autocomplete-panel {
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0.5rem 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #8A8A8A;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #8A8A8A;
  background: #e9ecef;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: #ffffff;
  background: #4394D8;
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #dc3545;
}

.p-datepicker {
  padding: 0;
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #efefef;
}

.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #8A8A8A;
  background: #ffffff;
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid #dee2e6;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:enabled:hover,
.p-datepicker .p-datepicker-header .p-datepicker-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}

.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  border-color: #4394D8;
}

.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}

.p-datepicker table {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.p-datepicker table th {
  padding: 0.5rem;
}

.p-datepicker table th > span {
  width: 2.5rem;
  height: 2.5rem;
}

.p-datepicker table td {
  padding: 0.5rem;
}

.p-datepicker table td > span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 4px;
  transition: box-shadow 0.15s;
  border: 1px solid transparent;
}

.p-datepicker table td > span.p-highlight {
  color: #ffffff;
  background: #4394D8;
}

.p-datepicker table td > span:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-datepicker table td.p-datepicker-today > span {
  background: #ced4da;
  color: #8A8A8A;
  border-color: transparent;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: #ffffff;
  background: #4394D8;
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid #dee2e6;
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}

.p-datepicker .p-timepicker {
  border-top: 1px solid #dee2e6;
  padding: 0.5rem;
}

.p-datepicker .p-timepicker button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.p-datepicker .p-timepicker button:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-datepicker .p-timepicker button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}

.p-datepicker .p-timepicker span {
  font-size: 1.25rem;
}

.p-datepicker .p-timepicker > div {
  padding: 0 0.5rem;
}

.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}

.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}

.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: #ffffff;
  background: #4394D8;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid #dee2e6;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}

.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}

.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: #e9ecef;
}

.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

@media screen and (max-width: 769px) {
  .p-datepicker table th, .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.p-cascadeselect:not(.p-disabled):hover {
  border-color: #ced4da;
}

.p-cascadeselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  border-color: #4394D8;
}

.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 0.5rem 0.75rem;
}

.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: #6c757d;
}

.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-cascadeselect-panel {
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}

.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.5rem 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #8A8A8A;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.5rem 1.5rem;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: #ffffff;
  background: #4394D8;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #8A8A8A;
  background: #e9ecef;
}

.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: #dc3545;
}

.p-input-filled .p-cascadeselect {
  background: #efefef;
}

.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #efefef;
}

.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}

.p-checkbox {
  width: 20px;
  height: 20px;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #8A8A8A;
  border-radius: 4px;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.15s;
  color: #ffffff;
  font-size: 14px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #4394D8;
  background: #4394D8;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #ced4da;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  border-color: #4394D8;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #48678c;
  background: #48678c;
  color: #ffffff;
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #dc3545;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #efefef;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: #4394D8;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #efefef;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: #48678c;
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-highlight .p-checkbox .p-checkbox-box {
  border-color: #ffffff;
}

.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.75rem;
}

.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: #ced4da;
}

.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  border-color: #4394D8;
}

.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: #4394D8;
  color: #ffffff;
  border-radius: 4px;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.25rem 0;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #8A8A8A;
  padding: 0;
  margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #dc3545;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #8A8A8A;
  border-color: #8A8A8A;
}

.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: #ffffff;
}

.p-colorpicker-overlay-panel {
  box-shadow: none;
}

.p-dropdown {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: #ced4da;
}

.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  border-color: #4394D8;
}

.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 1.75rem;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}

.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}

.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-dropdown .p-dropdown-clear-icon {
  color: #495057;
  right: 2.357rem;
}

.p-dropdown-panel {
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}

.p-dropdown-panel .p-dropdown-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #8A8A8A;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 1.75rem;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 0.75rem;
  color: #495057;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0.5rem 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #8A8A8A;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #4394D8;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #8A8A8A;
  background: #e9ecef;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1.5rem;
  color: #8A8A8A;
  background: transparent;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #8A8A8A;
  background: #ffffff;
  font-weight: 600;
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #dc3545;
}

.p-input-filled .p-dropdown {
  background: #efefef;
}

.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #efefef;
}

.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #efefef;
}

.p-editor-container .p-editor-toolbar {
  background: #efefef;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #dee2e6;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #8A8A8A;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #8A8A8A;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #8A8A8A;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #8A8A8A;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #8A8A8A;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #8A8A8A;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  border-radius: 4px;
  padding: 0.5rem 0;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #8A8A8A;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #8A8A8A;
  background: #e9ecef;
}

.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.5rem 1.5rem;
}

.p-editor-container .p-editor-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}

.p-editor-container .p-editor-content .ql-editor {
  background: #ffffff;
  color: #495057;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #8A8A8A;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #8A8A8A;
}

.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #8A8A8A;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #4394D8;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #4394D8;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #4394D8;
}

.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #4394D8;
}

.p-inputgroup-addon {
  background: #e9ecef;
  color: #495057;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  padding: 0.5rem 0.75rem;
  min-width: 2.357rem;
}

.p-inputgroup-addon:last-child {
  border-right: 1px solid #ced4da;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}

.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}

.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}

.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}

.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #dc3545;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #dc3545;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}

.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 4px;
  transition-duration: 0.15s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #ced4da;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #4394D8;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #4394D8;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #dc3545;
}

.p-inputtext {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.5rem 0.75rem;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  appearance: none;
  border-radius: 4px;
}

.p-inputtext:enabled:hover {
  border-color: #ced4da;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  border-color: #4394D8;
}

.p-inputtext.ng-dirty.ng-invalid {
  border-color: #dc3545;
}

.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}

.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}

.p-float-label > label {
  left: 0.75rem;
  color: #6c757d;
  transition-duration: 0.15s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: #dc3545;
}

.p-input-icon-left > i:first-of-type {
  left: 0.75rem;
  color: #495057;
}

.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label > label {
  left: 2.5rem;
}

.p-input-icon-right > i:last-of-type {
  right: 0.75rem;
  color: #495057;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 2.5rem;
}

::-webkit-input-placeholder {
  color: #6c757d;
}

:-moz-placeholder {
  color: #6c757d;
}

::-moz-placeholder {
  color: #6c757d;
}

:-ms-input-placeholder {
  color: #6c757d;
}

.p-input-filled .p-inputtext {
  background-color: #efefef;
}

.p-input-filled .p-inputtext:enabled:hover {
  background-color: #efefef;
}

.p-input-filled .p-inputtext:enabled:focus {
  background-color: #efefef;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}

.p-listbox {
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.p-listbox .p-listbox-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #8A8A8A;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 1.75rem;
}

.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 0.75rem;
  color: #495057;
}

.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}

.p-listbox .p-listbox-list {
  padding: 0.5rem 0;
}

.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #8A8A8A;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: #ffffff;
  background: #4394D8;
}

.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #8A8A8A;
  background: #e9ecef;
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #dc3545;
}

.p-multiselect {
  background: #ffffff;
  border: 1px solid #ced4da;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: #ced4da;
}

.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  border-color: #4394D8;
}

.p-multiselect .p-multiselect-label {
  padding: 0.5rem 0.75rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: #4394D8;
  color: #ffffff;
  border-radius: 4px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}

.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.75rem;
}

.p-multiselect-panel {
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
}

.p-multiselect-panel .p-multiselect-header {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #8A8A8A;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 1.75rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 0.75rem;
  color: #495057;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0.5rem 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #8A8A8A;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff;
  background: #4394D8;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #8A8A8A;
  background: #e9ecef;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1.5rem;
  color: #8A8A8A;
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #dc3545;
}

.p-input-filled .p-multiselect {
  background: #efefef;
}

.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #efefef;
}

.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #efefef;
}

.p-password-panel {
  padding: 1.25rem;
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  border-radius: 4px;
}

.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
}

.p-radiobutton {
  width: 20px;
  height: 20px;
}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 20px;
  height: 20px;
  color: #8A8A8A;
  border-radius: 50%;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: #ced4da;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  border-color: #4394D8;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.15s;
  background-color: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #4394D8;
  background: #4394D8;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: #48678c;
  background: #48678c;
  color: #ffffff;
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #dc3545;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #efefef;
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #efefef;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: #4394D8;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: #48678c;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-highlight .p-radiobutton .p-radiobutton-box {
  border-color: #ffffff;
}

.p-rating .p-rating-icon {
  color: #495057;
  margin-left: 0.5rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  font-size: 1.143rem;
}

.p-rating .p-rating-icon.p-rating-cancel {
  color: #dc3545;
}

.p-rating .p-rating-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}

.p-rating .p-rating-icon.pi-star {
  color: #4394D8;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #4394D8;
}

.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #dc3545;
}

.p-highlight .p-rating .p-rating-icon {
  color: #ffffff;
}

.p-selectbutton .p-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #ffffff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: #ffffff;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #5a6268;
  border-color: #545b62;
  color: #ffffff;
}

.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #dc3545;
}

.p-slider {
  background: #e9ecef;
  border: 0 none;
  border-radius: 4px;
}

.p-slider.p-slider-horizontal {
  height: 0.286rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}

.p-slider.p-slider-vertical {
  width: 0.286rem;
}

.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}

.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #4394D8;
  border: 2px solid #4394D8;
  border-radius: 4px;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-slider .p-slider-range {
  background: #4394D8;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #51749e;
  border-color: #51749e;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s, left 0.15s;
}

.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.15s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s, bottom 0.15s;
}

.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.15s;
}

.p-togglebutton.p-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #ffffff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: #ffffff;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #5a6268;
  border-color: #545b62;
  color: #ffffff;
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}

.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: #ffffff;
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #dc3545;
}

.p-button {
  color: #ffffff;
  background: #4394D8;
  border: 1px solid #4394D8;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
}

.p-button:enabled:hover {
  background: #51749e;
  color: #ffffff;
  border-color: #51749e;
}

.p-button:enabled:active {
  background: #48678c;
  color: #ffffff;
  border-color: #48678c;
}

.p-button.p-button-outlined {
  background-color: transparent;
  color: #4394D8;
  border: 1px solid;
}

.p-button.p-button-outlined:enabled:hover {
  background: rgba(94, 129, 172, 0.04);
  color: #4394D8;
  border: 1px solid;
}

.p-button.p-button-outlined:enabled:active {
  background: rgba(94, 129, 172, 0.16);
  color: #4394D8;
  border: 1px solid;
}

.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}

.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}

.p-button.p-button-text {
  background-color: transparent;
  color: #4394D8;
  border-color: transparent;
}

.p-button.p-button-text:enabled:hover {
  background: rgba(94, 129, 172, 0.04);
  color: #4394D8;
  border-color: transparent;
}

.p-button.p-button-text:enabled:active {
  background: rgba(94, 129, 172, 0.16);
  color: #4394D8;
  border-color: transparent;
}

.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}

.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}

.p-button.p-button-text.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}

.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}

.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}

.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}

.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}

.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: #4394D8;
  background-color: #ffffff;
}

.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.p-button.p-button-rounded {
  border-radius: 2rem;
}

.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.5rem 0;
}

.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}

.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}

.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.65625rem;
}

.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}

.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.625rem 0.9375rem;
}

.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}

.p-fluid .p-button {
  width: 100%;
}

.p-fluid .p-button-icon-only {
  width: 2.357rem;
}

.p-fluid .p-buttonset {
  display: flex;
}

.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: #ffffff;
  background: #6c757d;
  border: 1px solid #6c757d;
}

.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #5a6268;
  color: #ffffff;
  border-color: #5a6268;
}

.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: #545b62;
  color: #ffffff;
  border-color: #4e555b;
}

.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: #6c757d;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(108, 117, 125, 0.04);
  color: #6c757d;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(108, 117, 125, 0.16);
  color: #6c757d;
  border: 1px solid;
}

.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: #6c757d;
  border-color: transparent;
}

.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(108, 117, 125, 0.04);
  border-color: transparent;
  color: #6c757d;
}

.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(108, 117, 125, 0.16);
  border-color: transparent;
  color: #6c757d;
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: #ffffff;
  background: #17a2b8;
  border: 1px solid #17a2b8;
}

.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: #138496;
  color: #ffffff;
  border-color: #117a8b;
}

.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
  background: #138496;
  color: #ffffff;
  border-color: #117a8b;
}

.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: #17a2b8;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(23, 162, 184, 0.04);
  color: #17a2b8;
  border: 1px solid;
}

.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(23, 162, 184, 0.16);
  color: #17a2b8;
  border: 1px solid;
}

.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: #17a2b8;
  border-color: transparent;
}

.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(23, 162, 184, 0.04);
  border-color: transparent;
  color: #17a2b8;
}

.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(23, 162, 184, 0.16);
  border-color: transparent;
  color: #17a2b8;
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: #ffffff;
  background: #28a745;
  border: 1px solid #28a745;
}

.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: #218838;
  color: #ffffff;
  border-color: #1e7e34;
}

.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: #1e7e34;
  color: #ffffff;
  border-color: #1c7430;
}

.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: #28a745;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(40, 167, 69, 0.04);
  color: #28a745;
  border: 1px solid;
}

.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(40, 167, 69, 0.16);
  color: #28a745;
  border: 1px solid;
}

.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: #28a745;
  border-color: transparent;
}

.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(40, 167, 69, 0.04);
  border-color: transparent;
  color: #28a745;
}

.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(40, 167, 69, 0.16);
  border-color: transparent;
  color: #28a745;
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: #8A8A8A;
  background: #ffc107;
  border: 1px solid #ffc107;
}

.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: #e0a800;
  color: #8A8A8A;
  border-color: #d39e00;
}

.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: #d39e00;
  color: #8A8A8A;
  border-color: #c69500;
}

.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: #ffc107;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(255, 193, 7, 0.04);
  color: #ffc107;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(255, 193, 7, 0.16);
  color: #ffc107;
  border: 1px solid;
}

.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: #ffc107;
  border-color: transparent;
}

.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(255, 193, 7, 0.04);
  border-color: transparent;
  color: #ffc107;
}

.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(255, 193, 7, 0.16);
  border-color: transparent;
  color: #ffc107;
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: #ffffff;
  background: #6f42c1;
  border: 1px solid #6f42c1;
}

.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: #633bad;
  color: #ffffff;
  border-color: #58349a;
}

.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem #d3c6ec;
}

.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
  background: #58349a;
  color: #ffffff;
  border-color: #4d2e87;
}

.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: #6f42c1;
  border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(111, 66, 193, 0.04);
  color: #6f42c1;
  border: 1px solid;
}

.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(111, 66, 193, 0.16);
  color: #6f42c1;
  border: 1px solid;
}

.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: #6f42c1;
  border-color: transparent;
}

.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(111, 66, 193, 0.04);
  border-color: transparent;
  color: #6f42c1;
}

.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(111, 66, 193, 0.16);
  border-color: transparent;
  color: #6f42c1;
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: #ffffff;
  background: #dc3545;
  border: 1px solid #dc3545;
}

.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: #c82333;
  color: #ffffff;
  border-color: #bd2130;
}

.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: #bd2130;
  color: #ffffff;
  border-color: #b21f2d;
}

.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: #dc3545;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(220, 53, 69, 0.04);
  color: #dc3545;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(220, 53, 69, 0.16);
  color: #dc3545;
  border: 1px solid;
}

.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: #dc3545;
  border-color: transparent;
}

.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(220, 53, 69, 0.04);
  border-color: transparent;
  color: #dc3545;
}

.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(220, 53, 69, 0.16);
  border-color: transparent;
  color: #dc3545;
}

.p-button.p-button-link {
  color: #4394D8;
  background: transparent;
  border: transparent;
}

.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #51749e;
  border-color: transparent;
}

.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}

.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  border-color: transparent;
}

.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #4394D8;
  border-color: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin: 0.5rem;
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-carousel .p-carousel-indicators {
  padding: 1rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #4394D8;
  color: #ffffff;
}

.p-datatable .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}

.p-datatable .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}

.p-datatable .p-datatable-header {
  background: #efefef;
  color: #8A8A8A;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-footer {
  background: #efefef;
  color: #8A8A8A;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 2px 0;
  font-weight: 600;
  color: #8A8A8A;
  background: #ffffff;
  transition: box-shadow 0.15s;
}

.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  font-weight: 600;
  color: #8A8A8A;
  background: #ffffff;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #4394D8;
  margin-left: 0.5rem;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #8A8A8A;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #6c757d;
}

.p-datatable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: #4394D8;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #4394D8;
}

.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e9ecef;
  color: #4394D8;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #4394D8;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
  outline: 0 none;
}

.p-datatable .p-datatable-tbody > tr {
  background: #ffffff;
  color: #8A8A8A;
  transition: box-shadow 0.15s;
  outline-color: #bfcdde;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}

.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #4394D8;
  color: #ffffff;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #4394D8;
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #4394D8;
}

.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #8A8A8A;
}

.p-datatable .p-column-resizer-helper {
  background: #4394D8;
}

.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #efefef;
}

.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 2px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.05);
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #4394D8;
  color: #ffffff;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #ffffff;
}

.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #ffffff;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}

.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-dataview .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}

.p-dataview .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}

.p-dataview .p-dataview-header {
  background: #efefef;
  color: #8A8A8A;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-dataview .p-dataview-content {
  background: #ffffff;
  color: #8A8A8A;
  border: 0 none;
  padding: 0;
}

.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: 1px solid #dee2e6;
  border-width: 1px 0 0 0;
}

.p-dataview .p-dataview-footer {
  background: #efefef;
  color: #8A8A8A;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}

.p-dataview .p-dataview-emptymessage {
  padding: 1.25rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.p-column-filter-menu-button:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: transparent;
  color: #495057;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #4394D8;
  color: #ffffff;
}

.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-column-filter-clear-button {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.p-column-filter-clear-button:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-column-filter-overlay {
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  min-width: 12.5rem;
}

.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.5rem 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.5rem 1.5rem;
  border: 0 none;
  color: #8A8A8A;
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #ffffff;
  background: #4394D8;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #8A8A8A;
  background: #e9ecef;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.75rem 1.5rem;
  border-bottom: 1px solid #dee2e6;
  color: #8A8A8A;
  background: #efefef;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1.25rem;
  border-bottom: 1px solid #dee2e6;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}

.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}

.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.5rem 1.25rem;
}

.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1.25rem;
}

.fc .fc-view-container th {
  background: #efefef;
  border: 1px solid #dee2e6;
  color: #8A8A8A;
}

.fc .fc-view-container td.fc-widget-content {
  background: #ffffff;
  border: 1px solid #dee2e6;
  color: #8A8A8A;
}

.fc .fc-view-container td.fc-head-container {
  border: 1px solid #dee2e6;
}

.fc .fc-view-container .fc-row {
  border-right: 1px solid #dee2e6;
}

.fc .fc-view-container .fc-event {
  background: #51749e;
  border: 1px solid #51749e;
  color: #ffffff;
}

.fc .fc-view-container .fc-divider {
  background: #efefef;
  border: 1px solid #dee2e6;
}

.fc .fc-toolbar .fc-button {
  color: #ffffff;
  background: #4394D8;
  border: 1px solid #4394D8;
  font-size: 1rem;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.fc .fc-toolbar .fc-button:enabled:hover {
  background: #51749e;
  color: #ffffff;
  border-color: #51749e;
}

.fc .fc-toolbar .fc-button:enabled:active {
  background: #48678c;
  color: #ffffff;
  border-color: #48678c;
}

.fc .fc-toolbar .fc-button:enabled:active:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
  font-size: 1rem;
}

.fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}

.fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: #6c757d;
  border: 1px solid #6c757d;
  color: #ffffff;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #5a6268;
  border-color: #545b62;
  color: #ffffff;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: #545b62;
  border-color: #4e555b;
  color: #ffffff;
}

.fc .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
  z-index: 1;
}

.fc .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}

.fc .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.fc .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-orderlist .p-orderlist-controls {
  padding: 1.25rem;
}

.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}

.p-orderlist .p-orderlist-header {
  background: #efefef;
  color: #8A8A8A;
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 600;
}

.p-orderlist .p-orderlist-filter-container {
  padding: 1rem 1.25rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 1.75rem;
}

.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 0.75rem;
  color: #495057;
}

.p-orderlist .p-orderlist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #8A8A8A;
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.5rem 1.5rem;
  margin: 0;
  border: 0 none;
  color: #8A8A8A;
  background: transparent;
  transition: transform 0.15s, box-shadow 0.15s;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #8A8A8A;
}

.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: #ffffff;
  background: #4394D8;
}

.p-orderlist .p-orderlist-list .p-orderlist-droppoint.p-orderlist-droppoint-highlight {
  background-color: #48678c;
}

@media screen and (max-width: 769px) {
  .p-orderlist {
    flex-direction: column;
  }

  .p-orderlist .p-orderlist-controls {
    padding: 1.25rem;
    flex-direction: row;
  }

  .p-orderlist .p-orderlist-controls .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .p-orderlist .p-orderlist-controls .p-button:last-child {
    margin-right: 0;
  }
}
.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: #e9ecef;
  color: #8A8A8A;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #4394D8;
  color: #ffffff;
}

.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #2f435b;
}

.p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}

.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #dee2e6;
  border-color: #dee2e6;
}

.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #dee2e6;
  border-color: #dee2e6;
}

.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #8A8A8A;
  padding: 1.25rem;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}

.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-paginator {
  background: #ffffff;
  color: #4394D8;
  border: solid #dee2e6;
  border-width: 0;
  padding: 0.75rem;
  border-radius: 4px;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #4394D8;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #4394D8;
}

.p-paginator .p-paginator-first {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-paginator .p-paginator-last {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  height: 2.357rem;
}

.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}

.p-paginator .p-paginator-current {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #4394D8;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  padding: 0 0.5rem;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  color: #4394D8;
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0 0 0 -1px;
  transition: box-shadow 0.15s;
  border-radius: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #4394D8;
  border-color: #4394D8;
  color: #ffffff;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #4394D8;
}

.p-picklist .p-picklist-buttons {
  padding: 1.25rem;
}

.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}

.p-picklist .p-picklist-header {
  background: #efefef;
  color: #8A8A8A;
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 600;
}

.p-picklist .p-picklist-filter-container {
  padding: 1rem 1.25rem;
  background: #ffffff;
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 1.75rem;
}

.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 0.75rem;
  color: #495057;
}

.p-picklist .p-picklist-list {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #8A8A8A;
  padding: 0.5rem 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1.5rem;
  margin: 0;
  border: 0 none;
  color: #8A8A8A;
  background: transparent;
  transition: transform 0.15s, box-shadow 0.15s;
}

.p-picklist .p-picklist-list .p-picklist-item:not(.p-highlight):hover {
  background: #e9ecef;
  color: #8A8A8A;
}

.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: #ffffff;
  background: #4394D8;
}

.p-picklist .p-picklist-list .p-picklist-droppoint.p-picklist-droppoint-highlight {
  background-color: #48678c;
}

.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.5rem 1.5rem;
  color: #8A8A8A;
}

@media screen and (max-width: 769px) {
  .p-picklist {
    flex-direction: column;
  }

  .p-picklist .p-picklist-buttons {
    padding: 1.25rem;
    flex-direction: row;
  }

  .p-picklist .p-picklist-buttons .p-button {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .p-picklist .p-picklist-buttons .p-button:last-child {
    margin-right: 0;
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-right:before {
    content: "";
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-right:before {
    content: "";
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-left:before {
    content: "";
  }

  .p-picklist .p-picklist-transfer-buttons .pi-angle-double-left:before {
    content: "";
  }
}
.p-timeline .p-timeline-event-marker {
  border: 0 none;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: #4394D8;
}

.p-timeline .p-timeline-event-connector {
  background-color: #dee2e6;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}

.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}

.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-tree {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #8A8A8A;
  padding: 1.25rem;
  border-radius: 4px;
}

.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 4px;
  transition: box-shadow 0.15s;
  padding: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: #6c757d;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #8A8A8A;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: #4394D8;
  color: #ffffff;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #ffffff;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: #ffffff;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #8A8A8A;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: #e9ecef;
  color: #8A8A8A;
}

.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}

.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 1.75rem;
}

.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 0.75rem;
  color: #495057;
}

.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}

.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}

.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: #48678c;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: 4px;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  color: #8A8A8A;
  padding: 0.5rem;
  transition: box-shadow 0.15s;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: #4394D8;
  color: #ffffff;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: #ffffff;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: #6c757d;
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: #e9ecef;
  color: #8A8A8A;
}

.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-treetable .p-paginator-top {
  border-width: 1px 0 0 0;
  border-radius: 0;
}

.p-treetable .p-paginator-bottom {
  border-width: 1px 0 0 0;
  border-radius: 0;
}

.p-treetable .p-treetable-header {
  background: #efefef;
  color: #8A8A8A;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-treetable .p-treetable-footer {
  background: #efefef;
  color: #8A8A8A;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 2px 0;
  font-weight: 600;
  color: #8A8A8A;
  background: #ffffff;
  transition: box-shadow 0.15s;
}

.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  font-weight: 600;
  color: #8A8A8A;
  background: #ffffff;
}

.p-treetable .p-sortable-column {
  outline-color: #bfcdde;
}

.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: #6c757d;
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #ffffff;
  background: #4394D8;
  margin-left: 0.5rem;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #8A8A8A;
}

.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: #6c757d;
}

.p-treetable .p-sortable-column.p-highlight {
  background: #ffffff;
  color: #4394D8;
}

.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #4394D8;
}

.p-treetable .p-treetable-tbody > tr {
  background: #ffffff;
  color: #8A8A8A;
  transition: box-shadow 0.15s;
  outline-color: #bfcdde;
}

.p-treetable .p-treetable-tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}

.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #8A8A8A;
}

.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #4394D8;
  color: #ffffff;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: #ffffff;
}

.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: #ffffff;
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #8A8A8A;
}

.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #8A8A8A;
}

.p-treetable .p-column-resizer-helper {
  background: #4394D8;
}

.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #efefef;
}

.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}

.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}

.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}

.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}

.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}

.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}

.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #efefef;
  color: #8A8A8A;
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
  padding: 1rem 1rem;
  font-weight: 600;
}

.p-virtualscroller .p-virtualscroller-content {
  background: #ffffff;
  color: #8A8A8A;
  border: 0 none;
  padding: 0;
}

.p-virtualscroller .p-virtualscroller-footer {
  background: #efefef;
  color: #8A8A8A;
  border: 1px solid #dee2e6;
  border-width: 1px 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #8A8A8A;
  background: #efefef;
  font-weight: 600;
  border-radius: 4px;
  transition: box-shadow 0.15s;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #8A8A8A;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #efefef;
  border-color: #dee2e6;
  color: #8A8A8A;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #8A8A8A;
}

.p-accordion .p-accordion-content {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #8A8A8A;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}

.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}

.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-card {
  background: #ffffff;
  color: #8A8A8A;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.p-card .p-card-body {
  padding: 1.5rem;
}

.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.p-card .p-card-subtitle {
  font-weight: 400;
  margin-bottom: 0.5rem;
  color: #6c757d;
}

.p-card .p-card-content {
  padding: 1rem 0;
}

.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.p-divider .p-divider-content {
  background-color: #ffffff;
}

.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}

.p-divider.p-divider-horizontal:before {
  border-top: 1px #dee2e6;
}

.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}

.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}

.p-divider.p-divider-vertical:before {
  border-left: 1px #dee2e6;
}

.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-fieldset {
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #8A8A8A;
  border-radius: 4px;
}

.p-fieldset .p-fieldset-legend {
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #8A8A8A;
  background: #efefef;
  font-weight: 600;
  border-radius: 4px;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: box-shadow 0.15s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem 1.25rem;
  color: #8A8A8A;
  border-radius: 4px;
  transition: box-shadow 0.15s;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #8A8A8A;
}

.p-fieldset .p-fieldset-content {
  padding: 1.25rem;
}

.p-panel .p-panel-header {
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  background: #efefef;
  color: #8A8A8A;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}

.p-panel .p-panel-header .p-panel-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1.25rem;
}

.p-panel .p-panel-content {
  padding: 1.25rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #8A8A8A;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 0 none;
}

.p-panel .p-panel-footer {
  padding: 0.5rem 1.25rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #8A8A8A;
  border-top: 0 none;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #efefef;
  border: 0 none;
}

.p-splitter {
  border: 1px solid #dee2e6;
  background: #ffffff;
  border-radius: 4px;
  color: #8A8A8A;
}

.p-splitter .p-splitter-gutter {
  transition: box-shadow 0.15s;
  background: #efefef;
}

.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: #dee2e6;
}

.p-splitter .p-splitter-gutter-resizing {
  background: #dee2e6;
}

.p-tabview .p-tabview-nav {
  background: transparent;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
}

.p-tabview .p-tabview-nav li {
  margin-right: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid;
  border-width: 1px;
  border-color: #ffffff #ffffff #dee2e6 #ffffff;
  background: #ffffff;
  color: #6c757d;
  padding: 0.75rem 1rem;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: box-shadow 0.15s;
  margin: 0 0 -1px 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: #ffffff;
  border-color: #dee2e6;
  color: #6c757d;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff #dee2e6;
  color: #495057;
}

.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}

.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}

.p-tabview .p-tabview-panels {
  background: #ffffff;
  padding: 1.25rem;
  border: 0 none;
  color: #8A8A8A;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-toolbar {
  background: #efefef;
  border: 1px solid #dee2e6;
  padding: 1rem 1.25rem;
  border-radius: 4px;
}

.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: none;
}

.p-confirm-popup .p-confirm-popup-content {
  padding: 1.25rem;
}

.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0.5rem 1.25rem;
}

.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}

.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #ffffff;
}

.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: rgba(0, 0, 0, 0.2);
}

.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}

.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  border-radius: 4px;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.p-dialog .p-dialog-header {
  border-bottom: 1px solid #e9ecef;
  background: #ffffff;
  color: #8A8A8A;
  padding: 1rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 600;
  font-size: 1.25rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
  margin-right: 0.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}

.p-dialog .p-dialog-content {
  background: #ffffff;
  color: #8A8A8A;
  padding: 1rem;
}

.p-dialog .p-dialog-footer {
  border-top: 1px solid #e9ecef;
  background: #ffffff;
  color: #8A8A8A;
  padding: 1rem;
  text-align: right;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

.p-dialog-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-overlaypanel {
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-shadow: none;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1.25rem;
}

.p-overlaypanel .p-overlaypanel-close {
  background: #4394D8;
  color: #ffffff;
  width: 2rem;
  height: 2rem;
  transition: box-shadow 0.15s;
  border-radius: 50%;
  position: absolute;
  top: -1rem;
  right: -1rem;
}

.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: #51749e;
  color: #ffffff;
}

.p-overlaypanel:after {
  border: solid transparent;
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #ffffff;
}

.p-overlaypanel:before {
  border: solid transparent;
  border-color: rgba(0, 0, 0, 0);
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: #ffffff;
}

.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: rgba(0, 0, 0, 0.2);
}

.p-sidebar {
  background: #ffffff;
  color: #8A8A8A;
  padding: 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
}

.p-sidebar .p-sidebar-close {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.p-sidebar .p-sidebar-close:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: transparent;
}

.p-sidebar .p-sidebar-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-sidebar-mask.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-tooltip .p-tooltip-text {
  background: #8A8A8A;
  color: #ffffff;
  padding: 0.5rem 0.75rem;
  box-shadow: none;
  border-radius: 4px;
}

.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #8A8A8A;
}

.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: #8A8A8A;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: #8A8A8A;
}

.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: #8A8A8A;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #efefef;
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #8A8A8A;
  border-bottom: 0 none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}

.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-fileupload .p-fileupload-content {
  background: #ffffff;
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #8A8A8A;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-fileupload .p-progressbar {
  height: 0.25rem;
}

.p-fileupload .p-fileupload-row > div {
  padding: 1rem 1rem;
}

.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: #51749e;
  color: #ffffff;
  border-color: #51749e;
}

.p-fileupload-choose:not(.p-disabled):active {
  background: #48678c;
  color: #ffffff;
  border-color: #48678c;
}

.p-breadcrumb {
  background: #efefef;
  border: 0 none;
  border-radius: 4px;
  padding: 1rem;
}

.p-breadcrumb ul li .p-menuitem-link {
  transition: box-shadow 0.15s;
  border-radius: 4px;
}

.p-breadcrumb ul li .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
  color: #4394D8;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
  color: #4394D8;
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
  margin: 0 0.5rem 0 0.5rem;
  color: #6c757d;
}

.p-breadcrumb ul li:last-child .p-menuitem-text {
  color: #6c757d;
}

.p-breadcrumb ul li:last-child .p-menuitem-icon {
  color: #6c757d;
}

.p-contextmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  width: 12.5rem;
}

.p-contextmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #8A8A8A;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: #8A8A8A;
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: #8A8A8A;
  margin-right: 0.5rem;
}

.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: #8A8A8A;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #8A8A8A;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #8A8A8A;
}

.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #8A8A8A;
}

.p-contextmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-contextmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.p-contextmenu .p-menuitem {
  margin: 0;
}

.p-contextmenu .p-menuitem:last-child {
  margin: 0;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #8A8A8A;
}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #8A8A8A;
}

.p-contextmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-megamenu {
  padding: 0.5rem 1rem;
  background: #efefef;
  color: rgba(0, 0, 0, 0.9);
  border: 0 none;
  border-radius: 4px;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.5);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0.5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.5rem;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: transparent;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.7);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.7);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.7);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: transparent;
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.9);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.9);
}

.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.9);
}

.p-megamenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #8A8A8A;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: #8A8A8A;
}

.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: #8A8A8A;
  margin-right: 0.5rem;
}

.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: #8A8A8A;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #8A8A8A;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #8A8A8A;
}

.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #8A8A8A;
}

.p-megamenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-megamenu .p-megamenu-panel {
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #8A8A8A;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-megamenu .p-megamenu-submenu {
  padding: 0.5rem 0;
  width: 12.5rem;
}

.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem {
  margin: 0;
}

.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
  margin: 0;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #8A8A8A;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #8A8A8A;
}

.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.5rem 0;
}

.p-megamenu.p-megamenu-vertical .p-menuitem {
  margin: 0;
}

.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
  margin: 0;
}

.p-menu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}

.p-menu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #8A8A8A;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-menu .p-menuitem-link .p-menuitem-text {
  color: #8A8A8A;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  color: #8A8A8A;
  margin-right: 0.5rem;
}

.p-menu .p-menuitem-link .p-submenu-icon {
  color: #8A8A8A;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #8A8A8A;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #8A8A8A;
}

.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #8A8A8A;
}

.p-menu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-menu.p-menu-overlay {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #8A8A8A;
  background: #ffffff;
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.p-menu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.p-menu .p-menuitem {
  margin: 0;
}

.p-menu .p-menuitem:last-child {
  margin: 0;
}

.p-menubar {
  padding: 0.5rem 1rem;
  background: #efefef;
  color: rgba(0, 0, 0, 0.9);
  border: 0 none;
  border-radius: 4px;
}

.p-menubar .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #8A8A8A;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-menubar .p-menuitem-link .p-menuitem-text {
  color: #8A8A8A;
}

.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: #8A8A8A;
  margin-right: 0.5rem;
}

.p-menubar .p-menuitem-link .p-submenu-icon {
  color: #8A8A8A;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #8A8A8A;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #8A8A8A;
}

.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #8A8A8A;
}

.p-menubar .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1rem;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: rgba(0, 0, 0, 0.5);
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-right: 0.5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: rgba(0, 0, 0, 0.5);
  margin-left: 0.5rem;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: transparent;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.7);
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.7);
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.7);
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: transparent;
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: rgba(0, 0, 0, 0.9);
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: rgba(0, 0, 0, 0.9);
}

.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: rgba(0, 0, 0, 0.9);
}

.p-menubar .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  width: 12.5rem;
}

.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}

.p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}

.p-menubar .p-submenu-list .p-menuitem:last-child {
  margin: 0;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #8A8A8A;
}

.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #8A8A8A;
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }

  .p-menubar .p-menubar-button {
    display: flex;
    width: 2rem;
    height: 2rem;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: box-shadow 0.15s;
  }

  .p-menubar .p-menubar-button:hover {
    color: rgba(0, 0, 0, 0.7);
    background: transparent;
  }

  .p-menubar .p-menubar-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #bfcdde;
  }

  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.5rem 0;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    width: 100%;
  }

  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
  }

  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 0.75rem 1rem;
    color: #8A8A8A;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: #8A8A8A;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: #8A8A8A;
    margin-right: 0.5rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: #8A8A8A;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: #e9ecef;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: #8A8A8A;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: #8A8A8A;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: #8A8A8A;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem #bfcdde;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.15s;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }

  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }

  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.15s;
    transform: rotate(90deg);
  }

  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }

  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }

  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 2.25rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 3.75rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 5.25rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 6.75rem;
  }

  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 8.25rem;
  }

  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem 1.25rem;
  border: 1px solid #dee2e6;
  color: #8A8A8A;
  background: #efefef;
  font-weight: 600;
  border-radius: 4px;
  transition: box-shadow 0.15s;
}

.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #8A8A8A;
}

.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #efefef;
  border-color: #dee2e6;
  color: #8A8A8A;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #8A8A8A;
}

.p-panelmenu .p-panelmenu-content {
  padding: 0.5rem 0;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #8A8A8A;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #8A8A8A;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: #8A8A8A;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #8A8A8A;
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: #8A8A8A;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #8A8A8A;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #8A8A8A;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #8A8A8A;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}

.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}

.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.p-slidemenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}

.p-slidemenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #8A8A8A;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: #8A8A8A;
}

.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: #8A8A8A;
  margin-right: 0.5rem;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: #8A8A8A;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #8A8A8A;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #8A8A8A;
}

.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #8A8A8A;
}

.p-slidemenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-slidemenu.p-slidemenu-overlay {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.p-slidemenu .p-slidemenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #e9ecef;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #8A8A8A;
}

.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon, .p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: #8A8A8A;
}

.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}

.p-slidemenu .p-slidemenu-backward {
  padding: 0.75rem 1rem;
  color: #8A8A8A;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: box-shadow 0.15s;
  border-radius: 4px;
  background: transparent;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #8A8A8A;
  border: 1px solid #dee2e6;
  background: transparent;
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 4px;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: #6c757d;
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #4394D8;
  color: #ffffff;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #8A8A8A;
}

.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid #dee2e6;
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: transparent;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid;
  border-width: 1px;
  border-color: #ffffff #ffffff #dee2e6 #ffffff;
  background: #ffffff;
  color: #6c757d;
  padding: 0.75rem 1rem;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  transition: box-shadow 0.15s;
  margin: 0 0 -1px 0;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: #ffffff;
  border-color: #dee2e6;
  color: #6c757d;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff #dee2e6;
  color: #495057;
}

.p-tieredmenu {
  padding: 0.5rem 0;
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  width: 12.5rem;
}

.p-tieredmenu .p-menuitem-link {
  padding: 0.75rem 1rem;
  color: #8A8A8A;
  border-radius: 0;
  transition: box-shadow 0.15s;
  user-select: none;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: #8A8A8A;
}

.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: #8A8A8A;
  margin-right: 0.5rem;
}

.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: #8A8A8A;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #8A8A8A;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: #8A8A8A;
}

.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: #8A8A8A;
}

.p-tieredmenu .p-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #bfcdde;
}

.p-tieredmenu.p-tieredmenu-overlay {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.p-tieredmenu .p-submenu-list {
  padding: 0.5rem 0;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.p-tieredmenu .p-menuitem {
  margin: 0;
}

.p-tieredmenu .p-menuitem:last-child {
  margin: 0;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #8A8A8A;
}

.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: #8A8A8A;
}

.p-tieredmenu .p-menu-separator {
  border-top: 1px solid #dee2e6;
  margin: 0.5rem 0;
}

.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-inline-message {
  padding: 0.5rem 0.75rem;
  margin: 0;
  border-radius: 4px;
}

.p-inline-message.p-inline-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 0px;
  color: #004085;
}

.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #004085;
}

.p-inline-message.p-inline-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 0px;
  color: #155724;
}

.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: #155724;
}

.p-inline-message.p-inline-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 0px;
  color: #856404;
}

.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: #856404;
}

.p-inline-message.p-inline-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 0px;
  color: #721c24;
}

.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: #721c24;
}

.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}

.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}

.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: 4px;
}

.p-message .p-message-wrapper {
  padding: 1rem 1.25rem;
}

.p-message .p-message-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: box-shadow 0.15s;
}

.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.p-message .p-message-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-message.p-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 1px;
  color: #004085;
}

.p-message.p-message-info .p-message-icon {
  color: #004085;
}

.p-message.p-message-info .p-message-close {
  color: #004085;
}

.p-message.p-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 1px;
  color: #155724;
}

.p-message.p-message-success .p-message-icon {
  color: #155724;
}

.p-message.p-message-success .p-message-close {
  color: #155724;
}

.p-message.p-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 1px;
  color: #856404;
}

.p-message.p-message-warn .p-message-icon {
  color: #856404;
}

.p-message.p-message-warn .p-message-close {
  color: #856404;
}

.p-message.p-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 1px;
  color: #721c24;
}

.p-message.p-message-error .p-message-icon {
  color: #721c24;
}

.p-message.p-message-error .p-message-close {
  color: #721c24;
}

.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}

.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.p-message .p-message-summary {
  font-weight: 700;
}

.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  opacity: 1;
}

.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.p-toast .p-toast-message .p-toast-message-content {
  padding: 1rem;
  border-width: 0;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 0 0 1rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  font-size: 2rem;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem 0 0 0;
}

.p-toast .p-toast-message .p-toast-icon-close {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: transparent;
  transition: box-shadow 0.15s;
}

.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: rgba(255, 255, 255, 0.3);
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-toast .p-toast-message.p-toast-message-info {
  background: #cce5ff;
  border: solid #b8daff;
  border-width: 1px;
  color: #004085;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: #004085;
}

.p-toast .p-toast-message.p-toast-message-success {
  background: #d4edda;
  border: solid #c3e6cb;
  border-width: 1px;
  color: #155724;
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #155724;
}

.p-toast .p-toast-message.p-toast-message-warn {
  background: #fff3cd;
  border: solid #ffeeba;
  border-width: 1px;
  color: #856404;
}

.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-warn .p-toast-icon-close {
  color: #856404;
}

.p-toast .p-toast-message.p-toast-message-error {
  background: #f8d7da;
  border: solid #f5c6cb;
  border-width: 1px;
  color: #721c24;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #721c24;
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #efefef;
  width: 4rem;
  height: 4rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}

.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}

.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}

.p-galleria .p-galleria-item-nav {
  background: transparent;
  color: #efefef;
  width: 4rem;
  height: 4rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
  margin: 0 0.5rem;
}

.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}

.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}

.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #efefef;
  padding: 1rem;
}

.p-galleria .p-galleria-indicators {
  padding: 1rem;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}

.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #4394D8;
  color: #ffffff;
}

.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}

.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #4394D8;
  color: #ffffff;
}

.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #efefef;
  width: 2rem;
  height: 2rem;
  transition: box-shadow 0.15s;
  border-radius: 4px;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #efefef;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content {
  transition: box-shadow 0.15s;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-galleria-mask.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.9);
}

.p-avatar {
  background-color: #dee2e6;
  border-radius: 4px;
}

.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}

.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}

.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}

.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid #ffffff;
}

.p-badge {
  background: #4394D8;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

.p-badge.p-badge-secondary {
  background-color: #6c757d;
  color: #ffffff;
}

.p-badge.p-badge-success {
  background-color: #28a745;
  color: #ffffff;
}

.p-badge.p-badge-info {
  background-color: #17a2b8;
  color: #ffffff;
}

.p-badge.p-badge-warning {
  background-color: #ffc107;
  color: #8A8A8A;
}

.p-badge.p-badge-danger {
  background-color: #dc3545;
  color: #ffffff;
}

.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}

.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-blockui.p-component-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.p-chip {
  background-color: #dee2e6;
  color: #8A8A8A;
  border-radius: 16px;
  padding: 0 0.75rem;
}

.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}

.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -0.75rem;
  margin-right: 0.5rem;
}

.p-chip .pi-chip-remove-icon {
  border-radius: 4px;
  transition: box-shadow 0.15s;
}

.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-inplace .p-inplace-display {
  padding: 0.5rem 0.75rem;
  border-radius: 4px;
  transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #e9ecef;
  color: #8A8A8A;
}

.p-inplace .p-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfcdde;
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #e9ecef;
  border-radius: 4px;
}

.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #4394D8;
}

.p-progressbar .p-progressbar-label {
  color: #8A8A8A;
  line-height: 1.5rem;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 4px;
  box-shadow: none;
  transition: box-shadow 0.15s;
}

.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}

.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}

.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #efefef;
}

.p-skeleton {
  background-color: #e9ecef;
  border-radius: 4px;
}

.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
  background: #4394D8;
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
}

.p-tag.p-tag-success {
  background-color: #28a745;
  color: #ffffff;
}

.p-tag.p-tag-info {
  background-color: #17a2b8;
  color: #ffffff;
}

.p-tag.p-tag-warning {
  background-color: #ffc107;
  color: #8A8A8A;
}

.p-tag.p-tag-danger {
  background-color: #dc3545;
  color: #ffffff;
}

.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.p-terminal {
  background: #ffffff;
  color: #8A8A8A;
  border: 1px solid #dee2e6;
  padding: 1.25rem;
}

.p-terminal .p-terminal-input {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

/* Vendor extensions to the designer enhanced bootstrap compatibility */
.p-breadcrumb .p-breadcrumb-chevron {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.p-breadcrumb .p-breadcrumb-chevron:before {
  content: "/";
}

/* Add your customizations of theme here */
/* Add your variable customizations of layout here */
/* Utils */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.card {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background: #ffffff;
  padding: 18px;
  margin-bottom: 2rem;
}

.card:last-child {
  margin-bottom: 0;
}

.card .card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.card .card-subtitle {
  color: #9199a9;
  font-weight: 600;
  margin: -1rem 0 1rem 0;
}

.p-toast.p-toast-top-right, .p-toast.p-toast-top-left, .p-toast.p-toast-top-center {
  top: 70px;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: scale(13, 13);
  }
}
.fadeInDown {
  -webkit-animation: fadeInDown 0.5s;
  /* Safari 4.0 - 8.0 */
  animation: fadeInDown 0.5s;
}

.fadeOutUp {
  -webkit-animation: fadeOutUp 0.15s;
  /* Safari 4.0 - 8.0 */
  animation: fadeOutUp 0.15s;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

mark {
  background: #FFF8E1;
  padding: 0.25rem 0.4rem;
  border-radius: 4px;
  font-family: monospace;
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90A4AE;
}

hr {
  border-top: solid rgba(145, 153, 169, 0.3);
  border-width: 1px 0 0 0;
  margin: 1rem 0;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5;
}

p:last-child {
  margin-bottom: 0;
}

.splash-screen {
  width: 100%;
  min-height: 100vh;
  background-color: #F4F7F7;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  height: 40px;
  width: 40px;
  transform: translate(-50%, -50%);
}

[class^=ball-] {
  position: absolute;
  display: block;
  left: 30px;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  transition: all 0.5s;
  animation: circleRotate 4s both infinite;
  transform-origin: 0 250% 0;
}

@keyframes circleRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1440deg);
  }
}
.ball-1 {
  z-index: -1;
  background-color: #4394D8;
  animation-timing-function: cubic-bezier(0.5, 0.3, 0.9, 0.9);
}

.ball-2 {
  z-index: -2;
  background-color: #81A1C1;
  animation-timing-function: cubic-bezier(0.5, 0.6, 0.9, 0.9);
}

.ball-3 {
  z-index: -3;
  background-color: #88C0D0;
  animation-timing-function: cubic-bezier(0.5, 0.9, 0.9, 0.9);
}

.ball-4 {
  z-index: -4;
  background-color: #8FBCBB;
  animation-timing-function: cubic-bezier(0.5, 1.2, 0.9, 0.9);
}

.ball-5 {
  z-index: -5;
  background-color: #D08770;
  animation-timing-function: cubic-bezier(0.5, 1.5, 0.9, 0.9);
}

.ball-6 {
  z-index: -6;
  background-color: #EBCB8B;
  animation-timing-function: cubic-bezier(0.5, 1.8, 0.9, 0.9);
}

.ball-7 {
  z-index: -7;
  background-color: #A3BE8C;
  animation-timing-function: cubic-bezier(0.5, 2.1, 0.9, 0.9);
}

.ball-8 {
  z-index: -8;
  background-color: #B48EAD;
  animation-timing-function: cubic-bezier(0.5, 2.4, 0.9, 0.9);
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 14px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  color: #4c566a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: #eceff4;
}

body .ajax-loader {
  font-size: 32px;
}

body.hidden-overflow {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: #9199a9;
  color: var(--primary-color);
}

.layout-main-mask {
  display: none;
  background-color: #2e3440;
  position: fixed;
  z-index: 997;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.64;
  animation-name: fadeinmask;
  animation-duration: 0.15s;
  opacity: 0.8;
  filter: alpha(opacity=80);
  cursor: pointer;
}

.layout-main {
  margin-left: 80px;
  padding-top: 75px;
  -moz-transition: margin-left 0.15s;
  -o-transition: margin-left 0.15s;
  -webkit-transition: margin-left 0.15s;
  transition: margin-left 0.15s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  -moz-transition: margin-left 0.2s;
  -o-transition: margin-left 0.2s;
  -webkit-transition: margin-left 0.2s;
  transition: margin-left 0.2s;
}

.layout-footer {
  background-color: #ffffff;
  padding: 10px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
}

.layout-footer .logo-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 100%;
}

.layout-footer .logo-text img {
  height: 50px;
  width: 50px;
}

.layout-footer .logo-text .text {
  margin-left: 15px;
}

.layout-footer .logo-text .text h1 {
  font-size: 18px;
  color: #4c566a;
  font-weight: normal;
  margin: 0;
}

.layout-footer .logo-text .text span {
  color: #9199a9;
  font-size: 12px;
}

.layout-footer .icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 24px;
  color: #9199a9;
  height: 100%;
}

.layout-footer .icons i {
  margin-left: 20px;
  cursor: pointer;
  font-size: 28px;
}

.layout-footer .icons i:hover {
  color: #4394D8;
}

.layout-sidebar {
  width: 240px;
  height: 100%;
  position: fixed;
  top: 0;
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  z-index: 999;
  transform: translate3d(-180px, 0px, 0px);
  -moz-transition: transform 0.15s;
  -o-transition: transform 0.15s;
  -webkit-transition: transform 0.15s;
  transition: transform 0.15s;
  -webkit-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
  -moz-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
  box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
}

.layout-sidebar .sidebar-logo {
  height: 76px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 8px;
}

.layout-sidebar .sidebar-logo .sidebar-pin {
  display: none;
  width: 30px;
  height: 30px;
  line-height: 29px;
  text-align: center;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
}

.layout-sidebar .sidebar-logo .sidebar-pin i {
  line-height: inherit;
  font-size: 18px;
}

.layout-sidebar .sidebar-logo img {
  vertical-align: middle;
  height: 45px;
  width: 45px;
  border: 0 none;
}

.layout-sidebar .sidebar-logo .app-name {
  display: none;
  vertical-align: middle;
  font-size: 22.5px;
  margin-left: 4px;
}

.layout-sidebar .layout-menu-container {
  overflow: auto;
  height: calc(100% - 72px);
}

.layout-sidebar .layout-menu-container .layout-menu {
  list-style-type: none;
  margin: 10px 0 0 0;
  padding: 0;
  padding-bottom: 120px;
}

.layout-sidebar .layout-menu-container .layout-menu li {
  padding: 4px 10px;
}

.layout-sidebar .layout-menu-container .layout-menu li.active-menuitem > a i.layout-submenu-toggler {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.layout-sidebar .layout-menu-container .layout-menu li > a {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.layout-sidebar .layout-menu-container .layout-menu li > a i {
  width: 22px;
  height: 22px;
  font-size: 17px;
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -0.5em;
}

.layout-sidebar .layout-menu-container .layout-menu li > a i.layout-submenu-toggler {
  -moz-transition: all 0.15s;
  -o-transition: all 0.15s;
  -webkit-transition: all 0.15s;
  transition: all 0.15s;
  font-size: 22px;
  display: none;
  margin-right: 4px;
  right: 30px;
}

.layout-sidebar .layout-menu-container .layout-menu li > a.rotated-icon .layout-menuitem-icon {
  transform: rotate(90deg);
}

.layout-sidebar .layout-menu-container .layout-menu li > a.rotated-icon .layout-menuitem-icon.pi-mobile {
  margin-right: 2px;
}

.layout-sidebar .layout-menu-container .layout-menu li > a .menuitem-badge {
  display: none;
  margin-left: auto;
}

.layout-sidebar .layout-menu-container .layout-menu li ul {
  overflow: hidden;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.layout-sidebar .layout-menu-container .layout-menu li ul li {
  padding: 4px 0;
}

.layout-sidebar .layout-menu-container .layout-menu li ul li a {
  padding-left: 20px;
}

.layout-sidebar .layout-menu-container .layout-menu li ul li ul li a {
  padding-left: 30px;
}

.layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul li a {
  padding-left: 40px;
}

.layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul li a {
  padding-left: 50px;
}

.layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul li a {
  padding-left: 60px;
}

.layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul ul li a {
  padding-left: 70px;
}

.layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul ul ul li a {
  padding-left: 80px;
}

.layout-sidebar .layout-menu-container .layout-menu > li > a {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.layout-sidebar .layout-menu-container .layout-menu > li.active-menuitem > a {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}

.layout-sidebar .layout-menu-container .layout-menu > li.active-menuitem > ul {
  -moz-border-radius-bottomleft: 6px;
  -webkit-border-bottom-left-radius: 6px;
  border-bottom-left-radius: 6px;
  -moz-border-radius-bottomright: 6px;
  -webkit-border-bottom-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.layout-sidebar .layout-menu-container .layout-menu .menuitem-badge {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.layout-sidebar.layout-sidebar-active {
  transform: translate3d(0px, 0px, 0px);
}

.layout-sidebar.layout-sidebar-active .sidebar-logo {
  flex-flow: row-reverse;
  justify-content: space-between;
  padding: 0 14px;
}

.layout-sidebar.layout-sidebar-active .sidebar-logo img {
  display: inline;
}

.layout-sidebar.layout-sidebar-active .sidebar-logo .app-name {
  display: inline;
}

.layout-sidebar.layout-sidebar-active .sidebar-logo .sidebar-pin {
  display: inline-block;
}

.layout-sidebar.layout-sidebar-active .layout-menu li a i.layout-submenu-toggler {
  display: inline-block;
}

.layout-sidebar.layout-sidebar-active .layout-menu li a .menuitem-badge {
  display: inline-block;
}

.layout-wrapper-static .layout-sidebar {
  transform: translate3d(0px, 0px, 0px);
}

.layout-wrapper-static .layout-sidebar .sidebar-logo {
  flex-flow: row-reverse;
  justify-content: space-between;
  padding: 0 14px;
}

.layout-wrapper-static .layout-sidebar .sidebar-logo img {
  display: inline;
}

.layout-wrapper-static .layout-sidebar .sidebar-logo .app-name {
  display: inline;
}

.layout-wrapper-static .layout-sidebar .sidebar-logo .sidebar-pin {
  display: block;
}

.layout-wrapper-static .layout-sidebar .layout-menu li > a i.layout-submenu-toggler {
  display: inline-block;
}

.layout-wrapper-static .layout-main {
  margin-left: 240px;
}

.layout-wrapper-static .layout-main .layout-topbar {
  width: calc(100% - 240px);
  -moz-transition: width 0.15s;
  -o-transition: width 0.15s;
  -webkit-transition: width 0.15s;
  transition: width 0.15s;
}

.layout-wrapper-static-restore .layout-sidebar {
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
  transform: translate3d(0px, 0px, 0px);
}

.layout-wrapper-static-restore .layout-main {
  -moz-transition: none;
  -o-transition: none;
  -webkit-transition: none;
  transition: none;
  margin-left: 240px;
}

@media (max-width: 991px) {
  .layout-wrapper .layout-sidebar {
    width: 240px;
    top: 75px;
    transform: translate3d(-240px, 0px, 0px);
    -webkit-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
    -moz-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
    box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
  }

  .layout-wrapper .layout-sidebar .sidebar-logo {
    display: none;
  }

  .layout-wrapper.layout-wrapper-static .layout-main .layout-topbar {
    width: 100%;
  }

  .layout-wrapper.layout-wrapper-static-restore .layout-sidebar {
    -moz-transition: transform 0.15s;
    -o-transition: transform 0.15s;
    -webkit-transition: transform 0.15s;
    transition: transform 0.15s;
  }

  .layout-wrapper .layout-main {
    margin-left: 0px;
  }

  .layout-wrapper .layout-topbar {
    width: 100%;
  }

  .layout-wrapper .layout-topbar .topbar-logo {
    margin-top: -10px;
    margin-right: 20px;
    display: block;
  }

  .layout-wrapper .layout-topbar .topbar-logo a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .layout-wrapper .layout-topbar .topbar-logo a .app-name {
    margin-left: 10px;
    color: #4c566a;
    font-size: 18px;
  }

  .layout-wrapper.layout-sidebar-mobile-active .layout-sidebar {
    transform: translate3d(0px, 0px, 0px);
  }

  .layout-wrapper.layout-sidebar-mobile-active .layout-sidebar .layout-menu li a i.layout-submenu-toggler {
    display: inline-block;
  }

  .layout-wrapper.layout-sidebar-mobile-active .layout-sidebar .layout-menu li a .menuitem-badge {
    display: inline-block;
  }

  .layout-wrapper.layout-sidebar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button {
    background-color: #4c566a;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
  }

  .layout-wrapper.layout-sidebar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button i {
    color: #ffffff;
  }

  .layout-wrapper.layout-sidebar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button:hover {
    background-color: #4394D8;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
  }

  .layout-wrapper.layout-sidebar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button:hover i {
    color: #ffffff;
  }

  .layout-wrapper.layout-sidebar-mobile-active .layout-main-mask {
    display: block;
  }
}
@media (min-width: 992px) {
  .layout-wrapper.layout-horizontal .layout-sidebar {
    width: 100%;
    height: 50px;
    top: 75px;
    left: 0;
    z-index: 99;
    transform: none;
    box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .sidebar-logo {
    display: none;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container {
    overflow: visible;
    height: 100%;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu {
    margin: 0px 15px;
    padding-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li {
    width: auto;
    padding: 0;
    position: relative;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > a {
    height: 35px;
    margin: 8px 3px;
    padding: 7px 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-border-radius-topleft: 3px;
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -moz-border-radius-topright: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
    justify-content: space-between;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > a .menuitem-text {
    vertical-align: middle;
    margin-left: 8px;
    order: 2;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > a i {
    position: static;
    vertical-align: middle;
    margin-top: 0;
    top: auto;
    right: auto;
    margin-right: 5px;
    order: 1;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > a i.layout-submenu-toggler {
    order: 3;
    display: inline-block;
    margin-top: 2px;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > ul {
    top: 41px;
    left: 3px;
    width: 230px;
    position: absolute;
    padding: 0;
    margin: 0;
    z-index: 100;
    overflow: auto;
    max-height: 450px;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > ul li a {
    padding-top: 5px;
    padding-bottom: 5px;
    flex-flow: row-reverse;
    justify-content: flex-end;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > ul li a .menuitem-text {
    vertical-align: middle;
    margin-left: 8px;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > ul li a i {
    position: relative;
    right: 0;
    left: 0;
    top: 2px;
    margin-top: 0;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li > ul li a .layout-submenu-toggler {
    display: block;
    left: auto;
    right: 10px;
    position: absolute;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu > li.active-menuitem > ul {
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 2px 6px 0 rgba(29, 29, 33, 0.22);
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li a {
    padding-left: 12px;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li a {
    padding-left: 24px;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul li a {
    padding-left: 36px;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul li a {
    padding-left: 48px;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul li a {
    padding-left: 60px;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul ul li a {
    padding-left: 72px;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li ul li ul li ul ul ul ul ul li a {
    padding-left: 84px;
  }

  .layout-wrapper.layout-horizontal .layout-sidebar .layout-menu-container .layout-menu li a .menuitem-badge {
    left: 18px;
    top: 15px;
    display: block;
  }

  .layout-wrapper.layout-horizontal .layout-main {
    margin-left: 0px;
    padding-top: 125px;
  }

  .layout-wrapper.layout-horizontal .layout-topbar {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }

  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-logo-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 8%;
  }

  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-logo-wrapper .layout-topbar-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-logo-wrapper .layout-topbar-logo .app-name {
    font-size: 22.5px;
    color: #4c566a;
    margin: 0 10px;
  }

  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-logo-wrapper .layout-topbar-logo img {
    height: 45px;
    width: 45px;
  }
}
.layout-menu-light .layout-sidebar {
  background-color: #ffffff;
}

.layout-menu-light .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #2e3440;
}

.layout-menu-light .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #ffffff;
}

.layout-menu-light .layout-sidebar .sidebar-logo .app-name {
  color: #2e3440;
}

.layout-menu-light .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-light .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #4394D8;
}

.layout-menu-light .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #4394D8;
}

.layout-menu-light .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #2e3440;
}

.layout-menu-light .layout-sidebar .layout-menu > li > a i {
  color: #4c566a;
}

.layout-menu-light .layout-sidebar .layout-menu > li > a:hover {
  background-color: #f4f4f4;
}

.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #4394D8;
}

.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}

.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}

.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #4394D8;
}

.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #ffffff;
}

.layout-menu-light .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #ffffff;
}

.layout-menu-light .layout-sidebar .layout-menu > li ul {
  background-color: #e8e8e8;
}

.layout-menu-light .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #2e3440;
}

.layout-menu-light .layout-sidebar .layout-menu > li ul li a i {
  color: #4c566a;
}

.layout-menu-light .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: #f4f4f4;
}

.layout-menu-light .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #4394D8;
}

.layout-menu-light .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #4394D8;
}

@media (min-width: 992px) {
  .layout-menu-light.layout-horizontal .layout-sidebar {
    background-color: #ffffff;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #4394D8;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #4394D8;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #2e3440;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #4c566a;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: #f4f4f4;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #4394D8;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #ffffff;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #2e3440;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #4c566a;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: #f4f4f4;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #4394D8;
  }

  .layout-menu-light.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #4394D8;
  }
}
.layout-menu-dark .layout-sidebar {
  background-color: #2e3440;
}

.layout-menu-dark .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-dark .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-dark .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-dark .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-dark .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #809bbd;
}

.layout-menu-dark .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #809bbd;
}

.layout-menu-dark .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-dark .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-dark .layout-sidebar .layout-menu > li > a:hover {
  background-color: #4c566a;
}

.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #4394D8;
}

.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #ffffff;
}

.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #ffffff;
}

.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #4394D8;
}

.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #ffffff;
}

.layout-menu-dark .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #ffffff;
}

.layout-menu-dark .layout-sidebar .layout-menu > li ul {
  background-color: #4c566a;
}

.layout-menu-dark .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-dark .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-dark .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: #2E3440;
}

.layout-menu-dark .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #809bbd;
}

.layout-menu-dark .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #809bbd;
}

@media (min-width: 992px) {
  .layout-menu-dark.layout-horizontal .layout-sidebar {
    background-color: #2e3440;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #809bbd;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #809bbd;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: #4c566a;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #4394D8;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #ffffff;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #4c566a;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: #2E3440;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #809bbd;
  }

  .layout-menu-dark.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #809bbd;
  }
}
.layout-menu-chile .layout-sidebar {
  background-color: #282D37;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-chile .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-chile .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-chile .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-chile .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-chile .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #88C0D0;
}

.layout-menu-chile .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #88C0D0;
}

.layout-menu-chile .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-chile .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-chile .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #88C0D0;
}

.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #88C0D0;
}

.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-chile .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-chile .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-chile .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-chile .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-chile .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-chile .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #88C0D0;
}

.layout-menu-chile .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #88C0D0;
}

@media (min-width: 992px) {
  .layout-menu-chile.layout-horizontal .layout-sidebar {
    background-color: #282D37;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #88C0D0;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #88C0D0;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-chile.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #88C0D0;
  }
}
.layout-menu-naples .layout-sidebar {
  background-color: #312F3A;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-naples .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-naples .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-naples .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-naples .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-naples .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #A3BE8C;
}

.layout-menu-naples .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #A3BE8C;
}

.layout-menu-naples .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-naples .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-naples .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #A3BE8C;
}

.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #A3BE8C;
}

.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-naples .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-naples .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-naples .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-naples .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-naples .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-naples .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #A3BE8C;
}

.layout-menu-naples .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #A3BE8C;
}

@media (min-width: 992px) {
  .layout-menu-naples.layout-horizontal .layout-sidebar {
    background-color: #312F3A;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #A3BE8C;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #A3BE8C;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #A3BE8C;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #A3BE8C;
  }

  .layout-menu-naples.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #A3BE8C;
  }
}
.layout-menu-georgia .layout-sidebar {
  background-color: #282C36;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-georgia .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-georgia .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-georgia .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-georgia .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-georgia .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #ebcb8b;
}

.layout-menu-georgia .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #ebcb8b;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #ebcb8b;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #ebcb8b;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-georgia .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-georgia .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #ebcb8b;
}

.layout-menu-georgia .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #ebcb8b;
}

@media (min-width: 992px) {
  .layout-menu-georgia.layout-horizontal .layout-sidebar {
    background-color: #282C36;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #ebcb8b;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #ebcb8b;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #ebcb8b;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #ebcb8b;
  }

  .layout-menu-georgia.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #ebcb8b;
  }
}
.layout-menu-infinity .layout-sidebar {
  background-color: #202634;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-infinity .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-infinity .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-infinity .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-infinity .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-infinity .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #88C0D0;
}

.layout-menu-infinity .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #88C0D0;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #88C0D0;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #88C0D0;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-infinity .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-infinity .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #88C0D0;
}

.layout-menu-infinity .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #88C0D0;
}

@media (min-width: 992px) {
  .layout-menu-infinity.layout-horizontal .layout-sidebar {
    background-color: #202634;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #88C0D0;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #88C0D0;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-infinity.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #88C0D0;
  }
}
.layout-menu-chicago .layout-sidebar {
  background-color: #2A2E38;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-chicago .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-chicago .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-chicago .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-chicago .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-chicago .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-chicago .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #EBCB8B;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #EBCB8B;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #EBCB8B;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-chicago .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-chicago .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-chicago .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #EBCB8B;
}

@media (min-width: 992px) {
  .layout-menu-chicago.layout-horizontal .layout-sidebar {
    background-color: #2A2E38;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #EBCB8B;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #EBCB8B;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-chicago.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #EBCB8B;
  }
}
.layout-menu-majesty .layout-sidebar {
  background-color: #4C5158;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-majesty .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-majesty .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-majesty .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-majesty .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-majesty .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #88C0D0;
}

.layout-menu-majesty .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #88C0D0;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #88C0D0;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #88C0D0;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-majesty .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-majesty .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #88C0D0;
}

.layout-menu-majesty .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #88C0D0;
}

@media (min-width: 992px) {
  .layout-menu-majesty.layout-horizontal .layout-sidebar {
    background-color: #4C5158;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #88C0D0;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #88C0D0;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-majesty.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #88C0D0;
  }
}
.layout-menu-fish .layout-sidebar {
  background-color: #264254;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-fish .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-fish .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-fish .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-fish .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-fish .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #B48EAD;
}

.layout-menu-fish .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #B48EAD;
}

.layout-menu-fish .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-fish .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-fish .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #B48EAD;
}

.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #B48EAD;
}

.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-fish .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-fish .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-fish .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-fish .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-fish .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-fish .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #B48EAD;
}

.layout-menu-fish .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #B48EAD;
}

@media (min-width: 992px) {
  .layout-menu-fish.layout-horizontal .layout-sidebar {
    background-color: #264254;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #B48EAD;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #B48EAD;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #B48EAD;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #B48EAD;
  }

  .layout-menu-fish.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #B48EAD;
  }
}
.layout-menu-dawn .layout-sidebar {
  background-color: #20505D;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-dawn .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-dawn .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-dawn .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-dawn .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-dawn .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-dawn .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #EBCB8B;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #EBCB8B;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #EBCB8B;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-dawn .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-dawn .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-dawn .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #EBCB8B;
}

@media (min-width: 992px) {
  .layout-menu-dawn.layout-horizontal .layout-sidebar {
    background-color: #20505D;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #EBCB8B;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #EBCB8B;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-dawn.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #EBCB8B;
  }
}
.layout-menu-record .layout-sidebar {
  background-color: #202928;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-record .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-record .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-record .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-record .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-record .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #D08770;
}

.layout-menu-record .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #D08770;
}

.layout-menu-record .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-record .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-record .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #D08770;
}

.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #D08770;
}

.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-record .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-record .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-record .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-record .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-record .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.42);
}

.layout-menu-record .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #D08770;
}

.layout-menu-record .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #D08770;
}

@media (min-width: 992px) {
  .layout-menu-record.layout-horizontal .layout-sidebar {
    background-color: #202928;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #D08770;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #D08770;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #D08770;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.42);
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #D08770;
  }

  .layout-menu-record.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #D08770;
  }
}
.layout-menu-pool .layout-sidebar {
  background-color: #475A62;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-pool .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-pool .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-pool .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-pool .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-pool .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #A3BE8C;
}

.layout-menu-pool .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #A3BE8C;
}

.layout-menu-pool .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-pool .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-pool .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #A3BE8C;
}

.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #A3BE8C;
}

.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-pool .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-pool .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-pool .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-pool .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-pool .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-pool .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #A3BE8C;
}

.layout-menu-pool .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #A3BE8C;
}

@media (min-width: 992px) {
  .layout-menu-pool.layout-horizontal .layout-sidebar {
    background-color: #475A62;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #A3BE8C;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #A3BE8C;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #A3BE8C;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #A3BE8C;
  }

  .layout-menu-pool.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #A3BE8C;
  }
}
.layout-menu-metal .layout-sidebar {
  background-color: #262A33;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-metal .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-metal .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-metal .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-metal .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-metal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-metal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #EBCB8B;
}

.layout-menu-metal .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-metal .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-metal .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #EBCB8B;
}

.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #EBCB8B;
}

.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-metal .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-metal .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-metal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-metal .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-metal .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-metal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-metal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #EBCB8B;
}

@media (min-width: 992px) {
  .layout-menu-metal.layout-horizontal .layout-sidebar {
    background-color: #262A33;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #EBCB8B;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #EBCB8B;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-metal.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #EBCB8B;
  }
}
.layout-menu-china .layout-sidebar {
  background-color: #1E2A31;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-china .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-china .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-china .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-china .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-china .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #BF616A;
}

.layout-menu-china .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #BF616A;
}

.layout-menu-china .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-china .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-china .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #BF616A;
}

.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #BF616A;
}

.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-china .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-china .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-china .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-china .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-china .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}

.layout-menu-china .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #BF616A;
}

.layout-menu-china .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #BF616A;
}

@media (min-width: 992px) {
  .layout-menu-china.layout-horizontal .layout-sidebar {
    background-color: #1E2A31;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #BF616A;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #BF616A;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #BF616A;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.32);
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #BF616A;
  }

  .layout-menu-china.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #BF616A;
  }
}
.layout-menu-table .layout-sidebar {
  background-color: #4F5259;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-table .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-table .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-table .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-table .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-table .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-table .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #EBCB8B;
}

.layout-menu-table .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-table .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-table .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #EBCB8B;
}

.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #EBCB8B;
}

.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-table .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-table .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-table .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-table .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-table .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-table .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-table .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #EBCB8B;
}

@media (min-width: 992px) {
  .layout-menu-table.layout-horizontal .layout-sidebar {
    background-color: #4F5259;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #EBCB8B;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #EBCB8B;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-table.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #EBCB8B;
  }
}
.layout-menu-panorama .layout-sidebar {
  background-color: #252E38;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-panorama .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-panorama .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-panorama .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-panorama .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-panorama .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #88C0D0;
}

.layout-menu-panorama .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #88C0D0;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #88C0D0;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #88C0D0;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-panorama .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}

.layout-menu-panorama .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #88C0D0;
}

.layout-menu-panorama .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #88C0D0;
}

@media (min-width: 992px) {
  .layout-menu-panorama.layout-horizontal .layout-sidebar {
    background-color: #252E38;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #88C0D0;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #88C0D0;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.32);
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-panorama.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #88C0D0;
  }
}
.layout-menu-barcelona .layout-sidebar {
  background-color: #222932;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-barcelona .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-barcelona .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-barcelona .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-barcelona .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-barcelona .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-barcelona .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #EBCB8B;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #EBCB8B;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #EBCB8B;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.42);
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-barcelona .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #EBCB8B;
}

@media (min-width: 992px) {
  .layout-menu-barcelona.layout-horizontal .layout-sidebar {
    background-color: #222932;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #EBCB8B;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #EBCB8B;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.42);
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-barcelona.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #EBCB8B;
  }
}
.layout-menu-underwater .layout-sidebar {
  background-color: #202631;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-underwater .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-underwater .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-underwater .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-underwater .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-underwater .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #8FBCBB;
}

.layout-menu-underwater .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #8FBCBB;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #8FBCBB;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #8FBCBB;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-underwater .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.42);
}

.layout-menu-underwater .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #8FBCBB;
}

.layout-menu-underwater .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #8FBCBB;
}

@media (min-width: 992px) {
  .layout-menu-underwater.layout-horizontal .layout-sidebar {
    background-color: #202631;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #8FBCBB;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #8FBCBB;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #8FBCBB;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.42);
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #8FBCBB;
  }

  .layout-menu-underwater.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #8FBCBB;
  }
}
.layout-menu-symmetry .layout-sidebar {
  background-color: #545A65;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-symmetry .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-symmetry .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-symmetry .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-symmetry .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-symmetry .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #8FBCBB;
}

.layout-menu-symmetry .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #8FBCBB;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #8FBCBB;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #8FBCBB;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.42);
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #8FBCBB;
}

.layout-menu-symmetry .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #8FBCBB;
}

@media (min-width: 992px) {
  .layout-menu-symmetry.layout-horizontal .layout-sidebar {
    background-color: #545A65;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #8FBCBB;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #8FBCBB;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #8FBCBB;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.42);
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #8FBCBB;
  }

  .layout-menu-symmetry.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #8FBCBB;
  }
}
.layout-menu-rain .layout-sidebar {
  background-color: #35414F;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-rain .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-rain .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-rain .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-rain .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-rain .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #D08770;
}

.layout-menu-rain .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #D08770;
}

.layout-menu-rain .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-rain .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-rain .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #D08770;
}

.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #D08770;
}

.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-rain .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-rain .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-rain .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-rain .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-rain .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-rain .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #D08770;
}

.layout-menu-rain .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #D08770;
}

@media (min-width: 992px) {
  .layout-menu-rain.layout-horizontal .layout-sidebar {
    background-color: #35414F;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #D08770;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #D08770;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #D08770;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #D08770;
  }

  .layout-menu-rain.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #D08770;
  }
}
.layout-menu-utah .layout-sidebar {
  background-color: #3D2E34;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-utah .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-utah .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-utah .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-utah .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-utah .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-utah .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #EBCB8B;
}

.layout-menu-utah .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-utah .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-utah .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #EBCB8B;
}

.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #EBCB8B;
}

.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-utah .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-utah .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-utah .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-utah .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-utah .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-utah .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-utah .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #EBCB8B;
}

@media (min-width: 992px) {
  .layout-menu-utah.layout-horizontal .layout-sidebar {
    background-color: #3D2E34;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #EBCB8B;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #EBCB8B;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-utah.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #EBCB8B;
  }
}
.layout-menu-wave .layout-sidebar {
  background-color: #1A2B39;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-wave .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-wave .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-wave .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-wave .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-wave .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #81A1C1;
}

.layout-menu-wave .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #81A1C1;
}

.layout-menu-wave .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-wave .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-wave .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #81A1C1;
}

.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #81A1C1;
}

.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-wave .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-wave .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-wave .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-wave .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-wave .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-wave .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #81A1C1;
}

.layout-menu-wave .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #81A1C1;
}

@media (min-width: 992px) {
  .layout-menu-wave.layout-horizontal .layout-sidebar {
    background-color: #1A2B39;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #81A1C1;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #81A1C1;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #81A1C1;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #81A1C1;
  }

  .layout-menu-wave.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #81A1C1;
  }
}
.layout-menu-flora .layout-sidebar {
  background-color: #202422;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-flora .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-flora .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-flora .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-flora .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-flora .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-flora .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #EBCB8B;
}

.layout-menu-flora .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-flora .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-flora .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #EBCB8B;
}

.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #EBCB8B;
}

.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-flora .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-flora .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-flora .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-flora .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-flora .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.42);
}

.layout-menu-flora .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-flora .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #EBCB8B;
}

@media (min-width: 992px) {
  .layout-menu-flora.layout-horizontal .layout-sidebar {
    background-color: #202422;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #EBCB8B;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #EBCB8B;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.42);
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-flora.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #EBCB8B;
  }
}
.layout-menu-speed .layout-sidebar {
  background-color: #272634;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-speed .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-speed .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-speed .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-speed .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-speed .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-speed .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #EBCB8B;
}

.layout-menu-speed .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-speed .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-speed .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #EBCB8B;
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #EBCB8B;
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #EBCB8B;
}

@media (min-width: 992px) {
  .layout-menu-speed.layout-horizontal .layout-sidebar {
    background-color: #272634;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #EBCB8B;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #EBCB8B;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.32);
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #EBCB8B;
  }
}
.layout-menu-speed .layout-sidebar {
  background-color: #272634;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-speed .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-speed .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-speed .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-speed .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-speed .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-speed .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #EBCB8B;
}

.layout-menu-speed .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-speed .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-speed .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #EBCB8B;
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #EBCB8B;
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-speed .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-speed .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #EBCB8B;
}

@media (min-width: 992px) {
  .layout-menu-speed.layout-horizontal .layout-sidebar {
    background-color: #272634;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #EBCB8B;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #EBCB8B;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.32);
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-speed.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #EBCB8B;
  }
}
.layout-menu-canopy .layout-sidebar {
  background-color: #22272F;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-canopy .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-canopy .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-canopy .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-canopy .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-canopy .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #A3BE8C;
}

.layout-menu-canopy .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #A3BE8C;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #A3BE8C;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #A3BE8C;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-canopy .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-canopy .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #A3BE8C;
}

.layout-menu-canopy .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #A3BE8C;
}

@media (min-width: 992px) {
  .layout-menu-canopy.layout-horizontal .layout-sidebar {
    background-color: #22272F;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #A3BE8C;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #A3BE8C;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #A3BE8C;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #A3BE8C;
  }

  .layout-menu-canopy.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #A3BE8C;
  }
}
.layout-menu-sanpaolo .layout-sidebar {
  background-color: #252A32;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-sanpaolo .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-sanpaolo .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-sanpaolo .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #8FBCBB;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #8FBCBB;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #8FBCBB;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #8FBCBB;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #8FBCBB;
}

.layout-menu-sanpaolo .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #8FBCBB;
}

@media (min-width: 992px) {
  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar {
    background-color: #252A32;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #8FBCBB;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #8FBCBB;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #8FBCBB;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.32);
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #8FBCBB;
  }

  .layout-menu-sanpaolo.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #8FBCBB;
  }
}
.layout-menu-basketball .layout-sidebar {
  background-color: #293531;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-basketball .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-basketball .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-basketball .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-basketball .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-basketball .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #D08770;
}

.layout-menu-basketball .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #D08770;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #D08770;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #D08770;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-basketball .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.42);
}

.layout-menu-basketball .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #D08770;
}

.layout-menu-basketball .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #D08770;
}

@media (min-width: 992px) {
  .layout-menu-basketball.layout-horizontal .layout-sidebar {
    background-color: #293531;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #D08770;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #D08770;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #D08770;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.42);
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #D08770;
  }

  .layout-menu-basketball.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #D08770;
  }
}
.layout-menu-misty .layout-sidebar {
  background-color: #202734;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-misty .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-misty .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-misty .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-misty .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-misty .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #D08770;
}

.layout-menu-misty .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #D08770;
}

.layout-menu-misty .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-misty .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-misty .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #D08770;
}

.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #D08770;
}

.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-misty .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-misty .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-misty .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-misty .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-misty .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-misty .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #D08770;
}

.layout-menu-misty .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #D08770;
}

@media (min-width: 992px) {
  .layout-menu-misty.layout-horizontal .layout-sidebar {
    background-color: #202734;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #D08770;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #D08770;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #D08770;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #D08770;
  }

  .layout-menu-misty.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #D08770;
  }
}
.layout-menu-summit .layout-sidebar {
  background-color: #212733;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-summit .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-summit .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-summit .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-summit .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-summit .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #88C0D0;
}

.layout-menu-summit .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #88C0D0;
}

.layout-menu-summit .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-summit .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-summit .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #88C0D0;
}

.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #88C0D0;
}

.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-summit .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-summit .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-summit .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-summit .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-summit .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-summit .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #88C0D0;
}

.layout-menu-summit .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #88C0D0;
}

@media (min-width: 992px) {
  .layout-menu-summit.layout-horizontal .layout-sidebar {
    background-color: #212733;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #88C0D0;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #88C0D0;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-summit.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #88C0D0;
  }
}
.layout-menu-snow .layout-sidebar {
  background-color: #414A55;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-snow .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-snow .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-snow .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-snow .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-snow .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #81A1C1;
}

.layout-menu-snow .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #81A1C1;
}

.layout-menu-snow .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-snow .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-snow .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #81A1C1;
}

.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #81A1C1;
}

.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-snow .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-snow .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-snow .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-snow .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-snow .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-snow .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #81A1C1;
}

.layout-menu-snow .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #81A1C1;
}

@media (min-width: 992px) {
  .layout-menu-snow.layout-horizontal .layout-sidebar {
    background-color: #414A55;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #81A1C1;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #81A1C1;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #81A1C1;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #81A1C1;
  }

  .layout-menu-snow.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #81A1C1;
  }
}
.layout-menu-wall .layout-sidebar {
  background-color: #50555F;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-wall .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-wall .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-wall .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-wall .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-wall .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #81A1C1;
}

.layout-menu-wall .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #81A1C1;
}

.layout-menu-wall .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-wall .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-wall .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #81A1C1;
}

.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #81A1C1;
}

.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-wall .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-wall .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-wall .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-wall .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-wall .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.42);
}

.layout-menu-wall .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #81A1C1;
}

.layout-menu-wall .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #81A1C1;
}

@media (min-width: 992px) {
  .layout-menu-wall.layout-horizontal .layout-sidebar {
    background-color: #50555F;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #81A1C1;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #81A1C1;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #81A1C1;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.42);
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #81A1C1;
  }

  .layout-menu-wall.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #81A1C1;
  }
}
.layout-menu-ferris .layout-sidebar {
  background-color: #262B33;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-ferris .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-ferris .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-ferris .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-ferris .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-ferris .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #A3BE8C;
}

.layout-menu-ferris .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #A3BE8C;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #A3BE8C;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #A3BE8C;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-ferris .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.32);
}

.layout-menu-ferris .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #A3BE8C;
}

.layout-menu-ferris .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #A3BE8C;
}

@media (min-width: 992px) {
  .layout-menu-ferris.layout-horizontal .layout-sidebar {
    background-color: #262B33;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #A3BE8C;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #A3BE8C;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #A3BE8C;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.32);
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #A3BE8C;
  }

  .layout-menu-ferris.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #A3BE8C;
  }
}
.layout-menu-ship .layout-sidebar {
  background-color: #262B35;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-ship .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-ship .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-ship .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-ship .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-ship .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-ship .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #EBCB8B;
}

.layout-menu-ship .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-ship .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-ship .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #EBCB8B;
}

.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #EBCB8B;
}

.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-ship .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-ship .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-ship .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-ship .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-ship .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.42);
}

.layout-menu-ship .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #EBCB8B;
}

.layout-menu-ship .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #EBCB8B;
}

@media (min-width: 992px) {
  .layout-menu-ship.layout-horizontal .layout-sidebar {
    background-color: #262B35;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #EBCB8B;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #EBCB8B;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.42);
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #EBCB8B;
  }

  .layout-menu-ship.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #EBCB8B;
  }
}
.layout-menu-ny .layout-sidebar {
  background-color: #585D67;
  background-size: 240px 100%;
  background-repeat: no-repeat;
}

.layout-menu-ny .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-ny .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-ny .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-ny .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-ny .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #8FBCBB;
}

.layout-menu-ny .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #8FBCBB;
}

.layout-menu-ny .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #eceff4;
}

.layout-menu-ny .layout-sidebar .layout-menu > li > a i {
  color: #eceff4;
}

.layout-menu-ny .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #8FBCBB;
}

.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #3b4252;
}

.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #2e3440;
}

.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #8FBCBB;
}

.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #3b4252;
}

.layout-menu-ny .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #2e3440;
}

.layout-menu-ny .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-ny .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #eceff4;
}

.layout-menu-ny .layout-sidebar .layout-menu > li ul li a i {
  color: #eceff4;
}

.layout-menu-ny .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-ny .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #8FBCBB;
}

.layout-menu-ny .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #8FBCBB;
}

@media (min-width: 992px) {
  .layout-menu-ny.layout-horizontal .layout-sidebar {
    background-color: #585D67;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #8FBCBB;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #8FBCBB;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #eceff4;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #8FBCBB;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #3b4252;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #2e3440;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #eceff4;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #eceff4;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #8FBCBB;
  }

  .layout-menu-ny.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #8FBCBB;
  }
}
.layout-menu-cyan .layout-sidebar {
  background-color: #3C5259;
}

.layout-menu-cyan .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-cyan .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-cyan .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-cyan .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-cyan .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #D08770;
}

.layout-menu-cyan .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #D08770;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #ffffff;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li > a i {
  color: #ffffff;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #D08770;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #343a40;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #343a40;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #D08770;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #343a40;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #343a40;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-cyan .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #ffffff;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li ul li a i {
  color: #ffffff;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-cyan .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #D08770;
}

.layout-menu-cyan .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #D08770;
}

@media (min-width: 992px) {
  .layout-menu-cyan.layout-horizontal .layout-sidebar {
    background-color: #3C5259;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #D08770;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #D08770;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #ffffff;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #D08770;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #343a40;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #343a40;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #ffffff;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #D08770;
  }

  .layout-menu-cyan.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #D08770;
  }
}
.layout-menu-violet .layout-sidebar {
  background-color: #4A4358;
}

.layout-menu-violet .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-violet .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-violet .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-violet .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-violet .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #F8C869;
}

.layout-menu-violet .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #F8C869;
}

.layout-menu-violet .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #ffffff;
}

.layout-menu-violet .layout-sidebar .layout-menu > li > a i {
  color: #ffffff;
}

.layout-menu-violet .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #F8C869;
}

.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #343a40;
}

.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #343a40;
}

.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #F8C869;
}

.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #343a40;
}

.layout-menu-violet .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #343a40;
}

.layout-menu-violet .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-violet .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #ffffff;
}

.layout-menu-violet .layout-sidebar .layout-menu > li ul li a i {
  color: #ffffff;
}

.layout-menu-violet .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-violet .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #F8C869;
}

.layout-menu-violet .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #F8C869;
}

@media (min-width: 992px) {
  .layout-menu-violet.layout-horizontal .layout-sidebar {
    background-color: #4A4358;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #F8C869;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #F8C869;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #ffffff;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #F8C869;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #343a40;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #343a40;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #ffffff;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #F8C869;
  }

  .layout-menu-violet.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #F8C869;
  }
}
.layout-menu-red .layout-sidebar {
  background-color: #5E4346;
}

.layout-menu-red .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-red .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-red .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-red .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-red .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #8FBCBB;
}

.layout-menu-red .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #8FBCBB;
}

.layout-menu-red .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #ffffff;
}

.layout-menu-red .layout-sidebar .layout-menu > li > a i {
  color: #ffffff;
}

.layout-menu-red .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #8FBCBB;
}

.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #343a40;
}

.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #343a40;
}

.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #8FBCBB;
}

.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #343a40;
}

.layout-menu-red .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #343a40;
}

.layout-menu-red .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-red .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #ffffff;
}

.layout-menu-red .layout-sidebar .layout-menu > li ul li a i {
  color: #ffffff;
}

.layout-menu-red .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-red .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #8FBCBB;
}

.layout-menu-red .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #8FBCBB;
}

@media (min-width: 992px) {
  .layout-menu-red.layout-horizontal .layout-sidebar {
    background-color: #5E4346;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #8FBCBB;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #8FBCBB;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #ffffff;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #8FBCBB;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #343a40;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #343a40;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #ffffff;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #8FBCBB;
  }

  .layout-menu-red.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #8FBCBB;
  }
}
.layout-menu-blue .layout-sidebar {
  background-color: #354563;
}

.layout-menu-blue .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-blue .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-blue .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-blue .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-blue .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #f8c869;
}

.layout-menu-blue .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #f8c869;
}

.layout-menu-blue .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #ffffff;
}

.layout-menu-blue .layout-sidebar .layout-menu > li > a i {
  color: #ffffff;
}

.layout-menu-blue .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #f8c869;
}

.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #343a40;
}

.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #343a40;
}

.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #f8c869;
}

.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #343a40;
}

.layout-menu-blue .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #343a40;
}

.layout-menu-blue .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-blue .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #ffffff;
}

.layout-menu-blue .layout-sidebar .layout-menu > li ul li a i {
  color: #ffffff;
}

.layout-menu-blue .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-blue .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #f8c869;
}

.layout-menu-blue .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #f8c869;
}

@media (min-width: 992px) {
  .layout-menu-blue.layout-horizontal .layout-sidebar {
    background-color: #354563;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #f8c869;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #f8c869;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #ffffff;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #f8c869;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #343a40;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #343a40;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #ffffff;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #f8c869;
  }

  .layout-menu-blue.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #f8c869;
  }
}
.layout-menu-porsuk .layout-sidebar {
  background-color: #21596E;
}

.layout-menu-porsuk .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-porsuk .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-porsuk .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-porsuk .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-porsuk .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #A3BE8C;
}

.layout-menu-porsuk .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #A3BE8C;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #ffffff;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li > a i {
  color: #ffffff;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #A3BE8C;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #343a40;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #343a40;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #A3BE8C;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #343a40;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #343a40;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #ffffff;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li ul li a i {
  color: #ffffff;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #A3BE8C;
}

.layout-menu-porsuk .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #A3BE8C;
}

@media (min-width: 992px) {
  .layout-menu-porsuk.layout-horizontal .layout-sidebar {
    background-color: #21596E;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #A3BE8C;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #A3BE8C;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #ffffff;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #A3BE8C;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #343a40;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #343a40;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #ffffff;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #A3BE8C;
  }

  .layout-menu-porsuk.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #A3BE8C;
  }
}
.layout-menu-pink .layout-sidebar {
  background-color: #8C515E;
}

.layout-menu-pink .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-pink .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-pink .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-pink .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-pink .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #f8c869;
}

.layout-menu-pink .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #f8c869;
}

.layout-menu-pink .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #ffffff;
}

.layout-menu-pink .layout-sidebar .layout-menu > li > a i {
  color: #ffffff;
}

.layout-menu-pink .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #f8c869;
}

.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #343a40;
}

.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #343a40;
}

.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #f8c869;
}

.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #343a40;
}

.layout-menu-pink .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #343a40;
}

.layout-menu-pink .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-pink .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #ffffff;
}

.layout-menu-pink .layout-sidebar .layout-menu > li ul li a i {
  color: #ffffff;
}

.layout-menu-pink .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-pink .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #f8c869;
}

.layout-menu-pink .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #f8c869;
}

@media (min-width: 992px) {
  .layout-menu-pink.layout-horizontal .layout-sidebar {
    background-color: #8C515E;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #f8c869;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #f8c869;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #ffffff;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #f8c869;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #343a40;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #343a40;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #ffffff;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #f8c869;
  }

  .layout-menu-pink.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #f8c869;
  }
}
.layout-menu-purple .layout-sidebar {
  background-color: #60476f;
}

.layout-menu-purple .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-purple .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-purple .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-purple .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-purple .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #f8c869;
}

.layout-menu-purple .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #f8c869;
}

.layout-menu-purple .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #ffffff;
}

.layout-menu-purple .layout-sidebar .layout-menu > li > a i {
  color: #ffffff;
}

.layout-menu-purple .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #f8c869;
}

.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #343a40;
}

.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #343a40;
}

.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #f8c869;
}

.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #343a40;
}

.layout-menu-purple .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #343a40;
}

.layout-menu-purple .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-purple .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #ffffff;
}

.layout-menu-purple .layout-sidebar .layout-menu > li ul li a i {
  color: #ffffff;
}

.layout-menu-purple .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-purple .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #f8c869;
}

.layout-menu-purple .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #f8c869;
}

@media (min-width: 992px) {
  .layout-menu-purple.layout-horizontal .layout-sidebar {
    background-color: #60476f;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #f8c869;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #f8c869;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #ffffff;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #f8c869;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #343a40;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #343a40;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #ffffff;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #f8c869;
  }

  .layout-menu-purple.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #f8c869;
  }
}
.layout-menu-orange .layout-sidebar {
  background-color: #9B6F61;
}

.layout-menu-orange .layout-sidebar .sidebar-logo .sidebar-pin {
  background-color: #ffffff;
}

.layout-menu-orange .layout-sidebar .sidebar-logo .sidebar-pin i {
  color: #2e3440;
}

.layout-menu-orange .layout-sidebar .sidebar-logo .app-name {
  color: #ffffff;
}

.layout-menu-orange .layout-sidebar .layout-menu li > a {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
}

.layout-menu-orange .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
  color: #88C0D0;
}

.layout-menu-orange .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
  color: #88C0D0;
}

.layout-menu-orange .layout-sidebar .layout-menu > li > a .menuitem-text {
  color: #ffffff;
}

.layout-menu-orange .layout-sidebar .layout-menu > li > a i {
  color: #ffffff;
}

.layout-menu-orange .layout-sidebar .layout-menu > li > a:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a {
  background-color: #88C0D0;
}

.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
  color: #343a40;
}

.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a i {
  color: #343a40;
}

.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink {
  background-color: #88C0D0;
}

.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink .menuitem-text {
  color: #343a40;
}

.layout-menu-orange .layout-sidebar .layout-menu > li.active-menuitem > a.active-menuitem-routerlink i {
  color: #343a40;
}

.layout-menu-orange .layout-sidebar .layout-menu > li ul {
  background-color: rgba(255, 255, 255, 0.12);
}

.layout-menu-orange .layout-sidebar .layout-menu > li ul li a .menuitem-text {
  color: #ffffff;
}

.layout-menu-orange .layout-sidebar .layout-menu > li ul li a i {
  color: #ffffff;
}

.layout-menu-orange .layout-sidebar .layout-menu > li ul li a:hover {
  background-color: rgba(0, 0, 0, 0.36);
}

.layout-menu-orange .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
  color: #88C0D0;
}

.layout-menu-orange .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
  color: #88C0D0;
}

@media (min-width: 992px) {
  .layout-menu-orange.layout-horizontal .layout-sidebar {
    background-color: #9B6F61;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu li > a.active-menuitem-routerlink i {
    color: #88C0D0;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li > a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li > a i {
    color: #ffffff;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li > a:hover {
    background-color: rgba(255, 255, 255, 0.24);
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a {
    background-color: #88C0D0;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a .menuitem-text {
    color: #343a40;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li.active-menuitem > a i {
    color: #343a40;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul {
    background-color: #2e3440;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul li a .menuitem-text {
    color: #ffffff;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul li a i {
    color: #ffffff;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul li a:hover {
    background-color: rgba(0, 0, 0, 0.36);
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a .menuitem-text {
    color: #88C0D0;
  }

  .layout-menu-orange.layout-horizontal .layout-sidebar .layout-menu > li ul li.active-menuitem > a i {
    color: #88C0D0;
  }
}
.layout-topbar {
  background-color: #eceff4;
  position: fixed;
  top: 0;
  z-index: 998;
  width: calc(100% - 60px);
  -moz-transition: width 0.15s;
  -o-transition: width 0.15s;
  -webkit-transition: width 0.15s;
  transition: width 0.15s;
  -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.layout-topbar .layout-topbar-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 75px;
  padding: 0 2em;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  flex-grow: 1;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button,
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button,
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button,
.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo {
  display: none;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu-button {
  padding: 8px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  border: none;
  cursor: pointer;
  height: 35px;
  background-color: #ffffff;
  color: #4c566a;
  margin-top: 2px;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu-button i {
  font-size: 20px;
  vertical-align: middle;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu-button:hover, .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu-button:focus {
  background-color: #4394D8;
  color: #ffffff;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu {
  position: absolute;
  padding: 12px 18px;
  padding-bottom: 24px;
  background-color: #ffffff;
  top: 60px;
  display: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  list-style-type: none;
  -webkit-box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
  -moz-box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
  box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
  z-index: 1000;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu:before {
  width: 100%;
  height: 6px;
  background-color: #4394D8;
  content: " ";
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  position: absolute;
  top: -5px;
  left: 0px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li {
  min-width: 200px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > a {
  font-size: 12px;
  color: #4c566a;
  padding-bottom: 6px;
  border-bottom: 1px solid rgba(195, 204, 221, 0.5);
  width: 90%;
  display: block;
  cursor: default;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > a i {
  display: none;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul {
  padding: 0;
  margin-top: 15px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li i {
  font-size: 10px;
  color: #4c566a;
  padding-right: 10px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li > span h5 {
  color: #4c566a;
  font-weight: 500;
  margin: 0;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li > span span {
  color: #9199a9;
  font-size: 12px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li.active-row i {
  color: #a3be8c;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li:hover i {
  color: #4394D8;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li:hover > span h5 {
  color: #4394D8;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu.layout-megamenu-active {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a {
  width: 100%;
  padding: 0.7em;
  margin: 0 0.5em;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a.layout-rightpanel-button i {
  font-size: 22px;
  font-weight: bold;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a i {
  color: #4c566a;
  font-size: 22px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a:hover i, .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a:focus i {
  color: #4394D8;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a.layout-rightpanel-button i {
  -moz-transition: transform 0.15s;
  -o-transition: transform 0.15s;
  -webkit-transition: transform 0.15s;
  transition: transform 0.15s;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul {
  display: none;
  position: absolute;
  top: 75px;
  left: 5px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
  min-width: 250px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul:before {
  width: 50px;
  height: 6px;
  background-color: #4394D8;
  content: " ";
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  position: absolute;
  top: -5px;
  left: 0px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header {
  background-color: #4394D8;
  padding: 18px;
  display: block;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header h1 {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
  color: #ffffff;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header span {
  font-size: 13px;
  color: #f0f4ec;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li {
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  color: #4c566a;
  cursor: pointer;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li img {
  margin-right: 8px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  flex-grow: 1;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li .menu-text p {
  margin: 0;
  color: #4c566a;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li .menu-text span {
  color: #9199a9;
  font-size: 12px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.layout-submenu-item:hover {
  background-color: #f9fafb;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.layout-submenu-item:hover i {
  color: #4c566a;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar {
  padding: 0;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar p-calendar, .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-calendar {
  width: 100%;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-datepicker-inline {
  background-color: #ffffff;
  border: none;
  width: 100%;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-datepicker-inline .p-datepicker-next,
.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-datepicker-inline .p-datepicker-prev {
  display: none;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-datepicker-inline .p-datepicker-calendar th {
  color: #9199a9;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar .p-datepicker-inline .p-datepicker-calendar td {
  color: #4c566a;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals {
  padding: 0;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 0.5px solid rgba(145, 153, 169, 0.3);
  border-right: 0.5px solid rgba(145, 153, 169, 0.3);
  padding: 15px 5px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul > li:hover {
  background-color: #f9fafb;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul > li:hover i {
  color: #4c566a;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu {
  display: block;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  position: static;
  min-width: auto;
  margin-right: 0.5em;
  list-style-type: none;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu:before {
  display: none;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu > li {
  padding: 0;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu > li .search-input-wrapper {
  position: relative;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu > li .search-input-wrapper input {
  padding: 10px 40px 10px 10px;
  width: 180px;
  border: none;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  color: #4c566a;
  font-size: 13px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu > li .search-input-wrapper input::placeholder {
  color: #c9ccd2;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul.search-item-submenu > li .search-input-wrapper i {
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -10px;
  color: #d8dee9;
  font-size: 20px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem > ul {
  display: block;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > a {
  display: none;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item {
  position: relative;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  padding: 0.5em;
  margin: 0 0.5em;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > a .profile-image-wrapper img {
  height: 45px;
  width: 45px;
  border: 0 none;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > a .profile-info-wrapper {
  margin-left: 15px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > a .profile-info-wrapper h3 {
  font-size: 16px;
  font-weight: 500;
  color: #4c566a;
  margin: 0;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > a .profile-info-wrapper span {
  font-size: 13px;
  font-weight: 500;
  color: #9199a9;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul {
  display: none;
  position: absolute;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
  min-width: 250px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul:before {
  width: 54px;
  height: 6px;
  background-color: #4394D8;
  content: " ";
  -moz-border-radius-topleft: 2px;
  -webkit-border-top-left-radius: 2px;
  border-top-left-radius: 2px;
  -moz-border-radius-topright: 2px;
  -webkit-border-top-right-radius: 2px;
  border-top-right-radius: 2px;
  position: absolute;
  top: -5px;
  left: 0px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li {
  border-bottom: 1px solid rgba(195, 204, 221, 0.3);
  border-top: none;
  cursor: pointer;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  color: #4c566a;
  cursor: pointer;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  flex-grow: 1;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li:first-child {
  border-bottom: none;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li:last-child {
  cursor: initial;
  border: none;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header {
  padding: 12px 18px 0 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .performance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .performance span {
  margin-bottom: 12px;
  font-size: 11px;
  color: #c3ccdd;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-align: right;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .profile img {
  margin: 0;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .profile h1 {
  color: #eceff4;
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.profile-submenu-header .profile span {
  color: #c3ccdd;
  font-size: 13px;
  margin-bottom: 10px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li .icon {
  flex-grow: 0;
  font-size: 18px;
  margin-right: 10px;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li .icon.icon-1 {
  color: #d08770;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li .icon.icon-2 {
  color: #81a1c1;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li .icon.icon-3 {
  color: #a3be8c;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.layout-submenu-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.layout-submenu-footer button {
  padding: 6px 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.layout-submenu-footer button.signout-button {
  color: #434c5e;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #d8dee9;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item > ul > li.layout-submenu-footer button.buy-mirage-button {
  color: #4394D8;
  text-decoration: underline;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item.active-topmenuitem > ul {
  display: block;
  left: auto;
  right: 0;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-item.active-topmenuitem > ul::before {
  left: auto;
  right: 0;
}

.layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper {
  display: none;
}

@media (min-width: 992px) {
  .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul {
    display: block !important;
  }
}
@media (max-width: 991px) {
  .layout-wrapper .layout-topbar .layout-topbar-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: auto;
    padding: 0;
    -webkit-box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
    -moz-box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
    box-shadow: 0 3px 5px 0 rgba(33, 35, 39, 0.15);
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left {
    padding: 0 1em;
    height: 75px;
    -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button,
.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button,
.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-left: 10px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button i,
.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button i,
.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button i {
    color: #4c566a;
    font-size: 26px;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .sidebar-menu-button:hover i,
.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button:hover i,
.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button:hover i {
    color: #4394D8;
    font-size: 26px;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo-wrapper .layout-topbar-logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo-wrapper .layout-topbar-logo .app-name {
    font-size: 22.5px;
    color: #4c566a;
    margin-left: 10px;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo-wrapper .layout-topbar-logo img {
    height: 45px;
    width: 45px;
    border: 0 none;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu-button {
    display: none;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu {
    display: block;
    position: fixed;
    top: 75px;
    left: 0;
    width: 240px;
    height: calc(100% - 75px);
    transform: translate3d(-240px, 0px, 0px);
    -moz-transition: transform 0.15s;
    -o-transition: transform 0.15s;
    -webkit-transition: transform 0.15s;
    transition: transform 0.15s;
    margin-top: 0;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu::before {
    display: none;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li {
    margin-bottom: 10px;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > a {
    border: none;
    font-size: 14px;
    color: #4c566a;
    padding: 10px;
    width: 100%;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    cursor: pointer;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > a i {
    display: block;
    -moz-transition: all 0.15s;
    -o-transition: all 0.15s;
    -webkit-transition: all 0.15s;
    transition: all 0.15s;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > a:hover {
    background-color: #f9fafb;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul {
    max-height: 0;
    overflow: hidden;
    background-color: #f9fafb;
    margin-top: 0;
    -moz-transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);
    -webkit-transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: 400ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-border-radius-bottomleft: 6px;
    -webkit-border-bottom-left-radius: 6px;
    border-bottom-left-radius: 6px;
    -moz-border-radius-bottomright: 6px;
    -webkit-border-bottom-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li {
    padding: 10px;
    padding-left: 20px;
    margin: 0;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    flex-direction: row-reverse;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li i {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li > span h5 {
    font-size: 14px;
    font-weight: normal;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li > span > span {
    display: none;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li > ul > li:hover {
    background-color: #ffffff;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li.active-topmenuitem > a {
    background-color: #4394D8;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
    color: #ffffff;
    border-radius: 0;
    -moz-border-radius-topleft: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    -moz-border-radius-topright: 4px;
    -webkit-border-top-right-radius: 4px;
    border-top-right-radius: 4px;
    -webkit-box-shadow: 0 2px 4px 0 rgba(29, 29, 33, 0.24);
    -moz-box-shadow: 0 2px 4px 0 rgba(29, 29, 33, 0.24);
    box-shadow: 0 2px 4px 0 rgba(29, 29, 33, 0.24);
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li.active-topmenuitem > a i {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu > li.active-topmenuitem > ul {
    max-height: 450px;
    -webkit-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
    -moz-box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
    box-shadow: 3px 0 5px 0 rgba(33, 35, 39, 0.15);
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right {
    height: 150px;
    display: none;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions {
    padding: 0;
    height: 50%;
    border-bottom: 1px solid rgba(145, 153, 169, 0.3);
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a .topbar-icon {
    font-size: 26px;
    color: #4c566a;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a:hover i, .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a:focus i {
    color: #4394D8;
    -moz-transition: color 0.15s;
    -o-transition: color 0.15s;
    -webkit-transition: color 0.15s;
    transition: color 0.15s;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a.layout-rightpanel-button i {
    -moz-transition: transform 0.15s;
    -o-transition: transform 0.15s;
    -webkit-transition: transform 0.15s;
    transition: transform 0.15s;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li::after {
    display: none;
    width: 100%;
    height: 6px;
    background-color: #4394D8;
    content: " ";
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
    position: absolute;
    bottom: -1px;
    left: 0px;
    -webkit-animation: fadeInDown 0.5s;
    /* Safari 4.0 - 8.0 */
    animation: fadeInDown 0.5s;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul {
    position: fixed;
    top: 150px;
    left: 1em;
    right: 1em;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul::before {
    display: none;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li {
    padding: 18px;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.seach-item {
    padding: 10px;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.seach-item > ul {
    padding: 0;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.calendar {
    padding: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals {
    padding: 0;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul {
    padding: 0;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li.deals > ul > li {
    padding: 18px;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > a {
    display: block;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > .search-item-submenu {
    display: none;
    position: fixed;
    list-style-type: none;
    margin: 0;
    top: 150px;
    left: 1em;
    right: 1em;
    z-index: 1000;
    min-width: 250px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > .search-item-submenu span {
    width: 100%;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > .search-item-submenu span input {
    width: 100%;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item::after {
    left: 14px;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item.active-topmenuitem .search-item-submenu {
    display: block;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem::after {
    display: block;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions .profile-item {
    display: none;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    list-style-type: none;
    margin: 0;
    padding: 0 1em;
    height: 50%;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li {
    width: 100%;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > a {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > a .profile-image-wrapper img {
    width: 48px;
    height: 48px;
    border: 0 none;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > ul {
    display: none;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > ul .menu-text > p {
    margin: 3px;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > ul > li:not(.layout-submenu-footer):hover {
    background-color: #f9fafb;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li > ul > li:not(.layout-submenu-footer):hover i {
    color: #4c566a;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li.active-topmenuitem > ul {
    display: block;
    left: auto;
    right: 0;
    width: 100%;
    top: 62px;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-right .profile-mobile-wrapper > li.active-topmenuitem > ul::before {
    left: auto;
    right: 0;
    width: 100%;
  }

  .layout-wrapper.layout-topbar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button {
    background-color: #4c566a;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
  }

  .layout-wrapper.layout-topbar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button i {
    color: #ffffff;
  }

  .layout-wrapper.layout-topbar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .topbar-menu-mobile-button:hover {
    background-color: #4394D8;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
  }

  .layout-wrapper.layout-topbar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu {
    top: 225px;
    height: calc(100% - 225px);
  }

  .layout-wrapper.layout-topbar-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-right {
    display: block;
  }

  .layout-wrapper.layout-topbar-mobile-active .layout-rightpanel {
    top: 225px;
    height: calc(100% - 225px);
  }

  .layout-wrapper.layout-topbar-mobile-active .layout-sidebar {
    top: 225px;
  }

  .layout-wrapper.layout-topbar-mobile-active .layout-sidebar .layout-menu-container {
    height: calc(100% - 225px);
  }

  .layout-wrapper.layout-megamenu-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button {
    background-color: #4c566a;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
  }

  .layout-wrapper.layout-megamenu-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button i {
    color: #ffffff;
  }

  .layout-wrapper.layout-megamenu-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .megamenu-mobile-button:hover {
    background-color: #4394D8;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
  }

  .layout-wrapper.layout-megamenu-mobile-active .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-megamenu {
    transform: translate3d(0px, 0px, 0px);
  }

  .layout-wrapper.layout-megamenu-mobile-active .layout-main-mask {
    display: block;
  }
}
.layout-rightpanel {
  position: fixed;
  z-index: 999;
  right: 0;
  top: 75px;
  background-color: #ffffff;
  height: calc(100% - 75px);
  padding: 0;
  min-width: 285px;
  box-shadow: -3px 0 5px 0 rgba(33, 35, 39, 0.15);
  overflow: auto;
  transform: translate3d(400px, 0px, 0px);
  -moz-transition: transform 0.15s;
  -o-transition: transform 0.15s;
  -webkit-transition: transform 0.15s;
  transition: transform 0.15s;
}

.layout-rightpanel .right-panel-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #20252e;
  padding: 24px 18px;
}

.layout-rightpanel .right-panel-header .title span {
  font-size: 14px;
  padding: 2px 6px;
  color: #ffffff;
  border-radius: 4px;
  background-color: #4394D8;
}

.layout-rightpanel .right-panel-header .title h1 {
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  margin-top: 12px;
  font-weight: normal;
}

.layout-rightpanel .right-panel-header .rightpanel-exit-button:hover i {
  color: #9199a9;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.layout-rightpanel .right-panel-header .rightpanel-exit-button i {
  font-size: 20px;
  color: #eceff4;
}

.layout-rightpanel .right-panel-content {
  padding-bottom: 60px;
}

.layout-rightpanel .right-panel-content .right-panel-content-row {
  border-bottom: 1px solid rgba(145, 153, 169, 0.3);
  padding: 24px 0;
}

.layout-rightpanel .right-panel-content .right-panel-content-row:last-child {
  border-bottom: none;
}

.layout-rightpanel .right-panel-content .right-panel-content-row h1 {
  font-size: 18px;
  margin: 0;
  margin-bottom: 18px;
  color: #4c566a;
  font-weight: normal;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks {
  padding: 0;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
  margin-bottom: 18px;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-header .title h1 {
  margin-bottom: 0px;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-header .tasks-info span {
  font-size: 12px;
  color: #4c566a;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-header .tasks-info span.highlighted {
  color: #4c566a;
  font-weight: 500;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list {
  list-style: none;
  padding: 0;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item:nth-child(even) {
  background-color: #f9fafb;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item .checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item .checkbox p {
  margin: 0;
  color: #4c566a;
  margin-left: 10px;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item .tasks-day .time {
  border-radius: 2px;
  background-color: #88c0d0;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px;
  text-align: center;
  min-width: 55px;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .tasks .tasks-list .tasks-list-item .tasks-day .time.later {
  background-color: #9199a9;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .calendar {
  padding: 18px;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .calendar .p-datepicker-inline {
  background-color: #ffffff;
  border: none;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .calendar .p-datepicker-inline .p-datepicker-header {
  font-size: 13px;
  color: #4c566a;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .calendar .p-datepicker-inline .p-datepicker-header .p-datepicker-title span {
  color: #4c566a;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .calendar .p-datepicker-inline .p-datepicker-calendar th {
  color: #9199a9;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .calendar .p-datepicker-inline .p-datepicker-calendar td a {
  color: #4c566a;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather {
  padding: 0;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather h1 {
  padding-left: 18px;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list {
  list-style: none;
  padding: 0;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 18px;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item:nth-child(even) {
  background-color: #f9fafb;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .time-location span {
  font-size: 12px;
  color: #9199a9;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .time-location p {
  font-weight: 500;
  margin: 0;
  margin-top: 6px;
  color: #4c566a;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  width: 75px;
  justify-content: flex-start;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-icon {
  height: 36px;
  width: 36px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-icon.icon-1 {
  background-color: #ebcb8b;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-icon.icon-2 {
  background-color: #88c0d0;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-icon.icon-3 {
  background-color: #4394D8;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-icon.icon-4 {
  background-color: #d08770;
}

.layout-rightpanel .right-panel-content .right-panel-content-row .weather .weather-list .weather-list-item .weather-info .weather-value {
  font-size: 18px;
  color: #4c566a;
}

.layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-rightpanel-button i {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -moz-transition: transform 0.15s;
  -o-transition: transform 0.15s;
  -webkit-transition: transform 0.15s;
  transition: transform 0.15s;
}

.layout-wrapper.layout-rightpanel-active .layout-topbar .layout-topbar-wrapper .layout-rightpanel-button i {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -moz-transition: transform 0.15s;
  -o-transition: transform 0.15s;
  -webkit-transition: transform 0.15s;
  transition: transform 0.15s;
}

.layout-wrapper.layout-rightpanel-active .layout-rightpanel {
  transform: translate3d(0px, 0px, 0px);
}

@media (max-width: 991px) {
  .layout-rightpanel-active .layout-rightpanel {
    transform: translate3d(0px, 0px, 0px);
  }

  .layout-rightpanel-active .layout-main-mask {
    display: block;
  }
}
.layout-breadcrumb {
  background-color: #d8dee9;
  padding: 10px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layout-breadcrumb .breadcrumb .route-bar-breadcrumb {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
}

.layout-breadcrumb .breadcrumb .route-bar-breadcrumb li {
  padding: 0 5px;
  font-size: 13px;
  color: #9199a9;
}

.layout-breadcrumb .breadcrumb .route-bar-breadcrumb li:first-child {
  padding-left: 0;
}

.layout-breadcrumb .breadcrumb .route-bar-breadcrumb li i {
  font-weight: bold;
  font-size: 10px;
}

@media (max-width: 991px) {
  .layout-breadcrumb {
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
  }

  .layout-breadcrumb .breadcrumb {
    width: 100%;
    border-bottom: 1px solid rgba(145, 153, 169, 0.3);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    padding: 0 30px;
    padding-bottom: 10px;
  }

  .layout-breadcrumb .notification {
    padding: 0 30px;
    padding-top: 10px;
    text-align: center;
  }
}
.layout-content {
  flex: 1 0 auto;
  padding: 2em;
  background: #ffffff;
}

.layout-dashboard .list-effect {
  height: 80px;
  width: 100%;
  background-color: transparent;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(transparent));
  background-image: -webkit-linear-gradient(to top, #ffffff, transparent);
  background-image: -moz-linear-gradient(to top, #ffffff, transparent);
  background-image: -ms-linear-gradient(to top, #ffffff, transparent);
  background-image: -o-linear-gradient(to top, #ffffff, transparent);
  background-image: linear-gradient(to top, #ffffff, transparent);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="transparent");
  position: absolute;
  bottom: 0;
  left: 0;
}

.layout-dashboard .map {
  padding: 0;
}

.layout-dashboard .map .map-image {
  position: relative;
}

.layout-dashboard .map .map-image img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.layout-dashboard .map .map-info {
  padding: 12px;
}

.layout-dashboard .map .map-info .map-info-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

.layout-dashboard .map .map-info .map-info-header h6 {
  color: #4c566a;
  font-size: 14px;
  margin: 0;
}

.layout-dashboard .map .map-info .map-info-header .location-change {
  text-align: right;
  cursor: pointer;
}

.layout-dashboard .map .map-info .map-info-header .location-change span, .layout-dashboard .map .map-info .map-info-header .location-change i {
  color: #9199a9;
  font-size: 12px;
}

.layout-dashboard .map .map-info .map-info-header .location-change:hover span, .layout-dashboard .map .map-info .map-info-header .location-change:hover i {
  color: #4c566a;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.layout-dashboard .map .map-info .map-info-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}

.layout-dashboard .map .map-info .map-info-bar .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1em;
  cursor: pointer;
}

.layout-dashboard .map .map-info .map-info-bar .info:hover span {
  color: #9199a9;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.layout-dashboard .map .map-info .map-info-bar .info:last-child {
  border-right: none;
}

.layout-dashboard .map .map-info .map-info-bar .info .location-point {
  height: 18px;
  width: 18px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 5px;
}

.layout-dashboard .map .map-info .map-info-bar .info .location-point.point-1 {
  background-color: #bf616a;
}

.layout-dashboard .map .map-info .map-info-bar .info .location-point.point-2 {
  background-color: #b48ead;
}

.layout-dashboard .map .map-info .map-info-bar .info .location-point.point-3 {
  background-color: #d08770;
}

.layout-dashboard .map .map-info .map-info-bar .info a {
  text-decoration: underline;
  color: #4394D8;
}

.login-body .login-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-body .login-content .night-switch {
  position: absolute;
  right: 20px;
  top: 10px;
}

.login-body .login-content .night-switch .p-button {
  background-color: transparent;
  border-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 60px;
  height: 60px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
  outline: none;
  z-index: 101;
}

.login-body .login-content .night-switch .p-button .pi {
  width: 32px;
  height: 32px;
  font-size: 32px;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -16px;
  margin-left: -16px;
}

.login-body .login-content .night-switch .p-button .p-button-label {
  display: none;
}

.login-body .login-content .night-switch .p-button:enabled:hover {
  border: none;
  opacity: 0.7;
  -moz-transition: opacity 0.15s;
  -o-transition: opacity 0.15s;
  -webkit-transition: opacity 0.15s;
  transition: opacity 0.15s;
}

.login-body .login-content .login-panel {
  width: 40%;
  height: 100vh;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-body .login-content .login-panel .login-panel-content {
  z-index: 100;
  width: 80%;
  padding: 0 6%;
  text-align: center;
}

.login-body .login-content .login-panel .login-panel-content .logo {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-body .login-content .login-panel .login-panel-content .logo img {
  width: 80px;
}

.login-body .login-content .login-panel .login-panel-content h1 {
  font-size: 42px;
  font-weight: 100;
  letter-spacing: 1px;
  margin: 20px 0;
  color: #ffffff;
}

.login-body .login-content .login-panel .login-panel-content p {
  margin-top: 10px;
  font-size: 16px;
  color: #d8dee9;
}

.login-body .login-content .login-panel .login-panel-content .forms {
  margin-top: 30px;
  text-align: left;
}

.login-body .login-content .login-panel .login-panel-content .forms .login-input-wrapper {
  position: relative;
  margin-bottom: 24px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
}

.login-body .login-content .login-panel .login-panel-content .forms .login-input-wrapper input {
  width: 100%;
  padding-right: 16px;
  background-color: transparent;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px #c3ccdd;
  color: #d8dee9;
  margin-top: 6px;
}

.login-body .login-content .login-panel .login-panel-content .forms .login-input-wrapper i {
  position: absolute;
  color: #d8dee9;
  font-size: 16px;
  top: 50%;
  right: 0.429em;
  margin-top: 3px;
}

.login-body .login-content .login-panel .login-panel-content .forms .p-checkbox.p-component {
  margin-left: -6px;
}

.login-body .login-content .login-panel .login-panel-content .forms label {
  color: #c3ccdd;
}

.login-body .login-content .login-panel .login-panel-content .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.login-body .login-content .login-panel .login-panel-content .buttons .p-button {
  margin-top: 15px;
  background-color: #bd69ae;
  border: none;
  min-width: 100px;
}

.login-body .login-content .login-panel .login-panel-content .buttons a.secondary-button {
  background-color: transparent;
  border: none;
  color: #c3ccdd;
  padding: 2px 0;
  text-decoration: underline;
}

.login-body .login-content .desert {
  width: 100%;
  height: 30vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
}

.login-body.layout-light {
  background-color: #eceff4;
}

.login-body.layout-light .login-content .night-switch .p-button {
  color: #5a657c;
  border: none;
}

.login-body.layout-light .login-content .night-switch .p-button.light-switch {
  display: none;
}

.login-body.layout-light .login-content .night-switch .p-button.dark-switch {
  display: block;
}

.login-body.layout-light .login-content .login-panel {
  background-color: rgba(216, 222, 233, 0.3);
}

.login-body.layout-light .login-content .login-panel .login-panel-content h1 {
  color: #2e3440;
}

.login-body.layout-light .login-content .login-panel .login-panel-content p {
  color: #5a657c;
}

.login-body.layout-light .login-content .login-panel .login-panel-content .forms .login-input-wrapper input {
  border: solid 1px #cccccc;
  background-color: #ffffff;
  color: #9199a9;
}

.login-body.layout-light .login-content .login-panel .login-panel-content .forms .login-input-wrapper i {
  color: #9199a9;
}

.login-body.layout-light .login-content .login-panel .login-panel-content .forms label {
  color: #4c566a;
}

.login-body.layout-light .login-content .login-panel .login-panel-content .buttons button, .login-body.layout-light .login-content .login-panel .login-panel-content .buttons a {
  background-color: #4394D8;
}

.login-body.layout-light .login-content .login-panel .login-panel-content .buttons button.secondary-button, .login-body.layout-light .login-content .login-panel .login-panel-content .buttons a.secondary-button {
  background-color: transparent;
  color: #9199a9;
}

.login-body.layout-light .login-content .login-panel .login-panel-content .buttons button.secondary-button:enabled:hover, .login-body.layout-light .login-content .login-panel .login-panel-content .buttons a.secondary-button:enabled:hover {
  background-color: transparent;
  color: #636975;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.login-body.layout-light .login-content .login-panel .login-panel-content .buttons button:enabled:hover, .login-body.layout-light .login-content .login-panel .login-panel-content .buttons a:enabled:hover {
  background-color: #76a1d6;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.login-body.layout-light .login-content .desert {
  display: block;
}

.login-body.layout-dark {
  background-repeat: no-repeat;
  background-size: cover;
}

.login-body.layout-dark .login-content .night-switch .p-button {
  color: #ffffff;
}

.login-body.layout-dark .login-content .night-switch .p-button.dark-switch {
  display: none;
}

.login-body.layout-dark .login-content .night-switch .p-button.light-switch {
  display: block;
}

.login-body.layout-dark .login-content .login-panel {
  background-color: rgba(32, 37, 46, 0.3);
}

.login-body.layout-dark .login-content .login-panel .login-panel-content h1 {
  color: #ffffff;
}

.login-body.layout-dark .login-content .login-panel .login-panel-content p {
  color: #d8dee9;
}

.login-body.layout-dark .login-content .login-panel .login-panel-content .forms .login-input-wrapper input {
  border: solid 1px #c3ccdd;
  color: #d8dee9;
}

.login-body.layout-dark .login-content .login-panel .login-panel-content .forms .login-input-wrapper i {
  color: #d8dee9;
}

.login-body.layout-dark .login-content .login-panel .login-panel-content .forms label {
  color: #c3ccdd;
}

.login-body.layout-dark .login-content .login-panel .login-panel-content .buttons button, .login-body.layout-dark .login-content .login-panel .login-panel-content .buttons a {
  background-color: #bd69ae;
}

.login-body.layout-dark .login-content .login-panel .login-panel-content .buttons button.secondary-button, .login-body.layout-dark .login-content .login-panel .login-panel-content .buttons a.secondary-button {
  background-color: transparent;
  color: #c3ccdd;
}

.login-body.layout-dark .login-content .login-panel .login-panel-content .buttons button.secondary-button:enabled:hover, .login-body.layout-dark .login-content .login-panel .login-panel-content .buttons a.secondary-button:enabled:hover {
  background-color: transparent;
  color: #ffffff;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.login-body.layout-dark .login-content .login-panel .login-panel-content .buttons button:enabled:hover, .login-body.layout-dark .login-content .login-panel .login-panel-content .buttons a:enabled:hover {
  background-color: #e27dd0;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.login-body.layout-dark .login-content .desert {
  display: none;
}

@media (max-width: 740px) {
  .login-body .login-content .login-panel {
    width: 100%;
  }

  .login-body .login-content .login-panel .login-panel-content {
    width: 85%;
    padding: 20px 15px 50px;
  }

  .login-body .login-content .login-panel .login-panel-content h1 {
    font-size: 18px;
  }

  .login-body .login-content .login-panel .login-panel-content P {
    font-size: 16px;
  }

  .login-body .login-content .desert {
    background-position: right;
  }
}
.exception-body::after {
  content: " ";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.7;
}

.exception-body .exception-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}

.exception-body .exception-content .moon {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.exception-body .exception-content .exception-panel {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-top: none;
  width: 50%;
}

.exception-body .exception-content .exception-panel .exception-panel-content {
  z-index: 100;
  height: 50%;
  width: 50%;
}

.exception-body .exception-content .exception-panel .exception-panel-content h1 {
  font-size: 42px;
  font-weight: 100;
  letter-spacing: 1px;
  margin: 20px 0;
}

.exception-body .exception-content .exception-panel .exception-panel-content .seperator {
  width: 99px;
  height: 1px;
  background-color: #9199a9;
}

.exception-body .exception-content .exception-panel .exception-panel-content .tag {
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  padding: 3px 6px;
  margin-bottom: 20px;
}

.exception-body .exception-content .exception-panel .exception-panel-content p {
  margin-top: 25px;
  font-size: 16px;
}

.exception-body .exception-content .exception-panel .exception-panel-content button {
  margin-top: 30px;
  background-color: #4394D8;
  border: none;
}

.exception-body .exception-content .desert {
  width: 100%;
  height: 30vh;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
}

.exception-body.notfound {
  background-color: #20252e;
}

.exception-body.notfound::after {
  background-color: #444c5b -9%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#444c5b -9%), to(#20252e));
  background-image: -webkit-linear-gradient(to bottom, #444c5b -9%, #20252e);
  background-image: -moz-linear-gradient(to bottom, #444c5b -9%, #20252e);
  background-image: -ms-linear-gradient(to bottom, #444c5b -9%, #20252e);
  background-image: -o-linear-gradient(to bottom, #444c5b -9%, #20252e);
  background-image: linear-gradient(to bottom, #444c5b -9%, #20252e);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#444c5b -9%", endColorstr="#20252e");
}

.exception-body.notfound .exception-content .exception-panel {
  border-color: rgba(76, 86, 106, 0.3);
  background-color: rgba(0, 0, 0, 0.3);
}

.exception-body.notfound .exception-content .exception-panel .exception-panel-content h1 {
  color: #9199a9;
}

.exception-body.notfound .exception-content .exception-panel .exception-panel-content .tag {
  background-color: #eceff4;
}

.exception-body.notfound .exception-content .exception-panel .exception-panel-content p {
  color: #c3ccdd;
}

.exception-body.notfound .exception-content .desert {
  background-image: none;
}

.exception-body.error {
  background-color: #d07b70;
}

.exception-body.error::after {
  background-color: #5b4444 -9%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5b4444 -9%), to(#20262e));
  background-image: -webkit-linear-gradient(to bottom, #5b4444 -9%, #20262e);
  background-image: -moz-linear-gradient(to bottom, #5b4444 -9%, #20262e);
  background-image: -ms-linear-gradient(to bottom, #5b4444 -9%, #20262e);
  background-image: -o-linear-gradient(to bottom, #5b4444 -9%, #20262e);
  background-image: linear-gradient(to bottom, #5b4444 -9%, #20262e);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5b4444 -9%", endColorstr="#20262e");
}

.exception-body.error .exception-content .exception-panel {
  border-color: rgba(195, 204, 221, 0.3);
  background-color: rgba(255, 255, 255, 0.3);
}

.exception-body.error .exception-content .exception-panel .exception-panel-content h1 {
  color: #c3ccdd;
}

.exception-body.error .exception-content .exception-panel .exception-panel-content .tag {
  background-color: #bf616a;
  color: #ffffff;
}

.exception-body.error .exception-content .exception-panel .exception-panel-content p {
  color: #c3ccdd;
}

.exception-body.error .exception-content .desert {
  background-image: none;
}

.exception-body.access {
  background-color: #d08770;
}

.exception-body.access::after {
  background-color: #5b5544 -9%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#5b5544 -9%), to(#20262e));
  background-image: -webkit-linear-gradient(to bottom, #5b5544 -9%, #20262e);
  background-image: -moz-linear-gradient(to bottom, #5b5544 -9%, #20262e);
  background-image: -ms-linear-gradient(to bottom, #5b5544 -9%, #20262e);
  background-image: -o-linear-gradient(to bottom, #5b5544 -9%, #20262e);
  background-image: linear-gradient(to bottom, #5b5544 -9%, #20262e);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5b5544 -9%", endColorstr="#20262e");
}

.exception-body.access .exception-content .exception-panel {
  border-color: rgba(76, 86, 106, 0.3);
  background-color: rgba(255, 255, 255, 0.3);
}

.exception-body.access .exception-content .exception-panel .exception-panel-content h1 {
  color: #c3ccdd;
}

.exception-body.access .exception-content .exception-panel .exception-panel-content .tag {
  background-color: #ebcb8b;
}

.exception-body.access .exception-content .exception-panel .exception-panel-content p {
  color: #c3ccdd;
}

.exception-body.access .exception-content .desert {
  background-image: none;
}

@media (max-width: 740px) {
  .exception-body .exception-content .exception-panel {
    width: 100%;
  }

  .exception-body .exception-content .exception-panel .exception-panel-content {
    width: 90%;
  }

  .exception-body .exception-content .desert {
    background-position: right;
    height: 50vh;
  }
}
.landing-body {
  display: flex;
  flex-flow: column;
  background-color: #ffffff;
}

.landing-body h1 {
  font-size: 42px;
  letter-spacing: 1px;
  color: #20252e;
  margin: 0;
  font-weight: 100;
}

.landing-body .landing-nav-menu {
  width: 100%;
  height: 72px;
  background-color: #eceff4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  padding: 0 10%;
}

.landing-body .landing-nav-menu .logo {
  width: 155px;
}

.landing-body .landing-nav-menu .logo a {
  display: flex;
  align-items: center;
}

.landing-body .landing-nav-menu .logo .app-name {
  font-size: 22.5px;
  color: #2e3440;
  margin-left: 10px;
}

.landing-body .landing-nav-menu .logo img {
  width: 50px;
}

.landing-body .landing-nav-menu .nav-menu-wrapper ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  list-style: none;
  padding: 0;
}

.landing-body .landing-nav-menu .nav-menu-wrapper ul li {
  padding: 15px;
}

.landing-body .landing-nav-menu .nav-menu-wrapper ul li a {
  color: #5a657c;
  font-size: 16px;
  font-weight: 500;
}

.landing-body .landing-nav-menu .nav-menu-wrapper ul li a:hover {
  color: #8292b3;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.landing-body .landing-nav-menu .buy-button {
  width: 155px;
}

.landing-body .landing-nav-menu .buy-button button {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #d08770;
  padding: 6px 12px;
  border: none;
  padding: 15px 20px;
}

.landing-body .landing-nav-menu .buy-button button:hover {
  background-color: #ea987c;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.landing-body .landing-nav-menu .buy-button button a {
  color: #ffffff;
  font-size: 14px;
}

.landing-body .landing-nav-menu .menu-button {
  display: none;
}

.landing-body .landing-nav-menu .menu-button .landing-menu-button {
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: #2e3440;
  font-size: 22px;
}

.landing-body .landing-nav-menu .menu-button .landing-menu-button .landing-menu-button-icon {
  background-repeat: no-repeat;
  background-size: 18px 23px;
  width: 18px;
  height: 24px;
  text-align: center;
}

.landing-body .landing-header-wrapper {
  height: 600px;
  position: relative;
  background-color: #20252e;
}

.landing-body .landing-header-wrapper .sun {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: flex-start;
  position: absolute;
  top: 0px;
  z-index: 999;
  width: 100%;
}

.landing-body .landing-header-wrapper .sun img {
  height: 100%;
}

.landing-body .landing-header-wrapper .landing-header-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  top: 0;
  position: absolute;
  width: 100%;
  z-index: 999;
}

.landing-body .landing-header-wrapper .landing-header-content h1 {
  color: #c3ccdd;
}

.landing-body .landing-header-wrapper .landing-header-content p {
  font-size: 24px;
  color: #c3ccdd;
  margin: 1.5rem 0 1.5rem 0;
}

.landing-body .landing-header-wrapper .landing-header-content .video-button button {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #d08770;
  padding: 15px 20px;
  color: #ffffff;
  font-size: 14px;
  border: none;
  cursor: pointer;
}

.landing-body .landing-header-wrapper .landing-header-content .video-button button:hover {
  background-color: #ea987c;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.landing-body .landing-header-wrapper .tree {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100%;
}

.landing-body .section .section-title {
  text-align: center;
}

.landing-body .section .section-title p {
  font-size: 16px;
  color: #4c566a;
  margin: 0;
  margin-top: 8px;
}

.landing-body .section#meet-mirage {
  background-color: #eceff4;
  padding: 24px 10% 170px;
  position: relative;
}

.landing-body .section#meet-mirage .feature-cards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 65px;
  flex-flow: wrap;
}

.landing-body .section#meet-mirage .feature-cards .feature-card {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #ffffff;
  text-align: center;
  margin-bottom: 32px;
}

.landing-body .section#meet-mirage .feature-cards .feature-card .feature-card-img {
  margin-bottom: 16px;
}

.landing-body .section#meet-mirage .feature-cards .feature-card .feature-card-img img {
  width: 250px;
  height: auto;
}

.landing-body .section#meet-mirage .feature-cards .feature-card .feature-card-title {
  color: #20252e;
  font-size: 16px;
  font-weight: 500;
}

.landing-body .section#meet-mirage .feature-cards .feature-card .feature-card-content {
  color: #5a657c;
  padding: 12px 18px 25px;
  width: 300px;
}

.landing-body .section#meet-mirage .buy-mirage-promo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -50%;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 998;
}

.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #2e3440;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 40px;
  flex-flow: wrap;
}

.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-content {
  width: 70%;
}

.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-content h1 {
  color: #c3ccdd;
}

.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-content p {
  color: #ffffff;
  font-size: 16px;
}

.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-button button {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #d08770;
  padding: 15px 20px;
  color: #ffffff;
  font-size: 14px;
  border: none;
  cursor: pointer;
}

.landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-button button:hover {
  background-color: #ea987c;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.landing-body .section#features {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 140px 10% 60px;
  position: relative;
  flex-flow: wrap;
}

.landing-body .section#features .features-img {
  z-index: 998;
  margin-bottom: 24px;
}

.landing-body .section#features .features-img img {
  height: 400px;
  width: auto;
}

.landing-body .section#features .features-rows {
  display: flex;
  flex-flow: column;
  z-index: 998;
}

.landing-body .section#features .features-rows .features-row {
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 998;
  padding: 24px;
}

.landing-body .section#features .features-rows .features-row .features-row-icon {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.landing-body .section#features .features-rows .features-row .features-row-icon .feature {
  width: 32px;
  height: 32px;
  text-align: center;
}

.landing-body .section#features .features-rows .features-row .features-row-icon .feature.first {
  background-repeat: no-repeat;
  background-size: 32px 32px;
}

.landing-body .section#features .features-rows .features-row .features-row-icon .feature.second {
  background-repeat: no-repeat;
  background-size: 32px 32px;
}

.landing-body .section#features .features-rows .features-row .features-row-icon .feature.third {
  background-repeat: no-repeat;
  background-size: 32px 32px;
}

.landing-body .section#features .features-rows .features-row .features-row-content {
  margin-left: 24px;
  width: 80%;
}

.landing-body .section#features .features-rows .features-row .features-row-content h3 {
  color: #20252e;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.landing-body .section#features .features-rows .features-row .features-row-content span {
  color: #5a657c;
  font-size: 14px;
}

.landing-body .section#features .features-rows .features-row:hover {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 1px 30px 3px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
}

.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature {
  width: 70px;
  height: 70px;
}

.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.first {
  background-repeat: no-repeat;
  background-size: 70px 70px;
}

.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.second {
  background-repeat: no-repeat;
  background-size: 70px 70px;
}

.landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.third {
  background-repeat: no-repeat;
  background-size: 70px 70px;
}

.landing-body .section#features .effect-feature {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  max-height: 350px;
  z-index: 997;
}

.landing-body .section#pricing {
  background-color: #eceff4;
  padding: 36px 10% 60px;
}

.landing-body .section#pricing .pricing-cards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 35px;
  flex-flow: wrap;
}

.landing-body .section#pricing .pricing-cards .pricing-card {
  position: relative;
  display: flex;
  flex-flow: column;
  -moz-border-radius: 4.4px;
  -webkit-border-radius: 4.4px;
  border-radius: 4.4px;
  box-shadow: 0 1px 30px 3px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  padding: 40px 60px 23px;
  text-align: center;
  min-height: 400px;
  margin-bottom: 36px;
}

.landing-body .section#pricing .pricing-cards .pricing-card .pricing-card-title h2 {
  color: #2e3440;
  font-size: 20px;
  margin: 0;
}

.landing-body .section#pricing .pricing-cards .pricing-card .pricing-card-title p {
  color: #5a657c;
  font-size: 18px;
  margin: 0px;
}

.landing-body .section#pricing .pricing-cards .pricing-card .pricing-card-price {
  color: #4394D8;
  font-weight: bold;
  font-size: 32px;
  padding: 24px 0;
}

.landing-body .section#pricing .pricing-cards .pricing-card .pricing-license-rows .pricing-license-row {
  color: #5a657c;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 0;
}

.landing-body .section#pricing .pricing-cards .pricing-card .pricing-license-rows .pricing-license-row.disabled {
  color: #c3ccdd;
}

.landing-body .section#pricing .pricing-cards .pricing-card .pricing-license-button {
  padding-top: 24px;
}

.landing-body .section#pricing .pricing-cards .pricing-card .pricing-license-button button {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #88c0d0;
  padding: 15px 0;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  border: none;
  width: 160px;
  cursor: pointer;
}

.landing-body .section#pricing .pricing-cards .pricing-card .pricing-license-button button:hover {
  background-color: #97d9ec;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.landing-body .section#pricing .pricing-cards .pricing-card::before {
  display: none;
  width: 100%;
  height: 13px;
  background-color: #4394D8;
  content: " ";
  -moz-border-radius-topleft: 4px;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -moz-border-radius-topright: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.landing-body .section#pricing .pricing-cards .pricing-card.premium {
  padding: 53px 75px 25px;
  box-shadow: 0 1px 33px 3px rgba(0, 0, 0, 0.07);
}

.landing-body .section#pricing .pricing-cards .pricing-card.premium:before {
  display: block;
}

.landing-body .section#multimedia {
  padding: 35px 10% 60px;
  position: relative;
}

.landing-body .section#multimedia .multimedia-video-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 35px;
  z-index: 998;
  position: relative;
}

.landing-body .section#multimedia .multimedia-video-wrapper iframe {
  width: 570px;
  height: 300px;
}

.landing-body .section .effect img {
  position: absolute;
  bottom: 0px;
  width: 100%;
  left: 0;
  max-height: 350px;
  z-index: 997;
}

.landing-body .footer {
  background-color: #20252e;
  padding: 10px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
}

.landing-body .footer .logo-text {
  display: flex;
  align-items: center;
  height: 100%;
}

.landing-body .footer .logo-text img {
  height: 50px;
  width: 50px;
}

.landing-body .footer .logo-text .text {
  margin-left: 15px;
}

.landing-body .footer .logo-text .text h1 {
  font-size: 18px;
  color: #c3ccdd;
  font-weight: normal;
  margin: 0;
}

.landing-body .footer .logo-text .text span {
  color: #9199a9;
  font-size: 12px;
}

.landing-body .footer .icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  color: #9199a9;
  height: 100%;
}

.landing-body .footer .icons .icon:first-child img {
  margin-left: 0;
}

.landing-body .footer .icons .icon img {
  margin-left: 20px;
  cursor: pointer;
}

.landing-body .landing-mask {
  display: none;
  background-color: #2e3440;
  position: fixed;
  z-index: 1000;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0.64;
  animation-name: fadeinmask;
  animation-duration: 0.15s;
  opacity: 0.8;
  filter: alpha(opacity=80);
  cursor: pointer;
}

@media (max-width: 991px) {
  .landing-body .landing-nav-menu {
    z-index: 10001;
  }

  .landing-body .landing-nav-menu .nav-menu-wrapper {
    position: absolute;
    width: 100%;
    top: 50px;
    left: 0;
  }

  .landing-body .landing-nav-menu .nav-menu-wrapper ul {
    flex-flow: column;
    background-color: #f9fafb;
    max-height: 0;
    overflow: auto;
    -moz-transition: max-height 0.4s;
    -o-transition: max-height 0.4s;
    -webkit-transition: max-height 0.4s;
    transition: max-height 0.4s;
  }

  .landing-body .landing-nav-menu .nav-menu-wrapper ul li {
    width: 100%;
    text-align: center;
  }

  .landing-body .landing-nav-menu .nav-menu-wrapper ul li:hover {
    background-color: #d8dee9;
    -moz-transition: background-color 0.3s;
    -o-transition: background-color 0.3s;
    -webkit-transition: background-color 0.3s;
    transition: background-color 0.3s;
  }

  .landing-body .landing-nav-menu .nav-menu-wrapper ul li.active-link {
    background-color: #26282e;
  }

  .landing-body .landing-nav-menu .nav-menu-wrapper ul li.active-link a {
    color: #ffffff;
  }

  .landing-body .landing-nav-menu .buy-button {
    display: none;
  }

  .landing-body .landing-nav-menu .menu-button {
    display: block;
  }

  .landing-body .landing-nav-menu .menu-button .landing-menu-button {
    display: flex;
  }

  .landing-body .landing-nav-menu .menu-button .landing-menu-button:hover {
    background-color: #6c7a96;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
  }

  .landing-body .landing-nav-menu .landing-menu-button.landing-menu-active {
    background-color: #2e3440;
    -moz-transition: background-color 0.15s;
    -o-transition: background-color 0.15s;
    -webkit-transition: background-color 0.15s;
    transition: background-color 0.15s;
    color: #ffffff;
  }

  .landing-body .landing-nav-menu .landing-menu-button.landing-menu-active .landing-menu-button-icon {
    background-repeat: no-repeat;
    background-size: 18px 23px;
  }

  .landing-body .landing-nav-menu .nav-menu-wrapper ul.landing-menu-active {
    max-height: 300px;
    overflow: hidden;
  }

  .landing-body .landing-mask.landing-menu-active {
    display: block;
  }

  .landing-body .landing-header-wrapper .landing-header-content {
    text-align: center;
  }

  .landing-body .landing-header-wrapper .sun {
    display: none;
  }

  .landing-body .section#meet-mirage {
    padding: 24px 10% 260px;
  }

  .landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-content {
    width: 100%;
  }

  .landing-body .section#meet-mirage .buy-mirage-promo-wrapper .buy-mirage-promo .promo-button {
    width: 100%;
  }

  .landing-body .section#features {
    padding: 260px 10% 60px;
  }

  .landing-body .section#features .features-img img {
    height: 300px;
  }

  .landing-body .section#features .features-rows .features-row {
    padding: 17px 12px;
  }

  .landing-body .section#features .features-rows .features-row .features-row-icon {
    width: 46px;
    height: 46px;
  }

  .landing-body .section#features .features-rows .features-row .features-row-icon .feature {
    background-repeat: no-repeat;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
  }

  .landing-body .section#features .features-rows .features-row .features-row-icon .feature.first {
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .landing-body .section#features .features-rows .features-row .features-row-icon .feature.second {
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .landing-body .section#features .features-rows .features-row .features-row-icon .feature.third {
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }

  .landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature {
    width: 46px;
    height: 46px;
  }

  .landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.first {
    background-repeat: no-repeat;
    background-size: 46px 46px;
  }

  .landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.second {
    background-repeat: no-repeat;
    background-size: 46px 46px;
  }

  .landing-body .section#features .features-rows .features-row:hover .features-row-icon .feature.third {
    background-repeat: no-repeat;
    background-size: 46px 46px;
  }

  .landing-body .section#features .features-rows .features-row .features-row-content {
    margin-left: 12px;
  }

  .landing-body .footer {
    height: 100%;
    flex-flow: column;
  }

  .landing-body .footer .logo-text {
    flex-flow: column;
    text-align: center;
    margin-bottom: 10px;
  }

  .landing-body .footer .logo-text .text {
    margin-top: 10px;
    margin-left: 0;
  }
}
.layout-config {
  position: fixed;
  padding: 0;
  top: 75px;
  display: block;
  right: 0;
  width: 20em;
  z-index: 998;
  height: calc(100% - 75px);
  transform: translate3d(20em, 0px, 0px);
  transition: transform 0.15s;
  background-color: #ffffff;
  backface-visibility: hidden;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
}

.layout-config.layout-config-active {
  transform: translate3d(0px, 0px, 0px);
}

.layout-config.layout-config-active .layout-config-button {
  opacity: 0;
  visibility: hidden;
}

.layout-config .layout-config-button {
  display: block;
  position: absolute;
  width: 52px;
  height: 52px;
  line-height: 52px;
  background-color: #fafafa;
  text-align: center;
  top: 230px;
  left: -51px;
  z-index: -1;
  overflow: hidden;
  cursor: pointer;
  opacity: 1;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transition: background-color 0.15s, opacity 0.15s;
  transition-delay: 0.2s;
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
}

.layout-config .layout-config-button i {
  font-size: 32px;
  font-weight: bold;
  line-height: inherit;
  cursor: pointer;
  transition: transform 1s;
}

.layout-config .layout-config-close {
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  right: 10px;
  top: 10px;
  z-index: 999;
  background-color: #e0284f;
  border-radius: 50%;
  transition: background-color 0.15s;
}

.layout-config .layout-config-close i {
  color: #ffffff;
  line-height: inherit;
  font-size: 12px;
}

.layout-config .layout-config-close:hover {
  background-color: #d44d69;
}

.layout-config .layout-config-content {
  position: relative;
  height: 100%;
  padding: 1em;
  overflow: auto;
}

.layout-config .layout-config-content .layout-themes {
  display: flex;
  flex-wrap: wrap;
}

.layout-config .layout-config-content .layout-themes > div {
  padding: 0.25rem;
}

.layout-config .layout-config-content .layout-themes a {
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  display: block;
  position: relative;
  align-items: center;
  justify-content: center;
  transition: transform 0.15s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.layout-config .layout-config-content .layout-themes a i {
  font-size: 1rem;
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 50%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.layout-config .layout-config-content .layout-themes a:hover {
  transform: scale(1.1);
}

.layout-config .p-col {
  text-align: center;
}

.layout-config p {
  line-height: 1.5;
  margin-top: 0;
  color: #9199a9;
}

.blocked-scroll-config {
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .layout-config {
    transform: translate3d(20em, 0px, 0px);
  }

  .layout-config.layout-config-active {
    transform: translate3d(0px, 0px, 0px);
  }

  .layout-config .layout-config-close {
    right: 29px;
  }
}
.widget-overview-box {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  width: 100%;
  min-height: 90px;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-overview-box .overview-box-icon img {
  height: 50px;
  width: 50px;
}

.widget-overview-box .overview-box-text h5 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.widget-overview-box .overview-box-text span {
  color: #dcf2f9;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}

.widget-overview-box .overview-box-status {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  width: 43px;
  height: 22px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-overview-box.overview-box-1 {
  background: -moz-linear-gradient(256deg, #65afc4 0%, #88c0d0 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #65afc4), color-stop(100%, #88c0d0));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(256deg, #65afc4 0%, #88c0d0 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(256deg, #65afc4 0%, #88c0d0 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(256deg, #65afc4 0%, #88c0d0 100%);
  /* ie10+ */
  background: linear-gradient(256deg, #65afc4 0%, #88c0d0 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#65afc4", endColorstr="#88c0d0",GradientType=1 );
  /* ie6-9 */
}

.widget-overview-box.overview-box-1 .overview-box-text span {
  color: #dcf2f9;
}

.widget-overview-box.overview-box-1 .overview-box-status {
  background-color: #d08770;
}

.widget-overview-box.overview-box-2 {
  background: -moz-linear-gradient(256deg, #f2c260 0%, #ebcb8b 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #f2c260), color-stop(100%, #ebcb8b));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(256deg, #f2c260 0%, #ebcb8b 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(256deg, #f2c260 0%, #ebcb8b 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(256deg, #f2c260 0%, #ebcb8b 100%);
  /* ie10+ */
  background: linear-gradient(256deg, #f2c260 0%, #ebcb8b 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f2c260", endColorstr="#ebcb8b",GradientType=1 );
  /* ie6-9 */
}

.widget-overview-box.overview-box-2 .overview-box-text span {
  color: #fcf5e8;
}

.widget-overview-box.overview-box-2 .overview-box-status {
  background-color: #b48ead;
}

.widget-overview-box.overview-box-3 {
  background: -moz-linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #8fb56f), color-stop(100%, #a3be8c));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
  /* ie10+ */
  background: linear-gradient(256deg, #8fb56f 0%, #a3be8c 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#8fb56f", endColorstr="#a3be8c",GradientType=1 );
  /* ie6-9 */
}

.widget-overview-box.overview-box-3 .overview-box-text span {
  color: #f0f4ec;
}

.widget-overview-box.overview-box-3 .overview-box-status {
  background-color: #ebcb8b;
  color: #3b4252;
}

.widget-overview-box.overview-box-4 {
  background: -moz-linear-gradient(256deg, #a4719b 0%, #b48ead 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #a4719b), color-stop(100%, #b48ead));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(256deg, #a4719b 0%, #b48ead 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(256deg, #a4719b 0%, #b48ead 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(256deg, #a4719b 0%, #b48ead 100%);
  /* ie10+ */
  background: linear-gradient(256deg, #a4719b 0%, #b48ead 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#a4719b", endColorstr="#b48ead",GradientType=1 );
  /* ie6-9 */
}

.widget-overview-box.overview-box-4 .overview-box-text span {
  color: #f3ecf2;
}

.widget-overview-box.overview-box-4 .overview-box-status {
  background-color: #a3be8c;
  color: #3b4252;
}

.widget-statistics {
  overflow: auto;
}

.widget-statistics #dashboard-chart canvas {
  background-color: #ffffff 4%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#ffffff 4%), to(#71B5C8 214%));
  background-image: -webkit-linear-gradient(to bottom, #ffffff 4%, #71B5C8 214%);
  background-image: -moz-linear-gradient(to bottom, #ffffff 4%, #71B5C8 214%);
  background-image: -ms-linear-gradient(to bottom, #ffffff 4%, #71B5C8 214%);
  background-image: -o-linear-gradient(to bottom, #ffffff 4%, #71B5C8 214%);
  background-image: linear-gradient(to bottom, #ffffff 4%, #71B5C8 214%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff 4%", endColorstr="#71B5C8 214%");
  background-color: #ffffff;
}

.widget-statistics .dropdown {
  text-align: right;
  padding: 0;
}

.widget-statistics .dropdown .p-dropdown {
  border: none;
  min-width: auto;
}

.widget-statistics .dropdown .p-dropdown .p-dropdown-label {
  text-align: right;
}

.widget-news {
  overflow: hidden;
  position: relative;
  margin: 1em 0;
  padding: 0;
  border-radius: 4px;
}

.widget-news .news-row {
  padding-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  transition: transform 500ms ease 0s;
}

.widget-news .news-row .news {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 35px;
  z-index: -1;
  flex: 1 0 100%;
  -moz-transition: visibility 0.8s;
  -o-transition: visibility 0.8s;
  -webkit-transition: visibility 0.8s;
  transition: visibility 0.8s;
  -moz-transition: transform 500ms ease 0s;
  -o-transition: transform 500ms ease 0s;
  -webkit-transition: transform 500ms ease 0s;
  transition: transform 500ms ease 0s;
  overflow: hidden;
  margin: 0;
}

.widget-news .news-row .news.news-1 {
  background-image: none;
}

.widget-news .news-row .news.news-1 .news-title {
  background: -moz-linear-gradient(246deg, #f2c260 0%, #ebcb8b 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #f2c260), color-stop(100%, #ebcb8b));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(246deg, #f2c260 0%, #ebcb8b 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(246deg, #f2c260 0%, #ebcb8b 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(246deg, #f2c260 0%, #ebcb8b 100%);
  /* ie10+ */
  background: linear-gradient(246deg, #f2c260 0%, #ebcb8b 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f2c260", endColorstr="#ebcb8b",GradientType=1 );
  /* ie6-9 */
}

.widget-news .news-row .news.news-1 .link a {
  color: #ebcb8b;
}

.widget-news .news-row .news.news-2 {
  background-image: none;
}

.widget-news .news-row .news.news-2 .news-title {
  background: -moz-linear-gradient(246deg, #8fb56f 0%, #a3be8c 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #8fb56f), color-stop(100%, #a3be8c));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(246deg, #8fb56f 0%, #a3be8c 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(246deg, #8fb56f 0%, #a3be8c 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(246deg, #8fb56f 0%, #a3be8c 100%);
  /* ie10+ */
  background: linear-gradient(246deg, #8fb56f 0%, #a3be8c 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#8fb56f", endColorstr="#a3be8c",GradientType=1 );
  /* ie6-9 */
}

.widget-news .news-row .news.news-2 .link a {
  color: #a3be8c;
}

.widget-news .news-row .news.news-3 {
  background-image: none;
}

.widget-news .news-row .news.news-3 .news-title {
  background-color: #45b0d5;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#45b0d5), to(#59c7ed 99%));
  background-image: -webkit-linear-gradient(to bottom, #45b0d5, #59c7ed 99%);
  background-image: -moz-linear-gradient(to bottom, #45b0d5, #59c7ed 99%);
  background-image: -ms-linear-gradient(to bottom, #45b0d5, #59c7ed 99%);
  background-image: -o-linear-gradient(to bottom, #45b0d5, #59c7ed 99%);
  background-image: linear-gradient(to bottom, #45b0d5, #59c7ed 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#45b0d5", endColorstr="#59c7ed 99%");
}

.widget-news .news-row .news.news-3 .link a {
  color: #88c0d0;
}

.widget-news .news-row .news .news-title {
  width: 55px;
  height: 25px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-news .news-row .news .news-title h5 {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #434c5e;
  margin: 0;
}

.widget-news .news-row .news h6 {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.widget-news .news-row .news p {
  color: #ffffff;
  opacity: 0.6;
  margin-bottom: 20px;
}

.widget-news .news-row .news .link {
  position: absolute;
  width: 95%;
  bottom: 20px;
}

.widget-news .news-row .news .link a {
  cursor: pointer;
}

.widget-news .news-row .news .link a i {
  margin-left: 5px;
}

.widget-news .link-points {
  position: absolute;
  width: 95%;
  bottom: 20px;
}

.widget-news .link-points .points {
  justify-content: flex-end;
  padding-top: 10px;
}

.widget-news .link-points .points .point {
  width: 10px;
  height: 10px;
  background-color: #eceff4;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 3px;
  cursor: pointer;
}

.widget-news .link-points .points .point.active-point.point-1 {
  background-color: #ebcb8b;
}

.widget-news .link-points .points .point.active-point.point-2 {
  background-color: #a3be8c;
}

.widget-news .link-points .points .point.active-point.point-3 {
  background-color: #88c0d0;
}

.widget-timeline .timeline-row {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: solid 1px #e5e9f0;
  background-color: #f9fafb;
  padding: 15px;
  width: 100%;
  margin: 12px 0;
}

.widget-timeline .timeline-row > .p-grid > .p-col-1 {
  width: 40px;
}

.widget-timeline .timeline-row > .p-grid > .p-col-11 {
  width: calc(100% - 40px);
  padding-left: 1em;
}

.widget-timeline .timeline-row .timeline-icon {
  width: 36px;
  height: 36px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 10px;
}

.widget-timeline .timeline-row .timeline-icon.camera-icon {
  background: -moz-linear-gradient(225deg, #65afc4 0%, #88c0d0 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #65afc4), color-stop(100%, #88c0d0));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(225deg, #65afc4 0%, #88c0d0 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(225deg, #65afc4 0%, #88c0d0 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(225deg, #65afc4 0%, #88c0d0 100%);
  /* ie10+ */
  background: linear-gradient(225deg, #65afc4 0%, #88c0d0 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#65afc4", endColorstr="#88c0d0",GradientType=1 );
  /* ie6-9 */
}

.widget-timeline .timeline-row .timeline-icon.comment-icon {
  background: -moz-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #f2c260), color-stop(100%, #ebcb8b));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* ie10+ */
  background: linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f2c260", endColorstr="#ebcb8b",GradientType=1 );
  /* ie6-9 */
}

.widget-timeline .timeline-row .timeline-icon.chart-icon {
  background: -moz-linear-gradient(225deg, #a4719b 0%, #b48ead 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #a4719b), color-stop(100%, #b48ead));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(225deg, #a4719b 0%, #b48ead 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(225deg, #a4719b 0%, #b48ead 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(225deg, #a4719b 0%, #b48ead 100%);
  /* ie10+ */
  background: linear-gradient(225deg, #a4719b 0%, #b48ead 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#a4719b", endColorstr="#b48ead",GradientType=1 );
  /* ie6-9 */
}

.widget-timeline .timeline-row h6 {
  margin-top: 0;
  margin-bottom: 6px;
}

.widget-timeline .timeline-row .timeline-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  color: #9199a9;
}

.widget-timeline .timeline-row .timeline-images {
  margin-top: 15px;
  position: relative;
}

.widget-timeline .timeline-row .timeline-images img {
  width: 100%;
}

.widget-timeline .timeline-row .timeline-images .new {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #434c5e;
  color: #d8dee9;
  font-size: 12px;
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 3px 6px;
  text-align: center;
}

.widget-tasks {
  padding: 0;
  padding-top: 18px;
}

.widget-tasks .card-header {
  padding: 0 18px;
}

.widget-tasks .task-info {
  text-align: right;
}

.widget-tasks .task-info span {
  font-size: 12px;
  color: #9199a9;
}

.widget-tasks .task-info span.highlighted {
  color: #4c566a;
  font-weight: 500;
}

.widget-tasks .checkboxes {
  list-style: none;
  padding: 0;
  margin: 0;
  color: #4c566a;
}

.widget-tasks .checkboxes .checkbox-row {
  padding: 6px 18px;
}

.widget-tasks .checkboxes .checkbox-row:nth-child(odd) {
  background-color: #f9fafb;
}

.widget-tasks .checkboxes .checkbox-row .checkbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.widget-tasks .checkboxes .checkbox-row .checkbox p {
  margin-left: 12px;
}

.widget-tasks .checkboxes .checkbox-row .checkbox-row-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.widget-tasks .checkboxes .checkbox-row .checkbox-row-info .time {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-color: #88c0d0;
  color: #ffffff;
  font-size: 12px;
  padding: 3px 10px;
  text-align: center;
  min-width: 55px;
  margin-right: 4px;
}

.widget-tasks .checkboxes .checkbox-row .checkbox-row-info .time.later {
  background-color: #9199a9;
}

.widget-tasks .checkboxes .checkbox-row .checkbox-row-info i {
  color: #9199a9;
  margin-left: 12px;
  cursor: pointer;
}

.widget-tasks .checkboxes .checkbox-row .checkbox-row-info i:hover {
  color: #88c0d0;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.widget-support-request {
  position: relative;
}

.widget-support-request .support-info {
  text-align: right;
}

.widget-support-request .support-info p {
  font-size: 12px;
  color: #9199a9;
  margin: 0;
}

.widget-support-request .support-info .highlited {
  color: #4c566a;
  font-weight: 500;
}

.widget-support-request label {
  display: block;
  margin-bottom: 8px;
}

.widget-support-request .p-inputtext {
  margin-bottom: 16px;
  width: 100%;
  color: #9199a9;
}

.widget-support-request .p-inputtextarea {
  width: 100%;
  margin-bottom: 16px;
  color: #9199a9;
}

.widget-support-request .resolution-button-bar {
  text-align: left;
}

.widget-support-request .resolution-button-bar .p-button {
  width: auto;
  margin-right: 15px;
  min-width: 125px;
  margin-bottom: 15px;
}

.widget-support-request .resolution-button-bar .p-button.secondary-button {
  padding: 0;
  background-color: transparent;
  color: #9199a9;
}

.widget-team {
  padding: 0;
  padding-top: 18px;
  position: relative;
}

.widget-team h5 {
  padding-left: 18px;
}

.widget-team ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.widget-team ul li {
  padding: 12px 18px;
  border-top: 1px solid rgba(145, 153, 169, 0.3);
}

.widget-team ul li:last-child {
  border-bottom: 1px solid rgba(145, 153, 169, 0.3);
}

.widget-team ul li:nth-child(odd) {
  background-color: #f9fafb;
}

.widget-team ul li .member {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.widget-team ul li .member .team-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.widget-team ul li .member .team-box .profile-image {
  margin-right: 10px;
}

.widget-team ul li .member .team-box .profile-image img {
  width: 40px;
  height: 40px;
}

.widget-team ul li .member .team-box h6 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #4c566a;
}

.widget-team ul li .member .team-box span {
  font-size: 12px;
  color: #9199a9;
}

.widget-team ul li .member .icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.widget-team ul li .member .icons .icon {
  height: 24px;
  width: 24px;
  background-color: #eceff4;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 3px;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-team ul li .member .icons .icon i {
  color: #ffffff;
}

.widget-team ul li .member .icons .icon.icon-hastag {
  background-color: #d08770;
}

.widget-team ul li .member .icons .icon.icon-hastag:hover {
  background-color: #a76a57;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.widget-team ul li .member .icons .icon.icon-twitter {
  background-color: #4394D8;
}

.widget-team ul li .member .icons .icon.icon-twitter:hover {
  background-color: #456184;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.widget-team ul li .member .icons .icon.icon-prime {
  background-color: #bf616a;
}

.widget-team ul li .member .icons .icon.icon-prime:hover {
  background-color: #9c4a52;
  -moz-transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
  transition: background-color 0.15s;
}

.widget-card-graph {
  padding: 0;
  text-align: center;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  min-height: 225px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.widget-card-graph .graph-header {
  width: 100%;
  -moz-border-radius-topleft: 7px;
  -webkit-border-top-left-radius: 7px;
  border-top-left-radius: 7px;
  -moz-border-radius-topright: 7px;
  -webkit-border-top-right-radius: 7px;
  border-top-right-radius: 7px;
  height: 124px;
}

.widget-card-graph .graph-header h5 {
  padding-top: 18px;
  font-size: 18px;
  color: #4c566a;
}

.widget-card-graph .graph-header .graph {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50px;
  left: 50%;
  margin-left: -75px;
}

.widget-card-graph .graph-header .graph .graph-center {
  width: 125px;
  height: 125px;
  background-color: #2e3440;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#2e3440), to(#eceff4));
  background-image: -webkit-linear-gradient(to bottom, #2e3440, #eceff4);
  background-image: -moz-linear-gradient(to bottom, #2e3440, #eceff4);
  background-image: -ms-linear-gradient(to bottom, #2e3440, #eceff4);
  background-image: -o-linear-gradient(to bottom, #2e3440, #eceff4);
  background-image: linear-gradient(to bottom, #2e3440, #eceff4);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2e3440", endColorstr="#eceff4");
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
}

.widget-card-graph .graph-header .graph .graph-border {
  width: 150px;
  height: 150px;
  background-color: #f2c260;
  background-image: -webkit-gradient(linear, right top, left top, from(#f2c260), to(#ebcb8b));
  background-image: -webkit-linear-gradient(to left, #f2c260, #ebcb8b);
  background-image: -moz-linear-gradient(to left, #f2c260, #ebcb8b);
  background-image: -ms-linear-gradient(to left, #f2c260, #ebcb8b);
  background-image: -o-linear-gradient(to left, #f2c260, #ebcb8b);
  background-image: linear-gradient(to left, #f2c260, #ebcb8b);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2c260", endColorstr="#ebcb8b", gradientType="1");
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: relative;
}

.widget-card-graph .graph-header .graph .graph-border .graph-block {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 150px;
  width: 64px;
  background-color: #ffffff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  height: 115px;
  -moz-border-radius-topright: 50%;
  -webkit-border-top-right-radius: 50%;
  border-top-right-radius: 50%;
  -moz-border-radius-bottomright: 50%;
  -webkit-border-bottom-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.widget-card-graph .graph-content {
  padding: 18px;
  color: #9199a9;
  border-top: solid 1px rgba(145, 153, 169, 0.3);
  background-color: #ffffff;
  position: relative;
  -moz-border-radius-bottomleft: 7px;
  -webkit-border-bottom-left-radius: 7px;
  border-bottom-left-radius: 7px;
  -moz-border-radius-bottomright: 7px;
  -webkit-border-bottom-right-radius: 7px;
  border-bottom-right-radius: 7px;
  width: 100%;
  z-index: 10;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.widget-card-graph .graph-content h5 {
  margin-top: 10px;
  color: #4c566a;
  margin-bottom: 6px;
}

.widget-card-graph .graph-content hr {
  width: 42px;
  height: 1px;
  background-color: rgba(145, 153, 169, 0.2);
  border: none;
}

.widget-card-graph .graph-content .graph-icon {
  width: 30px;
  height: 30px;
  box-shadow: inset 0 2px 0 0 rgba(208, 135, 112, 0.11);
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -15px;
  left: 50%;
  margin-left: -15px;
}

.widget-card-graph .graph-content .jun {
  position: absolute;
  right: 15%;
  top: 0;
}

.widget-card-graph.graph-1 .graph-header .graph .graph-center {
  background-color: #eceff4;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#eceff4), to(#ffffff));
  background-image: -webkit-linear-gradient(to bottom, #eceff4, #ffffff);
  background-image: -moz-linear-gradient(to bottom, #eceff4, #ffffff);
  background-image: -ms-linear-gradient(to bottom, #eceff4, #ffffff);
  background-image: -o-linear-gradient(to bottom, #eceff4, #ffffff);
  background-image: linear-gradient(to bottom, #eceff4, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eceff4", endColorstr="#ffffff");
}

.widget-card-graph.graph-1 .graph-content .graph-icon {
  background: -moz-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #f2c260), color-stop(100%, #ebcb8b));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* ie10+ */
  background: linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f2c260", endColorstr="#ebcb8b",GradientType=1 );
  /* ie6-9 */
}

.widget-card-graph.graph-2 .graph-header {
  background-size: cover;
}

.widget-card-graph.graph-2 .graph-header .graph .graph-border {
  background-color: #4c566a;
  background-image: -webkit-gradient(linear, left top, right top, from(#4c566a), to(#2e3440));
  background-image: -webkit-linear-gradient(to right, #4c566a, #2e3440);
  background-image: -moz-linear-gradient(to right, #4c566a, #2e3440);
  background-image: -ms-linear-gradient(to right, #4c566a, #2e3440);
  background-image: -o-linear-gradient(to right, #4c566a, #2e3440);
  background-image: linear-gradient(to right, #4c566a, #2e3440);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4c566a", endColorstr="#2e3440", gradientType="1");
}

.widget-card-graph.graph-2 .graph-content {
  background-color: #2e3440;
}

.widget-card-graph.graph-2 .graph-content h5 {
  color: #eceff4;
}

.widget-card-graph.graph-2 .graph-content .graph-icon {
  background-color: #2e3440;
}

.widget-card-graph.graph-3 .graph-header {
  background-size: cover;
}

.widget-card-graph.graph-3 .graph-header h5 {
  color: #ffffff;
}

.widget-card-graph.graph-3 .graph-header .graph .graph-center {
  background-color: #eceff4;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#eceff4), to(#ffffff));
  background-image: -webkit-linear-gradient(to bottom, #eceff4, #ffffff);
  background-image: -moz-linear-gradient(to bottom, #eceff4, #ffffff);
  background-image: -ms-linear-gradient(to bottom, #eceff4, #ffffff);
  background-image: -o-linear-gradient(to bottom, #eceff4, #ffffff);
  background-image: linear-gradient(to bottom, #eceff4, #ffffff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eceff4", endColorstr="#ffffff");
}

.widget-card-graph.graph-3 .graph-content .graph-icon {
  background: -moz-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* ff3.6+ */
  background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #f2c260), color-stop(100%, #ebcb8b));
  /* safari4+,chrome */
  background: -webkit-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* opera 11.10+ */
  background: -ms-linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* ie10+ */
  background: linear-gradient(225deg, #f2c260 0%, #ebcb8b 100%);
  /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#f2c260", endColorstr="#ebcb8b",GradientType=1 );
  /* ie6-9 */
}

.widget-card-graph.graph-4 .graph-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.widget-card-graph.graph-4 .graph-header .title-tag {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #434c5e;
  color: #d8dee9;
  font-size: 12px;
  position: absolute;
  top: -8px;
  left: -8px;
  padding: 3px 6px;
  text-align: center;
}

.widget-card-graph.graph-4 .graph-header img {
  display: block;
  width: 100%;
  max-height: 120px;
}

.widget-card-graph.graph-4 .graph-content .graph-icon {
  background-color: #45b0d5;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#45b0d5), to(#59c7ed));
  background-image: -webkit-linear-gradient(to bottom, #45b0d5, #59c7ed);
  background-image: -moz-linear-gradient(to bottom, #45b0d5, #59c7ed);
  background-image: -ms-linear-gradient(to bottom, #45b0d5, #59c7ed);
  background-image: -o-linear-gradient(to bottom, #45b0d5, #59c7ed);
  background-image: linear-gradient(to bottom, #45b0d5, #59c7ed);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#45b0d5", endColorstr="#59c7ed");
}

.widget-store-items {
  position: relative;
}

.widget-store-items .store-info {
  text-align: right;
}

.widget-store-items .store-info .info-item {
  display: inline-block;
  margin-left: 15px;
  color: #9199a9;
  cursor: pointer;
}

.widget-store-items .store-info .info-item:hover {
  color: #4c566a;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.widget-store-items .store-cards-container .store-cards {
  max-height: 489px;
  overflow: auto;
}

.widget-store-items .store-cards-container .store-cards .store-card {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  border: solid 1px #e5e9f0;
  background-color: #f9fafb;
  height: 100%;
}

.widget-store-items .store-cards-container .store-cards .store-card .store-card-icons {
  text-align: center;
  padding: 6px;
  border-bottom: solid 1px #d8dee9;
}

.widget-store-items .store-cards-container .store-cards .store-card .store-card-icons i {
  color: #9199a9;
  cursor: pointer;
  font-size: 20px;
}

.widget-store-items .store-cards-container .store-cards .store-card .store-card-icons i:hover {
  color: #4c566a;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.widget-store-items .store-cards-container .store-cards .store-card .store-card-image img {
  width: 100%;
}

.widget-store-items .store-cards-container .store-cards .store-card .store-card-content {
  text-align: center;
  padding-bottom: 12px;
}

.widget-store-items .store-cards-container .store-cards .store-card .store-card-content h5 {
  margin: 6px;
  font-weight: 500;
  color: #4c566a;
}

.widget-store-items .store-cards-container .store-cards .store-card .store-card-content span {
  font-size: 12px;
  color: #9199a9;
}

.widget-download-files {
  padding: 0;
  padding-top: 18px;
  position: relative;
}

.widget-download-files .title {
  padding-left: 18px;
}

.widget-download-files .files-info {
  text-align: right;
  padding-right: 18px;
}

.widget-download-files .files-info span {
  font-size: 12px;
  color: #9199a9;
  cursor: pointer;
  display: block;
  margin-bottom: 1rem;
}

.widget-download-files .files-info span:hover {
  color: #4c566a;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.widget-download-files ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 500px;
  overflow: auto;
}

.widget-download-files ul li {
  padding: 15px;
  border-top: 1px solid rgba(145, 153, 169, 0.3);
}

.widget-download-files ul li:last-child {
  border-bottom: 1px solid rgba(145, 153, 169, 0.3);
}

.widget-download-files ul li .list-item {
  align-items: center;
  cursor: pointer;
}

.widget-download-files ul li .list-item:hover .file-name h6 {
  color: #9199a9;
  -moz-transition: color 0.15s;
  -o-transition: color 0.15s;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

.widget-download-files ul li .list-item .file-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.widget-download-files ul li .list-item .file-name h6 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #4c566a;
}

.widget-download-files ul li .list-item .icon {
  text-align: center;
}

.widget-download-files ul li .list-item .new {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}

.widget-download-files ul li .list-item .new .new-tag {
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  height: 20px;
  background-color: #434c5e;
  color: #d8dee9;
  font-size: 12px;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add your customizations of layout here */
/* Add your customizations of the layout variables here */
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeOutUp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}
@keyframes fadeinmask {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes modal-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
@keyframes modal-in {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
.modal-in {
  -webkit-animation-name: modal-in;
  animation-name: modal-in;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1.5rem 0 1rem 0;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

mark {
  background: #FFF8E1;
  padding: 0.25rem 0.4rem;
  border-radius: 24px;
  font-family: monospace;
}

blockquote {
  margin: 1rem 0;
  padding: 0 2rem;
  border-left: 4px solid #90A4AE;
}

hr {
  border-top: solid #dee2e6;
  border-width: 1px 0 0 0;
  margin: 1rem 0;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.5;
}

p:last-child {
  margin-bottom: 0;
}

html {
  height: 100%;
  font-size: 14px;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
  font-weight: 400;
  color: rgba(41, 50, 65, 0.8);
  padding: 0;
  margin: 0;
  min-height: 100%;
  background-color: #F2F4F6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.blocked-scroll {
  overflow: auto;
}

a {
  text-decoration: none;
  color: #4f8eec;
  color: var(--primary-color);
}

.ajax-loader {
  font-size: 32px;
  color: #2170E7;
  color: var(--primary-color);
}

.layout-main {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 100vh;
  padding-top: 0px;
  -moz-transition: padding-left 0.2s;
  -o-transition: padding-left 0.2s;
  -webkit-transition: padding-left 0.2s;
  transition: padding-left 0.2s;
}

.layout-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.05, 0.74, 0.2, 0.99);
  animation-fill-mode: forwards;
}

.layout-content {
  padding: 30px 36px;
  flex: 1 1 auto;
}

@media (max-width: 991px) {
  .layout-content {
    padding: 32px 14px;
  }
}
.layout-topbar-light .layout-topbar {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  -moz-transition: width 0.2s;
  -o-transition: width 0.2s;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  height: 62px;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-left {
  height: 100%;
  padding: 0 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  z-index: 999;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo {
  height: 15px;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo > img {
  height: 15px;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button {
  padding: 6px;
  margin-right: 16px;
  border-radius: 4px;
  display: none;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button i {
  font-size: 18px;
  width: 18px;
  height: 18px;
  background-color: transparent;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right {
  height: 100%;
  flex-grow: 1;
  padding: 0 16px 0 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-right: solid 1px transparent;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  flex-grow: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a {
  width: 100%;
  padding: 6px;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a > .topbar-icon {
  font-size: 18px;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a.layout-rightpanel-button i {
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul {
  display: none;
  position: absolute;
  top: 62px;
  right: 0px;
  list-style-type: none;
  margin: 0;
  padding: 7px 0 8px;
  z-index: 1000;
  -moz-border-radius-bottomleft: 2px;
  -webkit-border-bottom-left-radius: 2px;
  border-bottom-left-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  -webkit-border-bottom-right-radius: 2px;
  border-bottom-right-radius: 2px;
  min-width: 250px;
  animation-duration: 0.2s;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .angle-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  flex-grow: 1;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li {
  padding: 10px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  list-style: none;
  margin-bottom: 4px;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a i {
  margin-right: 10px;
  padding: 6px;
  border-radius: 2px;
  width: 26px;
  height: 26px;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a img {
  height: 36px;
  width: 36px;
  margin-right: 10px;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item a {
  width: auto;
  display: block;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item .search-input-wrapper {
  position: relative;
  width: 0;
  opacity: 0;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item .search-input-wrapper .p-inputtext {
  background: #F7FAFF;
  width: 100%;
  position: relative;
  padding: 9px;
  padding-left: 37px;
  border: none;
  color: #3E4754;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item .search-input-wrapper i {
  position: absolute;
  left: 9px;
  font-size: 18px;
  top: 50%;
  margin-top: -9px;
  display: none;
  z-index: 1;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.user-profile > a {
  margin-left: 16px;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.user-profile > a > img {
  height: 28px;
  width: 28px;
  border-radius: 10px;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item a {
  display: none;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item .search-input-wrapper {
  width: 200px;
  opacity: 1;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item .search-input-wrapper i {
  display: block;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item > ul {
  display: none;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem > ul {
  display: block;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-rightpanel-button {
  font-size: 18px;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  margin-left: 26px;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .layout-topbar-light .layout-topbar .layout-topbar-wrapper {
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
    padding: 0 6px;
  }
}
@media (max-width: 576px) {
  .layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item a {
    display: block;
  }

  .layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item > .search-input-wrapper {
    display: none;
  }

  .layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item > ul {
    display: block;
    padding: 0;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
  }

  .layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul {
    left: 10px;
    right: 10px;
    position: fixed;
    top: 62px;
  }
}
.layout-topbar-light .layout-topbar {
  background-color: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(41, 50, 65, 0.06);
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button > i {
  color: #293241;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button:hover {
  background-color: #E8EDF0;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a > .topbar-icon {
  color: #293241;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a:hover i {
  background-color: #E8EDF0;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > form > .search-input-wrapper input {
  background-color: #f8fafc;
  border: 1px solid #ebedef;
  color: lighthen(#2170E7, 10%);
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > form > .search-input-wrapper input::placeholder {
  color: lighthen(#2170E7, 10%);
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > form > .search-input-wrapper i {
  color: #293241;
  opacity: 0.5;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul {
  box-shadow: 0 2px 8px 0 rgba(25, 26, 28, 0.12);
  background-color: white;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header {
  background-color: #4688eb;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header h6 {
  color: #ffffff;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header:hover {
  background-color: #4688eb;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a {
  color: rgba(41, 50, 65, 0.8);
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a i {
  background-color: rgba(33, 112, 231, 0.8);
  color: #ffffff;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a .notification-detail {
  color: rgba(41, 50, 65, 0.5);
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li .menu-text p {
  color: rgba(41, 50, 65, 0.8);
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li .menu-text span {
  color: rgba(41, 50, 65, 0.5);
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li:hover {
  background-color: rgba(33, 112, 231, 0.2);
  background-color: var(--primary-lighter-color);
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-rightpanel-button {
  color: #293241;
  background-color: transparent;
}

.layout-topbar-light .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-rightpanel-button:hover {
  background-color: #E8EDF0;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.layout-topbar-dark .layout-topbar {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  -moz-transition: width 0.2s;
  -o-transition: width 0.2s;
  -webkit-transition: width 0.2s;
  transition: width 0.2s;
  height: 62px;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-left {
  height: 100%;
  padding: 0 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  z-index: 999;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo {
  height: 15px;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo > img {
  height: 15px;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button {
  padding: 6px;
  margin-right: 16px;
  border-radius: 4px;
  display: none;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button i {
  font-size: 18px;
  width: 18px;
  height: 18px;
  background-color: transparent;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right {
  height: 100%;
  flex-grow: 1;
  padding: 0 16px 0 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-right: solid 1px transparent;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  flex-grow: 1;
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a {
  width: 100%;
  padding: 6px;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a > .topbar-icon {
  font-size: 18px;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  background-color: transparent;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a.layout-rightpanel-button i {
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul {
  display: none;
  position: absolute;
  top: 62px;
  right: 0px;
  list-style-type: none;
  margin: 0;
  padding: 7px 0 8px;
  z-index: 1000;
  -moz-border-radius-bottomleft: 2px;
  -webkit-border-bottom-left-radius: 2px;
  border-bottom-left-radius: 2px;
  -moz-border-radius-bottomright: 2px;
  -webkit-border-bottom-right-radius: 2px;
  border-bottom-right-radius: 2px;
  min-width: 250px;
  animation-duration: 0.2s;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .angle-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  flex-grow: 1;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li {
  padding: 10px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  list-style: none;
  margin-bottom: 4px;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a i {
  margin-right: 10px;
  padding: 6px;
  border-radius: 2px;
  width: 26px;
  height: 26px;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a img {
  height: 36px;
  width: 36px;
  margin-right: 10px;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item a {
  width: auto;
  display: block;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item .search-input-wrapper {
  position: relative;
  width: 0;
  opacity: 0;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item .search-input-wrapper .p-inputtext {
  background: #F7FAFF;
  width: 100%;
  position: relative;
  padding: 9px;
  padding-left: 37px;
  border: none;
  color: #3E4754;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item .search-input-wrapper i {
  position: absolute;
  left: 9px;
  font-size: 18px;
  top: 50%;
  margin-top: -9px;
  display: none;
  z-index: 1;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.user-profile > a {
  margin-left: 16px;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.user-profile > a > img {
  height: 28px;
  width: 28px;
  border-radius: 10px;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item a {
  display: none;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item .search-input-wrapper {
  width: 200px;
  opacity: 1;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item .search-input-wrapper i {
  display: block;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item > ul {
  display: none;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem > ul {
  display: block;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-rightpanel-button {
  font-size: 18px;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  margin-left: 26px;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .layout-topbar-dark .layout-topbar .layout-topbar-wrapper {
    -ms-flex-align: start;
    align-items: flex-start;
    position: relative;
    padding: 0 6px;
  }
}
@media (max-width: 576px) {
  .layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item a {
    display: block;
  }

  .layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item > .search-input-wrapper {
    display: none;
  }

  .layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item > ul {
    display: block;
    padding: 0;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
  }

  .layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul {
    left: 10px;
    right: 10px;
    position: fixed;
    top: 62px;
  }
}
.layout-topbar-dark .layout-topbar {
  background-color: #293241;
  box-shadow: none;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button > i {
  color: #E9E9E9;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button:hover {
  background-color: #333e51;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a > .topbar-icon {
  color: #E9E9E9;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > a:hover i {
  background-color: #333e51;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > form > .search-input-wrapper input {
  background-color: #333e51;
  border: 1px solid #333e51;
  color: #7dabf1;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > form > .search-input-wrapper input::placeholder {
  color: #7dabf1;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.search-item > form > .search-input-wrapper i {
  color: #E9E9E9;
  opacity: 0.5;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul {
  box-shadow: 0 2px 8px 0 rgba(25, 26, 28, 0.12);
  background-color: #333e51;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header {
  background-color: #4688eb;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header h6 {
  color: #ffffff;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul .layout-submenu-header:hover {
  background-color: #4688eb;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a {
  color: #E9E9E9;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a i {
  background-color: rgba(33, 112, 231, 0.8);
  color: #ffffff;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li > a .notification-detail {
  color: #C2C2C2;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li .menu-text p {
  color: #E9E9E9;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li .menu-text span {
  color: #C2C2C2;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li > ul > li:hover {
  background-color: rgba(33, 112, 231, 0.2);
  background-color: var(--primary-lighter-color);
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-rightpanel-button {
  color: #E9E9E9;
  background-color: transparent;
}

.layout-topbar-dark .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-rightpanel-button:hover {
  background-color: #333e51;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.menu-wrapper {
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  left: 0;
}

.menu-wrapper .sidebar-logo {
  height: 62px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 22px;
  padding-right: 20px;
}

.menu-wrapper .sidebar-logo .sidebar-pin {
  display: none;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.menu-wrapper .sidebar-logo .sidebar-pin > span {
  display: block;
  height: 16px;
  width: 16px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid #dee2e6;
}

.menu-wrapper .sidebar-logo img {
  width: 17px;
  height: 20px;
  border: 0 none;
}

.menu-wrapper .layout-menu-container {
  height: calc(100% - 62px);
}

.menu-wrapper .layout-menu-container .layout-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-width: 62px;
  overflow: hidden;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.menu-wrapper .layout-menu-container .layout-menu > li > a {
  position: relative;
}

.menu-wrapper .layout-menu-container .layout-menu > li > a::before {
  content: "";
  width: 4px;
  height: 12px;
  display: block;
  border-radius: 0px 3px 3px 0px;
  position: absolute;
  left: 0;
}

.menu-wrapper .layout-menu-container .layout-menu > li > ul > li {
  margin-left: 6px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.menu-wrapper .layout-menu-container .layout-menu li {
  padding: 10px 0;
}

.menu-wrapper .layout-menu-container .layout-menu li.active-menuitem > a i.layout-submenu-toggler {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.menu-wrapper .layout-menu-container .layout-menu li .layout-menu-tooltip {
  display: none;
}

.menu-wrapper .layout-menu-container .layout-menu li > a {
  margin: 0px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  font-size: 13px;
  padding: 6px 20px;
  user-select: none;
  cursor: pointer;
}

.menu-wrapper .layout-menu-container .layout-menu li > a > span {
  margin: 0 8px;
  margin-left: 14px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  visibility: hidden;
  white-space: nowrap;
}

.menu-wrapper .layout-menu-container .layout-menu li > a > i {
  font-size: 24px;
}

.menu-wrapper .layout-menu-container .layout-menu li > a > i.layout-submenu-toggler {
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
  margin-left: auto;
  font-size: 12px;
  visibility: hidden;
}

.menu-wrapper .layout-menu-container .layout-menu li > a.rotated-icon .layout-menuitem-icon {
  transform: rotate(90deg);
}

.menu-wrapper .layout-menu-container .layout-menu li > ul {
  list-style-type: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
}

.menu-wrapper .layout-menu-container .layout-menu li > ul > li {
  padding: 0;
}

.menu-wrapper .layout-menu-container .layout-menu li > ul > li > a {
  padding: 10px 18px;
  margin-left: 0px;
  padding-right: 8px;
}

.menu-wrapper .layout-menu-container .layout-menu li > ul > li i {
  font-size: 14px;
}

.menu-wrapper .layout-menu-container .layout-menu li > ul > li ul li {
  padding: 0;
}

.menu-wrapper .layout-menu-container .layout-menu li > ul > li.layout-root-menuitem > a {
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 992px) {
  .layout-wrapper.layout-sidebar .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo {
    display: none;
  }

  .layout-wrapper.layout-sidebar .layout-main {
    padding-left: 62px;
  }

  .layout-wrapper.layout-static .menu-wrapper {
    transform: translate3d(0px, 0px, 0px);
  }

  .layout-wrapper.layout-static .menu-wrapper .sidebar-logo {
    justify-content: space-between;
  }

  .layout-wrapper.layout-static .menu-wrapper .sidebar-logo img {
    display: inline;
  }

  .layout-wrapper.layout-static .menu-wrapper .sidebar-logo .app-name {
    display: inline;
  }

  .layout-wrapper.layout-static .menu-wrapper .sidebar-logo .sidebar-pin {
    display: inline-block;
  }

  .layout-wrapper.layout-static .menu-wrapper .sidebar-logo .sidebar-pin > span {
    display: block;
    height: 16px;
    width: 16px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #dee2e6;
    border: 2px solid var(--primary-light-color);
    background-color: #dee2e6;
    background-color: var(--primary-lighter-color);
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu {
    max-width: 230px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu > li {
    min-width: 230px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu > li > ul > li {
    margin-left: 10px;
    margin-right: 12px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu li ul li a {
    padding-left: 20px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu li ul li ul li a {
    padding-left: 30px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu li ul li ul li ul li a {
    padding-left: 40px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu li ul li ul li ul li ul li a {
    padding-left: 50px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu li ul li ul li ul li ul li ul li a {
    padding-left: 60px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu li ul li ul li ul li ul li ul li ul li a {
    padding-left: 70px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 80px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu li ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 90px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu li ul li ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 100px;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu li > a > span {
    visibility: visible;
    white-space: normal;
  }

  .layout-wrapper.layout-static .menu-wrapper .layout-menu li > a i.layout-submenu-toggler {
    visibility: visible;
  }

  .layout-wrapper.layout-static .layout-main {
    padding-left: 230px;
    -moz-transition: padding-left 0.2s;
    -o-transition: padding-left 0.2s;
    -webkit-transition: padding-left 0.2s;
    transition: padding-left 0.2s;
  }

  .menu-wrapper.layout-sidebar-active {
    transform: translate3d(0px, 0px, 0px);
  }

  .menu-wrapper.layout-sidebar-active .sidebar-logo {
    justify-content: space-between;
  }

  .menu-wrapper.layout-sidebar-active .sidebar-logo img {
    display: inline;
  }

  .menu-wrapper.layout-sidebar-active .sidebar-logo .app-name {
    display: inline;
  }

  .menu-wrapper.layout-sidebar-active .sidebar-logo .sidebar-pin {
    display: inline-block;
  }

  .menu-wrapper.layout-sidebar-active .sidebar-logo .sidebar-pin > span {
    display: block;
    height: 16px;
    width: 16px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: 2px solid #dee2e6;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu {
    max-width: 230px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu > li {
    min-width: 230px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu > li > ul > li {
    margin-left: 10px;
    margin-right: 12px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu li ul li a {
    padding-left: 20px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu li ul li ul li a {
    padding-left: 30px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu li ul li ul li ul li a {
    padding-left: 40px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu li ul li ul li ul li ul li a {
    padding-left: 50px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu li ul li ul li ul li ul li ul li a {
    padding-left: 60px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu li ul li ul li ul li ul li ul li ul li a {
    padding-left: 70px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 80px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu li ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 90px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu li ul li ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 100px;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu li > a > span {
    visibility: visible;
    white-space: normal;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu li > a i.layout-submenu-toggler {
    visibility: visible;
  }

  .menu-wrapper.layout-sidebar-active .layout-menu-container {
    overflow: auto;
  }
}
@media (max-width: 991px) {
  .layout-wrapper .menu-wrapper {
    top: 62px;
    z-index: 1010;
    -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
    transform: translate3d(-230px, 0px, 0px);
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  .layout-wrapper .menu-wrapper .sidebar-logo {
    display: none;
  }

  .layout-wrapper .menu-wrapper .layout-menu-container .layout-menu {
    max-width: 230px;
  }

  .layout-wrapper.layout-mobile-active {
    overflow: hidden;
    height: 100vh;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper {
    transform: translate3d(0px, 0px, 0px);
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu {
    max-width: 230px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu > li {
    min-width: 230px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu > li > ul > li {
    margin-left: 10px;
    margin-right: 12px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu li ul li a {
    padding-left: 20px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu li ul li ul li a {
    padding-left: 30px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu li ul li ul li ul li a {
    padding-left: 40px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu li ul li ul li ul li ul li a {
    padding-left: 50px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu li ul li ul li ul li ul li ul li a {
    padding-left: 60px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu li ul li ul li ul li ul li ul li ul li a {
    padding-left: 70px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 80px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu li ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 90px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu li ul li ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 100px;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu li > a > span {
    visibility: visible;
    white-space: normal;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu li > a i.layout-submenu-toggler {
    visibility: visible;
  }

  .layout-wrapper.layout-mobile-active .menu-wrapper .layout-menu-container {
    overflow: auto;
  }

  .layout-wrapper.layout-mobile-active .layout-mask {
    display: block;
  }

  .layout-wrapper .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button {
    display: block;
  }
}
@media (min-width: 992px) {
  .layout-wrapper.layout-horizontal .menu-wrapper {
    top: 0px;
    width: 100%;
    height: 62px;
    position: relative;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .sidebar-logo {
    display: none;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu {
    list-style-type: none;
    margin: 0px 16px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    max-width: 100%;
    overflow: visible;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu li.active-menuitem > a i.layout-submenu-toggler {
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
    -o-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li {
    padding: 0;
    position: relative;
    margin: 0 9px;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li .layout-menu-tooltip {
    display: none;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li a {
    margin: 0px;
    padding: 10px 5px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li a:before {
    display: none;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li a > span {
    margin: 0 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    visibility: visible;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li a > i {
    font-size: 14px;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li a > i.layout-submenu-toggler {
    -moz-transition: transform 0.2s;
    -o-transition: transform 0.2s;
    -webkit-transition: transform 0.2s;
    transition: transform 0.2s;
    margin-left: auto;
    font-size: 12px;
    visibility: visible;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li.layout-root-menuitem > div {
    display: none;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul {
    list-style-type: none;
    top: 44px;
    left: 0px;
    width: 230px;
    position: absolute;
    padding: 10px;
    margin: 0;
    z-index: 100;
    overflow: auto;
    max-height: 460px;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul > li {
    border: 0 none;
    margin: 0;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li a {
    padding-left: 20px;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li a {
    padding-left: 30px;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li a {
    padding-left: 40px;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li a {
    padding-left: 50px;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li ul li a {
    padding-left: 60px;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li ul li ul li a {
    padding-left: 70px;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 80px;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 90px;
  }

  .layout-wrapper.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul > li ul li ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 100px;
  }

  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-wrapper .layout-topbar-left .menu-button-wrapper .menu-button {
    display: none;
  }

  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item a {
    display: block;
  }

  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item > .search-input-wrapper {
    display: none;
  }

  .layout-wrapper.layout-horizontal .layout-topbar .layout-topbar-wrapper .layout-topbar-right .layout-topbar-actions > li.active-topmenuitem.search-item > ul {
    display: block;
    padding: 0;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
  }
}
@media (min-width: 992px) {
  .layout-wrapper.layout-slim .menu-wrapper {
    width: 62px;
    overflow: visible;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container {
    padding: 0;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu {
    overflow: visible;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip {
    display: none;
    padding: 0 0.412px;
    position: absolute;
    left: 72px;
    top: 16px;
    line-height: 1;
    border-radius: 2px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-text {
    padding: 6px 8px;
    font-weight: 500;
    min-width: 75px;
    white-space: nowrap;
    text-align: center;
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
    -moz-box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
    box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    top: 50%;
    left: -4px;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li {
    position: relative;
    padding: 10px 12px 10px 14px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a {
    margin: 0px;
    padding: 6px;
    -moz-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    position: relative;
    border: none;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a:before {
    display: none;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a span {
    display: none;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a i {
    margin-right: 0;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a i.layout-submenu-toggler {
    display: none;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a .menuitem-badge {
    display: none;
    margin-left: auto;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a:hover + .layout-menu-tooltip {
    display: block;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul {
    position: absolute;
    top: 0;
    left: 62px;
    min-width: 250px;
    max-height: 450px;
    padding: 10px;
    overflow: auto;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li {
    margin: 0;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a {
    padding: 10px 5px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a > span {
    visibility: visible;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a > i.layout-submenu-toggler {
    visibility: visible;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li ul li a {
    padding-left: 20px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li ul li ul li a {
    padding-left: 30px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li ul li ul li ul li a {
    padding-left: 40px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li ul li ul li ul li ul li a {
    padding-left: 50px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li ul li ul li ul li ul li ul li a {
    padding-left: 60px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 70px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 80px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 90px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li ul li ul li ul li ul li ul li ul li ul li ul li ul li a {
    padding-left: 100px;
  }

  .layout-wrapper.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a:hover + .layout-menu-tooltip {
    display: none;
  }

  .layout-wrapper.layout-slim .layout-topbar .layout-topbar-wrapper .layout-topbar-left .layout-topbar-logo {
    display: none;
  }

  .layout-wrapper.layout-slim .layout-main {
    padding-left: 62px;
  }
}
.layout-menu-dark .menu-wrapper {
  background-color: #293241;
  box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > a {
  color: #E9E9E9;
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
  color: #2170E7;
  color: var(--primary-light-color);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > a.active-route {
  color: #2170E7;
  color: var(--primary-light-color);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > a.active-route::before {
  background-color: #2170E7;
  background-color: var(--primary-light-color);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
  color: #2170E7;
  color: var(--primary-light-color);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a::before {
  background-color: #2170E7;
  background-color: var(--primary-light-color);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem {
  background-color: #293241;
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a {
  background-color: #293241;
  color: #2170E7;
  color: var(--primary-light-color);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul > li > a.active-route {
  color: #2170E7;
  color: var(--primary-light-color);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul > li > a.active-route::before {
  background-color: #2170E7;
  background-color: var(--primary-light-color);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul li a {
  color: rgba(233, 233, 233, 0.8);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul li a:hover {
  background-color: rgba(33, 112, 231, 0.2);
  background-color: var(--primary-lighter-color);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
  color: #2170E7;
  color: var(--primary-light-color);
}

.layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a::before {
  background-color: #2170E7;
  background-color: var(--primary-light-color);
}

@media (min-width: 992px) {
  .layout-menu-dark .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > a {
    color: #E9E9E9;
  }

  .layout-menu-dark .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > a:hover {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li.active-menuitem > a {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li.active-menuitem > a::before {
    background-color: #2170E7;
    background-color: var(--primary-light-color);
  }

  .layout-menu-dark .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > ul > li.active-menuitem {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-dark .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
    color: #E9E9E9;
  }

  .layout-menu-dark .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > ul li a {
    color: rgba(233, 233, 233, 0.8);
  }

  .layout-menu-dark .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > ul li a:hover {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-dark .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #E9E9E9;
  }

  .layout-menu-dark.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > a {
    color: #E9E9E9;
  }

  .layout-menu-dark.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-static .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-static .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a::before {
    background-color: #2170E7;
    background-color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-dark.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
    color: #E9E9E9;
  }

  .layout-menu-dark.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > ul li a {
    color: rgba(233, 233, 233, 0.8);
  }

  .layout-menu-dark.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > ul li a:hover {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-dark.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #E9E9E9;
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper {
    box-shadow: none;
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > a {
    color: #E9E9E9;
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a i {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a:hover {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul {
    background-color: #293241;
    box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a {
    color: rgba(233, 233, 233, 0.8);
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a:hover {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route i {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route:hover {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip {
    background-color: #293241;
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-text {
    color: #ffffff;
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-arrow {
    border-right-color: #293241;
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a i {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a:hover {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a.active-route {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a.active-route i {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a.active-route:hover {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul {
    box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
    background-color: #293241;
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a {
    color: rgba(233, 233, 233, 0.8);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a:hover {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route i {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route:hover {
    color: #2170E7;
    color: var(--primary-light-color);
  }
}
@media (max-width: 991px) {
  .layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > a {
    color: #E9E9E9;
  }

  .layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    color: #2170E7;
    color: var(--primary-light-color);
  }

  .layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a::before {
    background-color: #2170E7;
    background-color: var(--primary-light-color);
  }

  .layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
    color: #E9E9E9;
  }

  .layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul li a {
    color: rgba(233, 233, 233, 0.8);
  }

  .layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul li a:hover {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-dark .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #E9E9E9;
  }
}
.layout-menu-light .menu-wrapper {
  background-color: #ffffff;
  box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > a {
  color: #293241;
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
  color: #2170E7;
  color: var(--primary-color);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > a.active-route {
  color: #2170E7;
  color: var(--primary-color);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > a.active-route::before {
  background-color: #2170E7;
  background-color: var(--primary-color);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
  color: #2170E7;
  color: var(--primary-color);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a::before {
  background-color: #2170E7;
  background-color: var(--primary-color);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem {
  background-color: #ffffff;
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a {
  background-color: #ffffff;
  color: #2170E7;
  color: var(--primary-color);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul > li > a.active-route {
  color: #2170E7;
  color: var(--primary-color);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul > li > a.active-route::before {
  background-color: #2170E7;
  background-color: var(--primary-color);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul li a {
  color: rgba(41, 50, 65, 0.7);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul li a:hover {
  background-color: rgba(33, 112, 231, 0.2);
  background-color: var(--primary-lighter-color);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
  color: #2170E7;
  color: var(--primary-color);
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a::before {
  background-color: #2170E7;
  background-color: var(--primary-color);
}

@media (min-width: 992px) {
  .layout-menu-light .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > a {
    color: #293241;
  }

  .layout-menu-light .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > a:hover {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li.active-menuitem > a {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li.active-menuitem > a::before {
    background-color: #2170E7;
    background-color: var(--primary-color);
  }

  .layout-menu-light .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > ul > li.active-menuitem {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-light .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
    color: #293241;
  }

  .layout-menu-light .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > ul li a {
    color: rgba(41, 50, 65, 0.7);
  }

  .layout-menu-light .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > ul li a:hover {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-light .menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #293241;
  }

  .layout-menu-light.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > a {
    color: #293241;
  }

  .layout-menu-light.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-static .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-static .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a::before {
    background-color: #2170E7;
    background-color: var(--primary-color);
  }

  .layout-menu-light.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-light.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
    color: #293241;
  }

  .layout-menu-light.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > ul li a {
    color: rgba(41, 50, 65, 0.7);
  }

  .layout-menu-light.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > ul li a:hover {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-light.layout-static .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #293241;
  }

  .layout-menu-light.layout-horizontal .menu-wrapper {
    box-shadow: none;
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > a {
    color: #293241;
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
    color: var(--primary-color);
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a i {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a:hover {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul {
    background-color: #ffffff;
    box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a {
    color: rgba(41, 50, 65, 0.7);
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a:hover {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
    color: var(--primary-color);
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route i {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-horizontal .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route:hover {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip {
    background-color: #293241;
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-text {
    color: #ffffff;
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu .layout-menu-tooltip .layout-menu-tooltip-arrow {
    border-right-color: #293241;
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a i {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a:hover {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a.active-route {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a.active-route i {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > a.active-route:hover {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul {
    box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
    background-color: #ffffff;
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a {
    color: rgba(41, 50, 65, 0.7);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a:hover {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route i {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light.layout-slim .menu-wrapper .layout-menu-container .layout-menu > li > ul li > a.active-route:hover {
    color: #2170E7;
    color: var(--primary-color);
  }
}
@media (max-width: 991px) {
  .layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > a {
    color: #293241;
  }

  .layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > a:hover {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a {
    color: #2170E7;
    color: var(--primary-color);
  }

  .layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li.active-menuitem > a::before {
    background-color: #2170E7;
    background-color: var(--primary-color);
  }

  .layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem {
    background-color: rgba(33, 112, 231, 0.2);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul > li.active-menuitem > a {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
    color: #293241;
  }

  .layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul li a {
    color: rgba(41, 50, 65, 0.7);
  }

  .layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul li a:hover {
    background-color: rgba(33, 112, 231, 0.1);
    background-color: var(--primary-lighter-color);
  }

  .layout-menu-light .menu-wrapper .layout-menu-container .layout-menu > li > ul li.active-menuitem > a {
    color: #293241;
  }
}
.layout-rightpanel {
  position: fixed;
  z-index: 1000;
  right: 0;
  top: 62px;
  height: calc(100% - 62px);
  padding: 0;
  width: 418px;
  overflow: auto;
  background-color: #F7FAFF;
  transform: translate3d(418px, 0px, 0px);
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
}

.layout-rightpanel .rightpanel-wrapper {
  padding: 22px 20px 40px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section {
  padding: 16px 0;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section .section-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 16px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section .section-header > h6 {
  margin: 0;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.weather-section .weather {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 14px 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 10px 40 rgba(41, 50, 65, 0.06);
  -moz-box-shadow: 0px 10px 40 rgba(41, 50, 65, 0.06);
  box-shadow: 0px 10px 40 rgba(41, 50, 65, 0.06);
  color: rgba(41, 50, 65, 0.8);
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.weather-section .weather > img {
  height: 60px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.weather-section .weather .weather-info {
  margin-left: 16px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.weather-section .weather .weather-info h6 {
  margin: 0 0 2px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.weather-section .weather .weather-info h1 {
  margin: 0;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.tasks-section > ul {
  padding: 0;
  margin: 0;
  list-style: none;
  overflow: auto;
  max-height: 320px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.tasks-section > ul > li {
  padding: 16px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 12px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.tasks-section > ul > li .task-info h6 {
  color: #3E4754;
  margin: 0 0 4px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.tasks-section > ul > li .task-info > span {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: rgba(41, 50, 65, 0.5);
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.tasks-section > ul > li.done {
  opacity: 0.5;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.tasks-section > ul > li.done .task-info h6 {
  text-decoration: line-through;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.favorites-section .favorite-items {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  margin: -7px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.favorites-section .favorite-items .favorite-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  box-shadow: 0px 0px 1px rgba(41, 50, 65, 0.5), 0px 1px 1px rgba(41, 50, 65, 0.2);
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  margin: 7px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.favorites-section .favorite-items .favorite-item:hover {
  background-color: #F7F7F8;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.favorites-section .favorite-items .add-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  width: 80px;
  height: 80px;
  margin: 7px;
  border: 1px dashed #dee2e6;
  color: #dee2e6;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.favorites-section .favorite-items .add-item:hover {
  background-color: #F7F7F8;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.chat-section {
  margin-top: 40px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.chat-section .p-tabview .p-tabview-panels .p-tabview-panel {
  height: 400px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.chat-section .p-tabview .p-tabview-panels .p-tabview-panel .chat {
  height: 400px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.chat-section .p-tabview .p-tabview-panels .p-tabview-panel .chat .fade {
  background-image: linear-gradient(180deg, #F7FAFF 0%, rgba(234, 237, 243, 0) 100%);
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.chat-section .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content {
  max-height: 400px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.chat-section .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content .chat-message .message {
  background-color: #ffffff;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.chat-section .p-tabview .p-tabview-panels .p-tabview-panel .chat .contacts {
  max-height: 400px;
}

.layout-rightpanel .rightpanel-wrapper .rightpanel-section.chat-section .p-tabview .p-tabview-panels .p-tabview-panel .chat .contacts ul > li:hover {
  background-color: #ffffff;
}

.layout-wrapper.layout-rightpanel-active .layout-rightpanel {
  transform: translate3d(0px, 0px, 0px);
}

@media (max-width: 576px) {
  .layout-rightpanel {
    width: 100%;
    transform: translate3d(100%, 0px, 0px);
  }
}
.layout-footer {
  padding: 30px 36px;
}

.layout-footer .footer-menutitle {
  color: rgba(41, 50, 65, 0.5);
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  min-height: 15px;
  display: block;
  margin-bottom: 9px;
}

.layout-footer .footer-subtitle {
  font-weight: 500;
  font-size: 14px;
  display: block;
  color: rgba(41, 50, 65, 0.5);
}

.layout-footer ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.layout-footer ul > li {
  padding: 7px 0;
}

.layout-footer ul > li > a {
  color: rgba(41, 50, 65, 0.8);
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.layout-footer ul > li > a:hover {
  color: rgba(41, 50, 65, 0.5);
}

.layout-footer .newsletter-input {
  margin-top: 16px;
  background-color: #ffffff;
  position: relative;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
}

.layout-footer .newsletter-input > input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 11px 16px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 200%;
}

.layout-footer .newsletter-input > button {
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -16px;
}

.layout-footer .newsletter-input > button > span {
  display: block;
  padding: 0;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
}

.layout-footer .footer-bottom {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.layout-footer .footer-bottom h4 {
  line-height: 22px;
  margin: 0;
  margin-right: 32px;
}

.layout-footer .footer-bottom h6 {
  line-height: 17px;
  margin: 0;
  color: rgba(41, 50, 65, 0.5);
  font-weight: 500;
}

/* Utils */
.clearfix:after {
  content: " ";
  display: block;
  clear: both;
}

.card {
  background: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
  border-radius: 24px;
  margin-bottom: 2rem;
}

.card:last-child {
  margin-bottom: 0;
}

.card .card-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 16px;
}

.card .card-header h6 {
  margin-bottom: 2px;
}

.card .card-header .subtitle {
  font-weight: 600;
  color: rgba(41, 50, 65, 0.5);
}

.card .card-subtitle {
  color: rgba(41, 50, 65, 0.5);
  font-weight: 600;
  margin: -1rem 0 1rem 0;
}

.card.no-gutter {
  margin-bottom: 0;
}

.p-sr-only {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.p-text-secondary {
  color: rgba(41, 50, 65, 0.5);
}

.layout-wrapper .layout-ajax-loader {
  position: absolute;
  right: 15px;
  bottom: 70px;
}

.layout-wrapper .layout-ajax-loader .layout-ajax-loader-icon {
  color: red;
  font-size: 32px;
}

.layout-dashboard .chart {
  overflow: auto;
}

.layout-dashboard .mobile-teams {
  display: none;
}

@media (max-width: 1200px) {
  .layout-dashboard .desktop-teams {
    display: none;
  }

  .layout-dashboard .mobile-teams {
    display: block;
  }

  .layout-dashboard .mobile-teams .team {
    height: 100%;
    flex-direction: column;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .layout-dashboard .mobile-teams .team .peoples {
    margin: 12px -8px;
  }
}
.overview-box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 24px;
  height: 100%;
  min-width: 200px;
}

.overview-box .overview-info > h6 {
  margin: 0 0 2px;
}

.overview-box .overview-info > h1 {
  margin: 0;
}

.overview-box > i {
  font-size: 24px;
}

.overview-box.white {
  background: #FFFFFF;
  color: rgba(41, 50, 65, 0.8);
}

.overview-box.blue {
  background: #69B7FF;
  color: #FFFFFF;
}

.overview-box.gray {
  background: rgba(41, 50, 65, 0.4);
  color: #FFFFFF;
}

.overview-box.darkgray {
  background: rgba(41, 50, 65, 0.8);
  color: #FFFFFF;
}

.overview-box.orange {
  background: linear-gradient(90deg, #FFB340 0%, #FFA740 100%);
  color: #FFFFFF;
}

.timeline {
  padding-right: 4px;
}

.timeline > ul {
  padding: 0;
  margin: 0;
  list-style: none;
  max-height: 372px;
  overflow: auto;
  margin-bottom: 1em;
}

.timeline > ul > li {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 16px;
}

.timeline > ul > li > i {
  font-size: 8px;
  margin-right: 10px;
  margin-top: 4px;
}

.timeline > ul > li .event-content span {
  display: block;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 12px;
  color: rgba(41, 50, 65, 0.5);
}

.timeline > ul > li .event-content span.event-title {
  color: #3E4754;
}

.timeline > ul > li .event-content span.time {
  font-size: 10px;
  font-weight: 400;
  color: rgba(41, 50, 65, 0.5);
}

.timeline > ul > li.blue > i {
  color: #297FFF;
}

.timeline > ul > li.green > i {
  color: #34B56F;
}

.timeline > ul > li.orange > i {
  color: #FFA928;
}

.device-status .content {
  color: rgba(41, 50, 65, 0.5);
  line-height: 1.4;
  margin-bottom: 20px;
}

.device-status .progress {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
  color: rgba(41, 50, 65, 0.5);
}

.device-status .progress > span {
  min-width: 40px;
}

.device-status .progress p-progressBar {
  width: 100%;
  margin: 0 12px;
}

.device-status .progress .p-progressbar {
  background: rgba(41, 127, 255, 0.2);
  background: var(--primary-lighter-color);
}

.device-status .progress .p-progressbar .p-progressbar-value {
  background: rgba(41, 127, 255, 0.2);
  background: var(--primary-color);
  opacity: 0.8;
  border-radius: 24px;
}

.device-status .progress.active .p-progressbar {
  background: rgba(41, 127, 255, 0.2);
  background: var(--primary-lighter-color);
}

.device-status .progress.active .p-progressbar .p-progressbar-value {
  background: linear-gradient(270deg, #42BBFF 0%, #6129FF 100%);
  background: linear-gradient(270deg, var(--primary-lighter-color) 0%, var(--primary-color) 100%);
  opacity: 0.8;
}

.device-status .device {
  margin-bottom: 16px;
}

.device-status .device span {
  color: #2170E7;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
}

.device-status .device span > span {
  font-size: 8px;
  font-weight: normal;
}

.device-status .device span.status {
  font-size: 12px;
  color: rgba(41, 50, 65, 0.5);
  margin-top: 4px;
  display: block;
}

.team {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.team .card-header {
  padding: 0;
  min-width: 70px;
}

.team .peoples {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
}

.team .peoples > img {
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin: 8px 8px;
  width: 32px;
  height: 32px;
}

.team .peoples .no-picture {
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin: 8px 8px;
  width: 32px;
  height: 32px;
  background: rgba(41, 50, 65, 0.1);
  color: rgba(41, 50, 65, 0.8);
  font-size: 12px;
  -moz-transition: background 0.2s;
  -o-transition: background 0.2s;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}

.team .peoples .no-picture:hover {
  background: rgba(41, 50, 65, 0.2);
}

.map {
  padding: 0;
}

.map > img {
  width: 100%;
  height: auto;
  border-radius: 24px 24px 12px 12px;
}

.map .map-content {
  padding: 50px 20px 28px;
}

.map .map-content h6 {
  margin: 0 0 16px;
}

.map .map-content .city {
  margin-bottom: 16px;
}

.map .map-content .city span {
  color: #2170E7;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 600;
}

.map .map-content .city span > span {
  font-size: 8px;
  font-weight: normal;
}

.map .map-content .city span.status {
  font-size: 12px;
  color: rgba(41, 50, 65, 0.5);
  margin-top: 4px;
  display: block;
}

.schedule > p {
  color: rgba(41, 50, 65, 0.5);
}

.schedule > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.schedule > ul > li {
  background: #F7F7F8;
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 5px 16px 12px;
}

.schedule > ul > li .schedule-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
}

.schedule > ul > li .schedule-header h6 {
  line-height: 24px;
  margin: 0;
}

.schedule > ul > li .schedule-header span {
  color: rgba(41, 50, 65, 0.5);
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}

.schedule > ul > li > span {
  margin-top: 4px;
  color: rgba(41, 50, 65, 0.5);
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.statistics .statistic-item .item-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 4px;
}

.statistics .statistic-item .item-title span {
  display: block;
  margin-right: 12px;
}

.statistics .statistic-item .item-title h5 {
  margin: 0;
  font-weight: 700;
}

.statistics .statistic-item h6 {
  margin: 0;
  font-weight: 600;
  color: rgba(41, 50, 65, 0.5);
}

.stocks ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.stocks ul > li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #F7FAFF;
  padding: 0;
  margin: 0 0 12px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  overflow: hidden;
}

.stocks ul > li .stock-name {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #EEF5FF;
  padding: 18px 10px;
  min-width: 70px;
  margin-right: 4px;
}

.stocks ul > li .stock-name h6 {
  margin: 0;
  color: rgba(41, 50, 65, 0.8);
  line-height: 17px;
  font-weight: 600;
}

.stocks ul > li > img {
  margin: 0 4px;
  height: 25px;
}

.stocks ul > li .stock-price {
  padding: 0 10px;
  color: #34B56F;
  margin: 0 4px;
}

.stocks ul > li .stock-price h6 {
  line-height: 17px;
  font-weight: 600;
  display: inline-block;
}

.stocks ul > li .stock-price i {
  display: inline-block;
}

.stocks ul > li .stock-status {
  margin-left: 4px;
  padding: 0 20px;
}

.stocks ul > li .stock-status span {
  display: block;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: rgba(41, 50, 65, 0.5);
}

.stocks ul > li.down .stock-price {
  color: #FF6E49;
}

.stocks ul > li.same .stock-price {
  color: #FFA928;
}

.stocks > .p-button {
  width: 100%;
  margin-top: 30px;
}

.operations {
  overflow: auto;
}

.operations .insights {
  padding: 16px 15px;
  background-color: rgba(41, 127, 255, 0.04);
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  margin: 12px 0 16px;
}

.operations .insights .insight-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
}

.operations .insights .insight-header h6 {
  margin: 0 6px;
}

.operations .insights > ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.operations .insights > ul > li {
  margin: 8px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: rgba(41, 50, 65, 0.5);
}

.operations .insights > ul > li span {
  font-weight: 600;
}

.operations .insights > ul > li span > span {
  font-size: 8px;
  line-height: 10px;
  font-weight: normal;
}

.operations > button {
  width: 100%;
}

.notification {
  padding: 30px 24px;
  background-color: #ffffff;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.notification > h6 {
  margin: 0;
  color: rgba(41, 50, 65, 0.8);
}

.notification > h6 > a {
  margin-left: 10px;
}

.notification > h6 > a i {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.chat .p-tabview .p-tabview-nav {
  background-color: transparent;
  margin: 0 -10px;
  border: none;
}

.chat .p-tabview .p-tabview-nav:before {
  display: none;
}

.chat .p-tabview .p-tabview-nav li {
  padding: 9px 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  margin: 0 10px;
  border: none;
}

.chat .p-tabview .p-tabview-nav li a {
  position: relative;
  width: 52px;
  height: 52px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #D0D6DD;
  cursor: pointer;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  color: #2170E7;
  color: var(--primary-color);
  border: 0 none;
  overflow: visible;
}

.chat .p-tabview .p-tabview-nav li a img {
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.chat .p-tabview .p-tabview-nav li a .p-badge {
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.chat .p-tabview .p-tabview-nav li.p-highlight {
  padding: 0 0 9px;
  border: none;
}

.chat .p-tabview .p-tabview-nav li.p-highlight a {
  box-shadow: 0px 0px 1px rgba(41, 50, 65, 0.16), 0px 1px 2px rgba(41, 50, 65, 0.04), 0px 6px 12px rgba(41, 50, 65, 0.24);
  border: 0 none;
}

.chat .p-tabview .p-tabview-nav li.p-highlight a:before {
  content: "";
  width: 12px;
  height: 2px;
  background: #2170E7;
  background: var(--primary-color);
  border-radius: 3px;
  position: absolute;
  bottom: -10px;
}

.chat .p-tabview .p-tabview-nav li:hover {
  border: none;
  padding: 0 0 9px;
}

.chat .p-tabview .p-tabview-panels {
  background-color: transparent;
  border: none;
  padding: 16px 0 0;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel {
  padding: 0;
  height: 350px;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 350px;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .fade {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 44px;
  background-image: linear-gradient(180deg, #ffffff 0%, rgba(234, 237, 243, 0) 100%);
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content {
  max-height: 400px;
  overflow: auto;
  padding: 30px 6px 12px;
  flex: 1 1 auto;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content .chat-message {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content .chat-message .name {
  display: block;
  color: rgba(41, 50, 65, 0.5);
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content .chat-message .message {
  max-width: 250px;
  padding: 8px 10px;
  box-shadow: 0px 0px 1px rgba(41, 50, 65, 0.5), 0px 1px 1px rgba(41, 50, 65, 0.2);
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  background-color: #F7FAFF;
  margin-bottom: 8px;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content .chat-message .message p {
  padding: 0;
  margin: 0 0 2px;
  color: rgba(41, 50, 65, 0.8);
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content .chat-message .message span {
  display: block;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  color: rgba(41, 50, 65, 0.5);
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content .chat-message.send {
  -ms-flex-align: end;
  align-items: flex-end;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content .chat-message.send .message span {
  text-align: right;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content.no-message {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-content.no-message h4 {
  color: rgba(41, 50, 65, 0.5);
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .contacts {
  flex: 1 1 auto;
  max-height: 400px;
  overflow: auto;
  padding: 0px 0 12px;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .contacts ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .contacts ul > li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 6px;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
  padding: 8px 10px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .contacts ul > li img {
  margin-right: 12px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .contacts ul > li h6 {
  margin: 0 0 2px;
  color: rgba(41, 50, 65, 0.8);
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .contacts ul > li span {
  display: block;
  color: rgba(41, 50, 65, 0.5);
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .contacts ul > li:hover {
  background-color: #F7FAFF;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-input {
  margin-top: 30px;
}

.chat .p-tabview .p-tabview-panels .p-tabview-panel .chat .chat-input input {
  width: 100%;
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
  border-radius: 24px;
  padding: 20px 19px;
  background-color: #F7F7F8;
  border: none;
}

.image-card {
  padding: 0;
  position: relative;
}

.image-card > span {
  position: absolute;
  right: 20px;
  top: 20px;
}

.image-card > img {
  width: 100%;
  height: auto;
  border-radius: 24px 24px 12px 12px;
}

.image-card .image-content {
  padding: 32px 20px 28px;
}

.image-card .image-content h6 {
  margin: 0 0 8px;
}

.image-card .image-content > p {
  color: rgba(41, 50, 65, 0.5);
}

.image-card .image-content > button {
  margin-top: 32px;
  width: 100%;
}

.login-body {
  background: #FFFFFF;
}

.login-body .login-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
}

.login-body .login-wrapper .login-panel {
  width: 30%;
  height: 100%;
  text-align: center;
  padding: 40px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex: 1 1 100%;
}

.login-body .login-wrapper .login-panel .logo {
  margin-bottom: 50px;
}

.login-body .login-wrapper .login-panel .logo img {
  width: 45px;
  height: 53px;
}

.login-body .login-wrapper .login-panel > a {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: rgba(41, 50, 65, 0.3);
}

.login-body .login-wrapper .login-panel > p {
  font-weight: 500;
  margin: 0;
  color: rgba(41, 50, 65, 0.5);
  margin-top: 40px;
}

.login-body .login-wrapper .login-panel > p > a {
  color: #2170E7;
  cursor: pointer;
}

.login-body .login-wrapper .login-panel > input {
  width: 85%;
  max-width: 247px;
  margin-bottom: 10px;
  background-color: #F6F7F7;
  border: 1.2px solid #D4D6D9;
  color: #515C66;
  padding: 12px 10px;
}

.login-body .login-wrapper .login-panel > input::placeholder {
  color: gba(41, 50, 65, 0.3);
}

.login-body .login-wrapper .login-panel > button {
  width: 85%;
  max-width: 247px;
  margin-bottom: 10px;
  padding: 0;
}

.login-body .login-wrapper .login-panel > button > span {
  padding: 15px 20px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
}

.login-body .login-wrapper .login-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 70px;
}

.login-body .login-wrapper .login-footer h4 {
  line-height: 22px;
  margin: 0;
  margin-right: 32px;
}

.login-body .login-wrapper .login-footer h6 {
  line-height: 17px;
  margin: 0;
  color: rgba(41, 50, 65, 0.5);
  font-weight: 500;
}

@media (max-width: 992px) {
  .login-body .login-wrapper .login-panel {
    width: 100%;
  }
}
.exception-body .exception-topbar {
  height: 62px;
  background-color: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(41, 50, 65, 0.06);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 16px;
}

.exception-body .exception-topbar .layout-topbar-logo > img {
  height: 15px;
}

.exception-body .exception-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100vh - 62px);
}

.exception-body .exception-wrapper .exception-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  flex: 1 1 auto;
}

.exception-body .exception-wrapper .exception-content > span {
  font-weight: normal;
  font-size: 60px;
  line-height: 73px;
  text-align: center;
  display: block;
}

.exception-body .exception-wrapper .exception-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 70px;
}

.exception-body .exception-wrapper .exception-footer h4 {
  line-height: 22px;
  margin: 0;
  margin-right: 32px;
}

.exception-body .exception-wrapper .exception-footer h6 {
  line-height: 17px;
  margin: 0;
  color: rgba(41, 50, 65, 0.5);
  font-weight: 500;
}

.exception-body.notfound .exception-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100vh - 62px);
}

.exception-body.notfound .exception-wrapper .exception-content {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  flex: 1 1 auto;
}

.exception-body.notfound .exception-wrapper .exception-content img {
  width: 332px;
  height: 271px;
  margin-bottom: -150px;
}

.exception-body.notfound .exception-wrapper .exception-content > span {
  font-size: 140px;
  line-height: 171px;
}

.exception-body.notfound .exception-wrapper .exception-content > span.exception-subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(41, 50, 65, 0.5);
}

.exception-body.notfound .exception-wrapper .exception-content > button {
  padding: 0;
  margin-top: 20px;
  width: 155px;
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
}

.exception-body.notfound .exception-wrapper .exception-content > button > span {
  padding: 18px;
  font-weight: 600;
}

@media (max-width: 991px) {
  .exception-body .exception-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100vh - 62px);
  }

  .exception-body .exception-wrapper .exception-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 20px;
  }
}
.landing-body {
  background-color: #E5E5E5;
}

.landing-body .landing-topbar {
  height: 83px;
  background-color: #FFFFFF;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  z-index: 999;
  padding: 20px 40px;
  position: relative;
}

.landing-body .landing-topbar .landing-topbar-left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.landing-body .landing-topbar .landing-topbar-left .logo {
  margin-right: 40px;
}

.landing-body .landing-topbar .landing-topbar-left .logo img {
  height: 16px;
  width: auto;
}

.landing-body .landing-topbar .landing-topbar-left > ul {
  list-style-type: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.landing-body .landing-topbar .landing-topbar-left > ul > li #landing-menu-close {
  display: none;
}

.landing-body .landing-topbar .landing-topbar-left > ul > li > a {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: rgba(41, 50, 65, 0.9);
  padding: 14px 10px;
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
  border-radius: 24px;
  margin: 0 10px;
  min-width: 100px;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.landing-body .landing-topbar .landing-topbar-left > ul > li > a:hover {
  color: #2170E7;
}

.landing-body .landing-topbar .landing-topbar-right .second-menubutton {
  margin-right: 20px;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: rgba(41, 50, 65, 0.9);
  padding: 14px 10px;
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
  border-radius: 24px;
  min-width: 100px;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}

.landing-body .landing-topbar .landing-topbar-right .second-menubutton:hover {
  color: #2170E7;
}

.landing-body .landing-topbar .landing-topbar-right .landing-button span {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

.landing-body .landing-topbar .landing-topbar-right #landing-menu-button {
  display: none;
  padding: 0 8px;
  cursor: pointer;
}

.landing-body .landing-topbar .landing-topbar-right #landing-menu-button i {
  font-size: 20px;
}

.landing-body .landing-button {
  background: linear-gradient(108.43deg, #297FFF 12.5%, #7A0EE7 96.32%);
  border: none;
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
  border-radius: 24px;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.landing-body .landing-button.p-button {
  padding: 0;
}

.landing-body .landing-button.p-button > .p-button-text {
  padding: 14px 10px;
  min-width: 121px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: block;
}

.landing-body .landing-button > a .p-button-text {
  padding: 14px 10px;
  min-width: 87px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: block;
}

.landing-body .landing-button:hover {
  background: linear-gradient(108.43deg, #2f79e7 12.5%, #781cd4 96.32%);
}

.landing-body .landing-banner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 303px 30px 218px;
  position: relative;
  transform-style: inherit;
  background-size: cover;
  height: 80vh;
}

.landing-body .landing-banner .landing-banner-content {
  text-align: center;
  position: relative;
}

.landing-body .landing-banner .landing-banner-content .title {
  display: block;
  font-weight: 500;
  font-size: 70px;
  line-height: 84px;
  color: #FFFFFF;
}

.landing-body .landing-banner .landing-banner-content h3 {
  margin: 40px 0 30px;
  color: #FFFFFF;
  font-weight: 500;
  line-height: 29px;
}

.landing-body .section-header {
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
}

.landing-body .section-header .title {
  display: block;
  font-weight: 500;
  font-size: 70px;
  line-height: 84px;
  color: rgba(41, 50, 65, 0.9);
}

.landing-body .section-header h3 {
  margin: 15px 0 100px;
  color: rgba(41, 50, 65, 0.9);
  font-weight: 500;
  line-height: 29px;
  max-width: 800px;
}

.landing-body .landing-features {
  background-color: #FFFFFF;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  padding: 36px 6% 125px;
}

.landing-body .landing-features .p-lg-3 {
  transition: transform 250ms linear;
  -webkit-transition: transform 250ms linear;
}

.landing-body .landing-features .feature {
  display: -ms-flexbox;
  display: flex;
}

.landing-body .landing-features .feature > span {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: rgba(41, 50, 65, 0.8);
  margin-top: 30px;
  margin-right: 12px;
}

.landing-body .landing-features .feature .feature-card {
  -moz-border-radius: 36px;
  -webkit-border-radius: 36px;
  border-radius: 36px;
  padding: 28px 30px;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.landing-body .landing-features .feature .feature-card > span {
  display: none;
}

.landing-body .landing-features .feature .feature-card h3 {
  font-weight: 500;
  line-height: 36px;
  margin: 0 0 20px;
  color: rgba(41, 50, 65, 0.8);
}

.landing-body .landing-features .feature .feature-card h5 {
  margin: 0;
  font-weight: normal;
  line-height: 150%;
  color: rgba(41, 50, 65, 0.9);
  opacity: 0.8;
}

.landing-body .landing-features .feature.yellow .feature-card {
  padding-bottom: 128px;
  background: linear-gradient(197.55deg, #FFD37D -1.02%, #FFDB7D 46.53%);
}

.landing-body .landing-features .feature.blue .feature-card {
  padding-bottom: 67px;
  background: linear-gradient(156.18deg, #DAF4FF 38.02%, #CEDFFF 95.69%);
}

.landing-body .landing-features .feature.darker-blue .feature-card {
  padding-bottom: 164px;
  background: linear-gradient(165.84deg, #C1E9FF 42.24%, rgba(219, 242, 255, 0.23) 97.17%);
}

.landing-body .landing-features .feature.darker-gray .feature-card {
  padding-bottom: 109px;
  background: linear-gradient(176.91deg, rgba(41, 50, 65, 0.6) 50%, rgba(41, 50, 65, 0.282) 115.03%);
}

.landing-body .landing-features .feature.darker-gray .feature-card h3 {
  color: #FFFFFF;
}

.landing-body .landing-features .feature.darker-gray .feature-card h5 {
  color: #FFFFFF;
  opacity: 0.8;
}

.landing-body .landing-features .feature.gray .feature-card {
  padding-bottom: 50px;
  background: linear-gradient(11.49deg, rgba(41, 50, 65, 0.1) 60.37%, rgba(41, 50, 65, 0.026) 98.03%);
}

.landing-body .landing-pricing {
  background-color: #FFFFFF;
  position: relative;
  padding: 125px 15% 260px;
  text-align: center;
}

.landing-body .landing-pricing .pricing-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 1px rgba(41, 50, 65, 0.5), 0px 1px 1px rgba(41, 50, 65, 0.2);
  -moz-border-radius: 24px;
  -webkit-border-radius: 24px;
  border-radius: 24px;
  padding: 30px 20px 33px;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 60px;
}

.landing-body .landing-pricing .pricing-card .preferred-tag {
  padding: 14px 24px;
  background: linear-gradient(112.58deg, #FFD029 22.19%, #F1AF60 100%);
  box-shadow: 0px 10px 40px rgba(41, 50, 65, 0.06);
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px;
  transform: rotate(-7.18deg);
  position: absolute;
  top: -32px;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.landing-body .landing-pricing .pricing-card h2 {
  margin: 0 0 14px;
  color: rgba(41, 50, 65, 0.9);
}

.landing-body .landing-pricing .pricing-card .price {
  display: block;
  color: #2170E7;
  font-weight: bold;
  font-size: 80px;
  line-height: 95px;
}

.landing-body .landing-pricing .pricing-card .time {
  color: rgba(41, 50, 65, 0.5);
  font-size: 12px;
  line-height: 14px;
  display: block;
  margin-bottom: 32px;
}

.landing-body .landing-pricing .pricing-card > ul {
  padding: 42px 0 0;
  width: 100%;
  margin: 0;
  list-style: none;
  border-top: 1px solid rgba(41, 50, 65, 0.1);
}

.landing-body .landing-pricing .pricing-card > ul > li {
  font-size: 16px;
  line-height: 205.34%;
  color: rgba(41, 50, 65, 0.5);
}

.landing-body .landing-pricing .pricing-card.pro {
  background: linear-gradient(333.31deg, #EFF3FB 6.36%, #FFFFFF 72.79%);
}

.landing-body .landing-pricing .pricing-card.enterprise {
  background: linear-gradient(156.19deg, rgba(41, 50, 65, 0.8) 10.28%, rgba(35, 40, 49, 0.496) 87.74%);
}

.landing-body .landing-pricing .pricing-card.enterprise h2 {
  margin: 0 0 14px;
  color: #FFFFFF;
}

.landing-body .landing-pricing .pricing-card.enterprise .price {
  color: #FFFFFF;
}

.landing-body .landing-pricing .pricing-card.enterprise .time {
  color: #FFFFFF;
  opacity: 0.6;
}

.landing-body .landing-pricing .pricing-card.enterprise > ul {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.landing-body .landing-pricing .pricing-card.enterprise > ul > li {
  color: #FFFFFF;
}

.landing-body .landing-pricing > a {
  font-size: 24px;
  line-height: 29px;
  display: block;
}

.landing-body .layout-footer {
  background-color: #FFFFFF;
  position: relative;
}

.landing-body .layout-footer .footer-menutitle {
  color: rgba(41, 50, 65, 0.2);
}

.landing-body .layout-footer .footer-subtitle {
  color: rgba(41, 50, 65, 0.3);
}

.landing-body .layout-footer ul > li {
  color: rgba(41, 50, 65, 0.5);
}

.landing-body .layout-footer ul > li > a {
  color: rgba(41, 50, 65, 0.5);
}

.landing-body .layout-footer ul > li > a:hover {
  color: rgba(41, 50, 65, 0.3);
}

.landing-body .layout-footer .newsletter-input {
  background-color: rgba(41, 50, 65, 0.04);
}

.landing-body .layout-footer .footer-bottom {
  color: rgba(41, 50, 65, 0.7);
}

.landing-body .layout-footer .footer-bottom h6 {
  color: rgba(41, 50, 65, 0.5);
}

.landing-body .landing-mask {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 998;
}

@media (max-width: 991px) {
  .landing-body.block-scroll {
    overflow: hidden;
  }

  .landing-body.block-scroll .landing-wrapper .landing-mask {
    display: block;
  }

  .landing-body .landing-wrapper.landing-menu-active .landing-topbar .landing-menu {
    transform: translate3d(0px, 0px, 0px);
  }

  .landing-body .landing-wrapper .landing-topbar {
    padding: 0 13px;
  }

  .landing-body .landing-wrapper .landing-topbar .landing-menu {
    position: fixed;
    flex-direction: column;
    -ms-flex-align: end;
    align-items: flex-end;
    right: 0;
    top: 0;
    padding: 28px 15px;
    z-index: 999;
    width: 220px;
    height: 100%;
    background-color: #EEF5FF;
    box-shadow: 0 24px 64px -2px rgba(0, 0, 0, 0.02), 0 6px 16px -2px rgba(0, 0, 0, 0.06), 0 2px 6px -2px rgba(0, 0, 0, 0.08);
    transform: translate3d(260px, 0px, 0px);
    -moz-transition: transform 0.2s;
    -o-transition: transform 0.2s;
    -webkit-transition: transform 0.2s;
    transition: transform 0.2s;
  }

  .landing-body .landing-wrapper .landing-topbar .landing-menu > li {
    margin: 0;
    width: 100%;
    margin-bottom: 12px;
  }

  .landing-body .landing-wrapper .landing-topbar .landing-menu > li > a {
    padding: 6px 16px;
    font-size: 14px;
    text-align: right;
    background-color: #EEF5FF;
    display: block;
    color: rgba(41, 50, 65, 0.9);
  }

  .landing-body .landing-wrapper .landing-topbar .landing-menu > li > a:hover {
    color: rgba(41, 50, 65, 0.6);
  }

  .landing-body .landing-wrapper .landing-topbar .landing-menu > li #landing-menu-close {
    display: block;
    font-size: 20px;
    text-align: right;
    color: rgba(41, 50, 65, 0.9);
  }

  .landing-body .landing-wrapper .landing-topbar .landing-menu > li #landing-menu-close:hover {
    color: rgba(41, 50, 65, 0.6);
  }

  .landing-body .landing-wrapper .landing-topbar #landing-menu-button {
    display: block;
    color: rgba(41, 50, 65, 0.9);
    font-size: 20px;
  }

  .landing-body .landing-wrapper .landing-topbar .landing-topbar-right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }

  .landing-body .landing-wrapper .landing-topbar .landing-topbar-right .second-menubutton {
    display: none;
  }

  .landing-body .landing-wrapper .landing-topbar .landing-topbar-right .landing-button {
    margin-right: 20px;
  }

  .landing-body .landing-wrapper .landing-banner {
    background-position: top;
    padding: 80px 23px;
    -ms-flex-pack: start;
    justify-content: flex-start;
    height: auto;
    top: auto !important;
  }

  .landing-body .landing-wrapper .landing-banner .landing-banner-content {
    text-align: left;
    max-width: 262px;
    top: auto !important;
  }

  .landing-body .landing-wrapper .landing-banner .landing-banner-content > span {
    font-size: 60px;
    line-height: 91.84%;
  }

  .landing-body .landing-wrapper .landing-banner .landing-banner-content > h3 {
    font-size: 18px;
    line-height: 130%;
  }

  .landing-body .landing-wrapper .landing-features {
    padding: 36px 20px 30px;
  }

  .landing-body .landing-wrapper .landing-features .p-lg-3 {
    transform: translateY(0) !important;
    margin-top: auto !important;
  }

  .landing-body .landing-wrapper .landing-features .feature-empty {
    display: none;
  }

  .landing-body .landing-wrapper .landing-features .feature-3 {
    margin-top: auto;
  }

  .landing-body .landing-wrapper .landing-features .feature-4 {
    margin-top: auto;
  }

  .landing-body .landing-wrapper .landing-features .feature > span {
    display: none;
  }

  .landing-body .landing-wrapper .landing-features .feature .feature-card {
    padding-bottom: 28px !important;
  }

  .landing-body .landing-wrapper .landing-features .feature .feature-card > span {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: rgba(41, 50, 65, 0.8);
    margin-right: 12px;
    margin-top: 8px;
    display: block;
  }

  .landing-body .landing-wrapper .landing-features .feature.blue .feature-card {
    flex-direction: row-reverse;
    text-align: right;
  }

  .landing-body .landing-wrapper .landing-features .feature.blue .feature-card > span {
    margin-right: 0px;
    margin-left: 12px;
  }

  .landing-body .landing-wrapper .landing-features .feature.darker-gray .feature-card {
    flex-direction: row-reverse;
    text-align: right;
  }

  .landing-body .landing-wrapper .landing-features .feature.darker-gray .feature-card > span {
    color: #FFFFFF;
    float: right;
    margin-right: 0px;
    margin-left: 12px;
  }

  .landing-body .landing-wrapper .section-header .title {
    font-size: 60px;
    line-height: 72px;
  }

  .landing-body .landing-wrapper .section-header h3 {
    font-size: 18px;
    line-height: 130%;
  }

  .landing-body .landing-wrapper .landing-pricing {
    padding: 30px 20px 97px;
  }

  .landing-body .landing-wrapper .landing-pricing .pricing-card {
    margin-bottom: 20px;
  }

  .landing-body .landing-wrapper .landing-pricing .pricing-card > ul {
    display: none;
  }

  .landing-body .landing-wrapper .landing-pricing .preferred {
    order: -1 !important;
  }

  .landing-body .landing-wrapper .landing-pricing .preferred .pricing-card > ul {
    display: block;
  }
}
.help-page p {
  margin: 0;
}

.help-page .help-search {
  padding: 0;
  text-align: center;
}

.help-page .help-search .help-search-content {
  padding: 5rem 12rem;
}

.help-page .help-search .help-search-content h3 {
  color: rgba(41, 50, 65, 0.8);
  font-weight: 500;
}

.help-page .help-search .search-container {
  font-size: 1rem;
  padding: 1rem;
  position: relative;
}

.help-page .help-search .search-container input {
  appearance: none;
  font-size: 1rem;
  text-indent: 2rem;
  padding: 1rem;
  width: 100%;
}

.help-page .help-search .search-container i {
  width: 1rem;
  position: absolute;
  margin-left: 1rem;
  top: 50%;
  margin-top: -0.5rem;
}

.help-page .status-bars {
  margin-top: 1rem;
  display: -ms-flexbox;
  display: flex;
}

.help-page .status-bars .status-bar {
  flex: 1 1 0;
  -ms-flex: 1 1 0px;
  background: #6EC180;
  height: 50px;
  margin-right: 0.25rem;
  transition: transform 0.2s;
}

.help-page .status-bars .status-bar:last-child {
  margin-right: 0;
}

.help-page .status-bars .status-bar.status-bar-failure {
  background: #FF6E49;
}

.help-page .status-bars .status-bar:hover {
  transform: scale(1.1);
}

.help-page .status-bar-footer {
  padding: 1rem 0 0 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.help-page .blog-post {
  border-radius: 4px;
  padding: 20px;
  margin: 3rem 2rem;
  border: 1px solid #dee2e6;
  background-color: #ffffff;
  position: relative;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}

.help-page .blog-post:last-child {
  margin-bottom: 1rem;
}

.help-page .blog-post img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.help-page .blog-post .blog-text h1 {
  color: rgba(41, 50, 65, 0.8);
  margin-bottom: 1rem;
  font-weight: 500;
}

.help-page .blog-post .blog-text span {
  color: rgba(41, 50, 65, 0.5);
  line-height: 1.4;
}

.help-page .blog-post .blog-profile {
  position: absolute;
  top: -25px;
  left: -25px;
}

.help-page .blog-post .blog-profile img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

@media screen and (max-width: 991px) {
  .help-page .help-search .help-search-content {
    padding: 6rem 2rem;
  }
}
.invoice {
  padding: 2rem;
}

.invoice .invoice-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.invoice .invoice-company .logo-image {
  height: 50px;
  margin-bottom: 0.5rem;
}

.invoice .invoice-company div {
  margin-bottom: 0.5rem;
}

.invoice .invoice-company .company-name {
  font-weight: 500;
  font-size: 1.5rem;
}

.invoice .invoice-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: right;
  font-weight: 300;
}

.invoice .invoice-details {
  width: 15rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.invoice .invoice-details > div {
  width: 50%;
  margin-bottom: 0.5rem;
}

.invoice .invoice-details .invoice-label {
  text-align: left;
  font-weight: 500;
}

.invoice .invoice-details .invoice-value {
  text-align: right;
}

.invoice .invoice-to {
  margin-top: 1.5rem;
  padding-top: 2rem;
  border-top: 1px solid #F2F4F6;
}

.invoice .invoice-to .bill-to {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.invoice .invoice-to .invoice-to-info div {
  margin-bottom: 0.5rem;
}

.invoice .invoice-items {
  margin-top: 2rem;
  padding-top: 2rem;
}

.invoice .invoice-items table {
  width: 100%;
  border-collapse: collapse;
}

.invoice .invoice-items table tr {
  border-bottom: 1px solid #F2F4F6;
}

.invoice .invoice-items table th {
  font-weight: 500;
}

.invoice .invoice-items table th, .invoice .invoice-items table td {
  padding: 1rem;
  text-align: right;
}

.invoice .invoice-items table th:first-child, .invoice .invoice-items table td:first-child {
  text-align: left;
}

.invoice .invoice-summary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 2.5rem;
  padding-top: 2.5rem;
}

.invoice .invoice-summary .invoice-value {
  font-weight: 500;
}

@media print {
  body * {
    visibility: hidden;
  }

  #invoice-content * {
    visibility: visible;
  }

  #invoice-content {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    margin: 0;
  }

  .invoice {
    padding: 0;
    margin: 0;
    background: #ffffff;
    color: #424242;
  }
}
.layout-config {
  width: 16rem;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  padding: 1rem;
  overflow: auto;
  background: #ffffff;
  z-index: 999;
  border-left: 0 none;
  transform: translateX(100%);
  transition: transform 0.2s cubic-bezier(0.05, 0.74, 0.2, 0.99);
}

.layout-config.layout-config-active {
  transform: translateX(0);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.layout-config.layout-config-active .layout-config-content .layout-config-button i {
  transform: rotate(360deg);
}

.layout-config p {
  line-height: 1.5rem;
  color: #6c757d;
}

.layout-config .layout-themes {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.layout-config .layout-themes > div {
  padding: 0.25rem;
}

.layout-config .layout-themes a {
  width: 2rem;
  height: 2rem;
  border-radius: 24px;
  display: block;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: transform 0.2s;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.layout-config .layout-themes a i {
  font-size: 1rem;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.layout-config .layout-themes a:hover {
  transform: scale(1.1);
}

.layout-config-button {
  display: block;
  position: fixed;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  background: #1976D2;
  color: #ffffff;
  text-align: center;
  top: 50%;
  right: 0;
  margin-top: -1.5rem;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  transition: background-color 0.2s;
  overflow: hidden;
  cursor: pointer;
  z-index: 999;
  box-shadow: -0.25rem 0 1rem rgba(0, 0, 0, 0.15);
}

.layout-config-button i {
  font-size: 2rem;
  line-height: inherit;
  transform: rotate(0deg);
  transition: transform 1s;
}

.layout-config-button:hover {
  background: #2083e4;
}

/* Add your customizations of the layout styles here */
.layout-wrapper .layout-rightpanel .rightpanel-wrapper {
  position: relative;
  height: 100%;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-header {
  text-align: center;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-header .profile {
  padding: 12px;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-actions {
  padding: 12px 6px 36px;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-actions .actions .action-buttons .p-col-6, .layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-actions .actions .action-buttons .p-md-4 {
  padding: 0.2em;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-chat .ui-tabs .ui-tabs-nav {
  background-color: white;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-chat .ui-tabs .ui-tabs-nav li.ui-tabs-header {
  padding: 1rem;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-chat .ui-tabs .ui-tabs-nav li.ui-tabs-header a {
  font-size: 12px;
  font-weight: 500;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-chat .ui-tabs .ui-tabs-nav li.ui-tabs-header > span {
  font-size: 10px;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-chat .ui-tabs .ui-tabs-nav li.ui-tabs-header.ui-state-active {
  background-color: #F7FAFF;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-chat .ui-tabs .ui-tabs-panels {
  background-color: white;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-chat .ui-tabs .ui-tabs-panels .ui-tabs-panel {
  padding: 0;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-chat .ui-tabs .ui-tabs-panels .ui-tabs-panel .chat .chat-content .chat-message .message {
  width: 80%;
}

.layout-wrapper .layout-rightpanel .rightpanel-wrapper .rightpanel-chat .ui-tabs .ui-tabs-panels .ui-tabs-panel .chat .chat-input input {
  width: 105px;
  margin-right: 7px;
}

:root {
  --primaryColor: #4394D8;
  --secondaryColor: #97DDFF;
  --fontColor: #FFFFFF;
}

/* You can add custom css to this file */
.language-menu {
  position: absolute;
  right: 0px;
  padding: 10px;
}

.hidden {
  display: none;
}

.active {
  background-color: #58a7ec !important;
  color: white !important;
}

li.active {
  background-color: transparent !important;
}

.filtered {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

/* ------------------------------------------------------ */
/* General */
html,
body {
  background: #ffffff !important;
  font-size: 12px;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.mw-100 {
  max-width: 100% !important;
}

.d-flex {
  display: flex !important;
}

.d-none {
  display: none !important;
}

.d-table {
  display: table !important;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.grid {
  margin: 0;
}

.w-300 {
  width: 300px;
}

.w-260 {
  width: 260px !important;
}

.border-r-grey {
  border-right: 1px solid #cccccc;
}

.full-divider .p-divider {
  margin: 40px 0;
  width: 100vw;
  margin-left: -44px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

p {
  margin: 0 0 0.5rem 0;
}

* {
  font-family: "Poppins";
}

.rounded {
  border-radius: 0.33rem !important;
}

.top-65 {
  top: 65px !important;
}

.img-invert {
  filter: brightness(0) invert(1);
}

.shadow {
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.14) !important;
}

.version-label {
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 0.833rem;
}

.text-shadow {
  text-shadow: 0 0 1rem #fff, 0 0 1rem #fff !important;
}

.mb-9 {
  margin-bottom: 6rem !important;
}

/* Others */
.grid-nogutter > .col,
.grid-nogutter > [class*=col-] {
  padding: 0.5rem;
}

/* Text Color */
.text-greenlight {
  color: #43d8bd !important;
}

.text-blue {
  color: #4394d8 !important;
}

.text-magenta {
  color: #d8439c !important;
}

.text-orange {
  color: #ffb703 !important;
}

.text-green {
  color: #70af85 !important;
}

.text-purple {
  color: #a452e4 !important;
}

.text-softgrey {
  color: #aaaaaa !important;
}

.text-grey {
  color: #757575 !important;
}

.text-dark-blue {
  color: #084b83 !important;
}

/* Border Color */
.border-greenlight {
  border-color: #43d8bd !important;
}

.border-blue {
  border-color: #4394d8 !important;
}

.border-magenta {
  border-color: #d8439c !important;
}

.border-orange {
  border-color: #ffb703 !important;
}

.border-green {
  border-color: #70af85 !important;
}

.border-purple {
  border-color: #a452e4 !important;
}

/* Background Color */
.bg-softgrey {
  background-color: #f7f7f7 !important;
}

.bg-purple {
  background-color: #a452e4 !important;
  border-color: #a452e4 !important;
}

.bg-magenta {
  background-color: #d8439c !important;
  border-color: #d8439c !important;
}

.bg-green {
  background-color: #2ec1ac !important;
  border-color: #2ec1ac !important;
}

.bg-red {
  background-color: #e76e6e !important;
  border-color: #e76e6e !important;
}

.bg-blue {
  background-color: #3b9ef2 !important;
  border-color: #3b9ef2 !important;
}

.bg-softblue {
  background-color: #e3effa !important;
  border-color: #e3effa !important;
}

.bg-dark-blue {
  background-color: #084b83 !important;
  border-color: #084b83 !important;
}

/* Breadcrumb */
.p-breadcrumb {
  background: transparent;
}

/* Global Input */
.p-inputtext,
.p-inputtextarea,
.p-dropdown,
.p-multiselect {
  border-color: #e2e2e2;
  border-radius: 4px;
  font-family: "Poppins";
  width: 100%;
}
.p-inputtext[readonly=true],
.p-inputtextarea[readonly=true],
.p-dropdown[readonly=true],
.p-multiselect[readonly=true] {
  background: #ced4da;
  opacity: 0.6;
}
.p-inputtext[readonly=true] + label,
.p-inputtextarea[readonly=true] + label,
.p-dropdown[readonly=true] + label,
.p-multiselect[readonly=true] + label {
  opacity: 0.6;
}

::-webkit-input-placeholder {
  opacity: 0.5;
}

/* Checkbox */
.field-checkbox,
.field-radiobutton {
  margin-bottom: 0.5rem;
}

/* Color Picker */
.p-colorpicker-preview {
  width: 2.5rem;
  height: 2.5rem;
}

/* Calendar */
.p-calendar {
  position: relative;
  display: flex !important;
  width: auto;
}

/* Input Number */
.p-inputnumber {
  position: relative;
  display: flex !important;
}

.p-inputnumber,
p-inputnumber {
  display: flex !important;
}

.p-inputnumber-full-width {
  width: 100%;
}

/* Input Line */
input.p-inputtext.line-input {
  border: none !important;
  background: transparent;
  border-bottom: 1px solid #cccccc !important;
  border-radius: 0 !important;
}

/* Input Group */
.p-inputgroup .p-inputgroup-addon {
  background: #ffffff;
}
.p-inputgroup .p-inputgroup-addon:last-child {
  border: 1px solid #e2e2e2;
  border-left: none;
}
.p-inputgroup .p-inputmask {
  width: 100%;
}

/* Dropdown */
.p-dropdown.p-disabled {
  -webkit-user-select: unset;
  user-select: unset;
}
.p-dropdown.p-disabled .p-dropdown-clear-icon {
  display: none;
}

/* Checkbox */
.p-checkbox .p-checkbox-box {
  border-width: 1px;
}

/* Chips */
.p-chips .p-chips-multiple-container .p-chips-token {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  max-width: 100%;
  line-break: anywhere;
}

/* Button */
.p-button {
  padding: 0.5rem 1.5rem;
}
.p-button.p-button-warning {
  color: #ffffff !important;
}
.p-button.data-type-btn {
  width: 2.5rem !important;
  padding: 0.35rem !important;
  background-color: #e5e5e5 !important;
}

.p-button.p-button-secondary2,
.p-buttonset.p-button-secondary2 > .p-button,
.p-splitbutton.p-button-secondary2 > .p-button {
  color: #8a8a8a;
  background-color: #ffffff;
  border-color: #ffffff;
}
.p-button.p-button-secondary2:enabled:hover,
.p-buttonset.p-button-secondary2 > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary2 > .p-button:enabled:hover {
  background: #e5e5e5;
  color: #8a8a8a;
  border-color: #e5e5e5;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: #8a8a8a;
  background-color: #f7f7f7;
  border-color: #f7f7f7;
}
.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: #e5e5e5;
  color: #8a8a8a;
  border-color: #e5e5e5;
}

.p-button.btn-img-sqr {
  flex-direction: column;
  width: 7rem;
}
.p-button.btn-img-sqr img {
  width: auto;
  height: 2rem;
  margin: 5px;
}

.material-icons {
  font-family: "Material Icons" !important;
}

.icon-no-label.p-button-icon-only .p-button-label {
  display: none;
}

.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.5rem;
}

.p-button.p-button-icon-only.p-button-rounded {
  height: 3rem;
}

.btn-float {
  z-index: 1;
  position: fixed;
  bottom: 5.75rem;
  right: 1.5rem;
  width: 4rem !important;
  height: 4rem !important;
  box-shadow: 2px 2px 6px #888888;
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
  color: var(--fontColor) !important;
}
.btn-float.btn-back {
  left: 1.5rem;
}
.btn-float.btn-cart {
  border-radius: 20% 0 0 20% !important;
  right: 0;
}
.btn-float .pi {
  font-size: 1.5rem;
}

.btn-check-data {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.btn-free-plan {
  border-radius: 4rem;
  border: none;
  background: linear-gradient(200deg, #f24818 0%, #ffb703 100%);
}

.btn-upgrade-pro, .btn-upgrade-pro:enabled:hover, .btn-upgrade-pro:focus {
  border-radius: 4rem;
  border: none;
  background: linear-gradient(200deg, #63f1d8 0%, #3b9ef2 100%);
}

.btn-soon {
  border-radius: 4rem;
  border: none;
  background: linear-gradient(200deg, #c8c8c8 0%, #8a8a8a 100%);
}

.p-button-xl {
  height: 4.5rem !important;
  width: 4.5rem !important;
}

/* Select Button Default */
.p-selectbutton .p-button {
  background: #f4f7fa;
  border-color: #e2e2e2;
  color: #4e555b;
}
.p-selectbutton .p-button.p-highlight {
  background: #4394d8;
  border-color: #4394d8;
  color: #ffffff;
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #4394d8;
  border-color: #4394d8;
  color: #ffffff;
}
.p-selectbutton .p-button.p-highlight:hover {
  background: #4394d8;
  border-color: #4394d8;
  color: #ffffff;
}

/* Button Group / Select Button (eg. Order Summary) */
.option-button.p-selectbutton {
  display: flex;
}
.option-button.p-selectbutton .p-button {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  text-align: start;
  margin: 4px;
  padding: 1rem 1.5rem;
  border-radius: 4px !important;
  border: 1px solid #e2e2e2 !important;
}
.option-button.p-selectbutton .p-button .title {
  color: #444444;
  margin-bottom: 0;
  font-weight: 700;
}
.option-button.p-selectbutton .p-button .sub-title {
  color: #8A8A8A;
  font-size: 0.833rem;
}
.option-button.p-selectbutton .p-button .price {
  color: #444444;
  font-size: 1.167rem;
  font-weight: 700;
}
.option-button.p-selectbutton .p-button .price .p-tag {
  display: none;
}
.option-button.p-selectbutton .p-button .p-tag-danger {
  display: none;
}
.option-button.p-selectbutton .p-button.p-highlight, .option-button.p-selectbutton .p-button.p-highlight:hover, .option-button.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #E3EFFA;
  border-color: #4394d8 !important;
}
.option-button.p-selectbutton .p-button.p-highlight .title, .option-button.p-selectbutton .p-button.p-highlight:hover .title, .option-button.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .title {
  color: #4394d8;
}
.option-button.p-selectbutton .p-button.p-highlight .price .p-tag, .option-button.p-selectbutton .p-button.p-highlight:hover .price .p-tag, .option-button.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .price .p-tag {
  color: #444444;
  display: initial;
}
.option-button.p-selectbutton .p-button.p-highlight .price .p-tag + label, .option-button.p-selectbutton .p-button.p-highlight:hover .price .p-tag + label, .option-button.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .price .p-tag + label {
  display: none;
}
.option-button.p-selectbutton .p-button.p-highlight .p-tag-danger, .option-button.p-selectbutton .p-button.p-highlight:hover .p-tag-danger, .option-button.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-tag-danger {
  display: initial;
}
.option-button.p-selectbutton .p-button.p-highlight .p-tag-secondary2, .option-button.p-selectbutton .p-button.p-highlight:hover .p-tag-secondary2, .option-button.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-tag-secondary2 {
  display: none;
}
.option-button.p-selectbutton.w-100 .p-button {
  width: 100%;
}

/* Button Group / Select Button (eg. Question Form) */
.multi-select-button.p-selectbutton .p-button {
  border-radius: 4px !important;
  border: 1px solid #dadada !important;
  margin-right: 8px;
  margin-bottom: 8px;
}

/* Custom Google Button */
.abcRioButton {
  height: 50px;
  width: 100% !important;
  border-radius: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  -webkit-user-select: none;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  color: #757575;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  border-radius: 4px;
}

.abcRioButtonBlue {
  background-color: #4285f4;
  border: none;
  color: #fff;
}

.abcRioButtonBlue:hover {
  background-color: #4285f4;
}

.abcRioButton:hover {
  box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}

.abcRioButtonBlue .abcRioButtonContentWrapper {
  border: 1px solid transparent;
}

.abcRioButtonContentWrapper {
  height: 100%;
  width: 100%;
}

.abcRioButtonBlue .abcRioButtonIcon {
  background-color: #fff;
  border-radius: 1px;
}

.abcRioButtonIcon {
  float: left;
  padding: 15px;
}

.abcRioButtonContents {
  font-size: 16px;
  line-height: 48px;
  font-weight: 600;
  letter-spacing: 0.21px;
  margin-left: 6px;
  margin-right: 6px;
  vertical-align: top;
}

/* Card */
.card.card-static {
  border-radius: 6px;
  box-shadow: none;
}
.card.summary-card {
  background: left top/contain no-repeat url(/assets/images/icon/icon_red-circle.svg);
  background-size: 5.831rem 5.831rem;
  border: unset !important;
}
.card.summary-card img {
  background: #ffffff;
  border-radius: 100%;
  border: 0.0835rem solid #ffffff;
  height: 3.332rem;
}

/* Table */
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0;
}
.p-datatable .p-datatable-header {
  background: inherit !important;
  border: none;
  padding: 0;
}
.p-datatable .p-datatable-scrollable-header-box {
  padding-right: 0 !important;
}
.p-datatable .p-datatable-scrollable-body {
  overflow-y: unset !important;
}
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  border: 0.5px solid #dee2e6 !important;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  border: 1px solid #c8c8c8;
  border-width: 1px 1px 1px 1px;
  font-weight: 700;
  color: #333333;
  background: #f4f4f4 !important;
  transition: background-color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-loading-overlay {
  z-index: 99;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  margin: 0.5rem 0.33rem;
}

td {
  word-wrap: break-word;
}

thead > tr > th:first-child {
  width: 50px !important;
  max-width: 50px;
  justify-content: center;
  padding: 1rem 1rem;
}

tbody > tr > td:first-child {
  background: #f4f4f4;
}

tbody > tr > td:first-child {
  width: 50px !important;
  max-width: 50px;
  justify-content: center;
}

.editor.table-data {
  width: calc(100vw - 80px - 80px - 348px);
  max-width: 90vw;
}
.editor.table-data .p-datatable .p-datatable-wrapper {
  max-height: calc(100vh - 370px);
  border: 1px solid #dee2e6;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table {
  table-layout: auto;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr > th,
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > td {
  max-width: unset;
  min-width: 150px;
  border: 0.5px solid #dee2e6;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr > th:first-child,
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > td:first-child {
  max-width: 50px;
  min-width: 50px;
  text-align: center;
  justify-content: center;
  position: sticky;
  left: 0;
  z-index: 1;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr > th:first-child > *,
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > td:first-child > * {
  justify-content: center;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr > th.no-data,
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > td.no-data {
  max-width: unset !important;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr > th .p-inputtext, .editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr > th .p-inputtext:enabled:focus,
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > td .p-inputtext,
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > td .p-inputtext:enabled:focus {
  border: none;
  padding: 0 0.5rem;
  background: transparent;
  box-shadow: none;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead {
  z-index: 2;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr > th .header-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child {
  position: sticky;
  bottom: 0;
  left: 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  z-index: 1;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child td {
  background-color: #f7f7f7;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child td > * {
  z-index: 1;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child td .p-inputtext:enabled:focus,
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child td .p-inputtext.p-filled, .editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child td span.output, .editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child td span {
  display: flex;
  width: 100%;
  background-color: #f7f7f7;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child td::before {
  content: "Click here to add data";
  position: absolute;
  color: #c1c1c1;
  padding-left: 0.5rem;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child td:first-child {
  padding: 1.5rem;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child td:first-child p-tablecheckbox {
  display: none;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child td:first-child:before {
  content: "";
  font-family: "primeicons";
  font-size: 16px;
  opacity: 1;
  padding-left: 0;
  color: #8a8a8a;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody tr + tr:last-child td p-celleditor p-checkbox {
  background-color: #f7f7f7;
  width: 100%;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > th > * {
  width: 100%;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > td {
  padding: 0.5rem 1rem;
}
.editor.table-data .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > td > * {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 150px;
  height: 20px;
}
.editor.table-data .p-datatable .p-datatable-footer {
  background: #ffffff;
  border: none;
  padding: 0.5rem 1rem 0;
}
.editor.table-data .p-dropdown .p-dropdown-panel {
  top: -94px !important;
}

.p-tree {
  border: none;
  padding: 0;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  padding: 0;
}

.p-treetable table {
  table-layout: auto !important;
}
.p-treetable .p-treetable-header {
  background: transparent;
  border: none;
  text-align: right;
}
.p-treetable .p-treetable-wrapper {
  position: relative;
  overflow: auto;
  overflow-y: unset !important;
}
.p-treetable .p-treetable-wrapper .p-treetable-thead > tr > th,
.p-treetable .p-treetable-wrapper .p-treetable-tbody > tr > td {
  max-width: 200px;
  min-width: 200px;
  padding: 1rem 1rem;
}
.p-treetable .p-treetable-wrapper .p-treetable-thead > tr > th:first-child,
.p-treetable .p-treetable-wrapper .p-treetable-tbody > tr > td:first-child {
  max-width: 50px;
  min-width: 50px;
  text-align: center;
}

.menu-title {
  margin: 0;
  align-self: center;
  color: #084b83;
  font-weight: 400;
}

.menu-subtitle {
  color: #444444;
  font-weight: 300;
  margin-bottom: 20px;
}

/* Tag */
.p-tag {
  padding: 0.25rem 0.5rem;
}
.p-tag.p-tag-secondary {
  background-color: #e5e5e5;
  color: #8a8a8a;
}
.p-tag.p-tag-secondary2 {
  background-color: #64748b;
  color: #ffffff;
}
.p-tag.p-tag-success {
  background-color: #2ec1ac !important;
  color: #ffffff !important;
}
.p-tag.p-tag-warning {
  background-color: #ffb703 !important;
  color: #ffffff !important;
}
.p-tag.p-tag-danger {
  background-color: #DF542F !important;
  color: #ffffff !important;
}
.p-tag.p-tag-basic {
  background-color: #f7f7f7 !important;
  color: #8a8a8a !important;
  border: 1px solid #dbdcdc !important;
}
.p-tag.p-tag-pro {
  background-color: #e3effa !important;
  color: #4494d9 !important;
  border: 1px solid #95c1e9 !important;
}

/* Accordion */
.p-accordion .p-accordion-header .p-accordion-header-link,
.p-accordion .p-accordion-header.p-highlight .p-accordion-header-link {
  border-radius: 0 !important;
  padding: 1rem 1.25rem;
  border: none;
  border-bottom: 1px solid #c4c4c4 !important;
  background: #e5e5e5 !important;
  display: list-item;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon,
.p-accordion .p-accordion-header.p-highlight .p-accordion-header-link .p-accordion-toggle-icon {
  float: right;
  margin-top: 0.25rem;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-header-text,
.p-accordion .p-accordion-header.p-highlight .p-accordion-header-link .p-accordion-header-text {
  float: left;
}
.p-accordion .p-accordion-header .p-accordion-header-link .accordion-icon,
.p-accordion .p-accordion-header.p-highlight .p-accordion-header-link .accordion-icon {
  vertical-align: middle;
  margin-right: 10px;
  height: 16px;
  width: 16px;
}
.p-accordion .p-accordion-header:hover .p-accordion-header-link,
.p-accordion .p-accordion-header.p-highlight:hover .p-accordion-header-link {
  border: none !important;
  background: #e5e5e5 !important;
  border-bottom: 1px solid #c4c4c4 !important;
}
.p-accordion .p-accordion-tab .p-accordion-content {
  background: #f7f7f7;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #c4c4c4;
}

/* Accordion Custom */
.acc-usage.p-accordion {
  list-style: none;
}
.acc-usage.p-accordion .p-accordion-header .p-accordion-header-link {
  border: 1px solid #e1e1e1 !important;
  background-color: #ffffff !important;
  border-radius: 0.4rem !important;
  display: flex;
  width: 100%;
}
.acc-usage.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  float: left;
  margin-right: 1rem;
  display: none;
}
.acc-usage.p-accordion .p-accordion-header .p-accordion-header-link:focus {
  box-shadow: none;
}
.acc-usage.p-accordion .p-accordion-header.p-highlight .p-accordion-header-link, .acc-usage.p-accordion .p-accordion-header.p-highlight:hover .p-accordion-header-link {
  padding-bottom: 0 !important;
}
.acc-usage.p-accordion .p-accordion-header.p-highlight .p-accordion-toggle-icon, .acc-usage.p-accordion .p-accordion-header.p-highlight:hover .p-accordion-toggle-icon {
  padding-bottom: 1rem;
}
.acc-usage.p-accordion .p-accordion-header.p-highlight .list-item, .acc-usage.p-accordion .p-accordion-header.p-highlight:hover .list-item {
  padding-bottom: 1rem !important;
}
.acc-usage.p-accordion .p-accordion-header:hover .p-accordion-header-link {
  border: 1px solid #e1e1e1 !important;
  background-color: #ffffff !important;
}
.acc-usage.p-accordion .acc-btn-new .p-accordion-header .p-accordion-header-link {
  color: #4394d8 !important;
  background-color: #eff8fd !important;
  border: 1px dashed #4394d8 !important;
}
.acc-usage.p-accordion .acc-btn-new:hover .p-accordion-header .p-accordion-header-link {
  transition: all 0.2s;
  color: #4394d8 !important;
  background-color: #FFFFFF !important;
  border: 1px solid #4394d8 !important;
}
.acc-usage.p-accordion .p-accordion-content {
  background: #ffffff;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0 0 0.4rem 0.4rem !important;
  border-top: none !important;
  padding-left: 3.5rem;
  display: none;
}

/* List */
.list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  width: 100%;
}
.list-item img {
  align-self: center;
  width: 54px;
  height: 54px;
  min-width: 54px;
  margin-right: 1.5rem;
}
.list-item .list-detail {
  flex: 1 1 0;
  overflow: hidden;
  padding: 2px;
}
.list-item .list-detail .list-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.list-item .list-detail .list-desc {
  font-size: 0.875rem;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.list-item .list-action {
  display: flex;
  flex-direction: column;
}
.list-item ~ .list-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

/* Progress Bar */
.progress-sm.p-progressbar {
  height: 0.5rem;
}
.progress-sm.p-progressbar .p-progressbar-label {
  display: none !important;
}

/* Divider */
.p-divider {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
}
.p-divider:before {
  content: "";
  position: absolute;
  width: 100%;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px solid #dee2e6 !important;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px solid #dee2e6;
}
.p-divider .p-divider-content {
  z-index: 1;
}

/* Progress Spinner */
.progress-spinner-btn {
  filter: brightness(10);
  display: flex;
  width: 5px;
  height: 5px;
  position: absolute;
  top: calc(50% - 0.75rem);
  right: 10px;
}

/* Modal Pop Up Dialog */
.p-dialog {
  border: transparent;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 500;
  font-size: 1.5rem;
  color: #084b83;
  margin-left: 5px;
  display: flex;
  align-items: center;
}
.p-dialog .p-dialog-header .p-dialog-title i {
  margin-right: 10px;
  font-size: 1.5rem;
}
.p-dialog .p-dialog-header .p-dialog-icon {
  height: 20px;
  margin-right: 15px;
}
.p-dialog .p-dialog-content {
  color: #444444;
}
.p-dialog .p-dialog-footer {
  border-top: none;
  text-align: center;
  padding-bottom: 4rem;
}
.p-dialog .p-dialog-footer .p-button .p-button-icon {
  order: 2;
  margin-left: 0.5rem;
  margin-right: 0;
}
.p-dialog .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  text-align: center;
  width: 100%;
  margin-left: 0;
  padding: 20px 0;
}
.p-dialog.dialog-no-footer .p-dialog-content {
  border-radius: 0px 0px 4px 4px;
  padding: 1rem 2rem;
}
.p-dialog.footer-default .p-dialog-footer {
  border-top: 1px solid #e9ecef;
  padding: 1rem;
  text-align: right;
}
.p-dialog .modal-footer {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  background: #FFFFFF;
  width: 100%;
  padding: 1rem 2rem;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 1px solid #dee2e6;
}

p-dynamicdialog .p-dialog-mask {
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.15s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Toast Notification Message */
.p-toast {
  z-index: 2000;
}
.p-toast .p-toast-message .p-toast-icon-close {
  padding: 1rem;
}

/* Message */
.p-message.p-message-info {
  border: solid #004085;
  border-width: 0 0 0 6px;
}

/* Data View */
.p-dataview .p-dataview-header {
  background: transparent;
  border: none;
  padding: 1rem 0;
}
.p-dataview .p-paginator-bottom {
  border: none;
}

/* Header / Topbar */
.p-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  border-bottom: 1px solid #cccccc;
}
.p-header .p-menubar {
  background-color: #ffffff;
  padding: 0 1rem;
  height: 55px;
  border-radius: 0;
}
.p-header .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 1.7rem 1rem;
  background-color: transparent;
  border-radius: 0;
  border-bottom: 4px solid transparent;
}
.p-header .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link.p-menuitem-link-active, .p-header .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:hover {
  border-bottom: 4px solid #4394d8;
}
.p-header .p-menubar .p-menubar-end {
  display: flex;
}
.p-header .p-menubar .p-menubar-end ui-fibr-profile {
  display: flex;
  align-items: center;
}
.p-header .display-user {
  display: inline-flex;
  line-height: 25px;
  padding: 5px 0px;
}

.hide-menubar .p-menubar-button {
  display: none;
}

/* Sub Header / Sub Topbar */
.sub-header .p-toolbar.p-component {
  background: #f7f7f7;
  position: fixed;
  top: 56px;
  width: 100%;
  z-index: 3;
  border-radius: 0 !important;
  border: none !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  padding: 0.5rem 1.25rem;
  flex-flow: row;
  height: 54px;
}
.sub-header .p-toolbar-group-left {
  flex-flow: wrap;
}
.sub-header .p-toolbar-group-left .app-detail {
  display: flex;
  align-items: center;
}
.sub-header .p-toolbar-group-left .logo > img {
  vertical-align: middle;
  width: 40px;
}

/* Content */
.p-content {
  top: 110px;
  max-width: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 0;
}
.p-content.content-nosubheader {
  top: 55px;
}
.p-content.content-nosubheader .layout-main {
  height: calc(100vh - 98px);
}
.p-content .side-menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.p-content .center-content {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-height: 200px;
  width: 0;
  min-width: 200px;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
.p-content .layout-main {
  margin: 0;
  padding: 0;
  overflow: auto;
  min-height: 200px;
  height: calc(100vh - 154px);
}
.p-content .layout-main.full {
  max-width: calc(100vw);
}
.p-content .layout-app {
  display: flex;
  height: 100%;
  width: 100vw;
  overflow-x: auto;
  flex-direction: column;
}
.p-content .layout-app .app-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.p-content .layout-app .data-content,
.p-content .layout-app .preview-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.p-content .layout-app .data-table,
.p-content .layout-app .preview-app {
  overflow: overlay;
  height: calc(100vh - 154px);
  display: block;
}

/* Footer */
.footer .p-footer {
  position: fixed;
  bottom: 0px;
  border-top: 1px solid #cccccc;
  background-color: #ffffff;
  padding: 1rem 1.25rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

/* Vertical Menu */
.p-vmenu p-tabmenu .p-tabmenu-nav {
  width: 80px;
  height: calc(100vh - 153px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #f7f7f7;
  overflow-y: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  transition: width 0.3s;
}
.p-vmenu p-tabmenu .p-tabmenu-nav .p-tabmenuitem > .p-menuitem-link {
  border: none;
  border-radius: 0;
  justify-content: center;
  height: 62px;
  flex-direction: column;
  color: #808080;
  background-color: #f7f7f7;
  text-align: center;
  padding: 10px;
}
.p-vmenu p-tabmenu .p-tabmenu-nav .p-tabmenuitem > .p-menuitem-link .p-menuitem-icon {
  margin-right: 0;
  font-size: 20px;
  margin-bottom: 5px;
}
.p-vmenu p-tabmenu .p-tabmenu-nav .p-tabmenuitem > .p-menuitem-link .p-menuitem-text {
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 1.25;
}
.p-vmenu p-tabmenu .p-tabmenu-nav .p-tabmenuitem > .p-menuitem-link .p-menuitem-img {
  position: absolute;
  bottom: 60%;
  right: 34%;
  background: #f7f7f7;
  height: 14px;
  width: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.p-vmenu p-tabmenu .p-tabmenu-nav .p-tabmenuitem:nth-child(2) > .p-menuitem-link .p-menuitem-text {
  white-space: unset;
}
.p-vmenu p-tabmenu .p-tabmenu-nav .p-tabmenuitem:hover > .p-menuitem-link, .p-vmenu p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight > .p-menuitem-link {
  background-color: #ffffff !important;
  color: #4394d8 !important;
  z-index: auto;
}
.p-vmenu p-tabmenu .p-tabmenu-nav .p-tabmenuitem:hover > .p-menuitem-link .p-menuitem-img, .p-vmenu p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight > .p-menuitem-link .p-menuitem-img {
  background: #ffffff;
}
.p-vmenu p-tabmenu.editor .p-tabmenu-nav .p-tabmenuitem:first-child {
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}
.p-vmenu p-tabmenu.editor .p-tabmenu-nav .p-tabmenuitem:first-child > .p-menuitem-link {
  display: flex;
}
.p-vmenu p-tabmenu.editor .p-tabmenu-nav .p-tabmenuitem:first-child > .p-menuitem-link .p-menuitem-text {
  font-size: 12px;
}
.p-vmenu p-tabmenu.editor .p-tabmenu-nav .p-tabmenuitem:first-child > .p-menuitem-link .p-menuitem-img {
  display: none;
}
.p-vmenu p-tabmenu.editor .p-tabmenu-nav .p-tabmenuitem:first-child:hover > .p-menuitem-link {
  color: #808080 !important;
  background-color: #f7f7f7 !important;
  cursor: text !important;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem:nth-child(2) {
  position: sticky;
  top: 5.1rem;
  z-index: 1;
  background: #f7f7f7;
  border-bottom: 1px solid #dee2e6;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem:nth-child(2) > .p-menuitem-link .p-menuitem-img {
  display: none;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem.add-button > .p-menuitem-link {
  display: flex;
  border: 2px dotted #cccccc !important;
  margin: 8px;
  padding: 4px;
  border-radius: 4px !important;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem.add-button > .p-menuitem-link .p-menuitem-img {
  display: none;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem:nth-last-child(1 of .sticky-table) {
  bottom: 0;
  margin-top: auto;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem:nth-last-child(2 of .sticky-table) {
  bottom: 63px;
  margin-top: auto;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem:nth-last-child(2 of .sticky-table) + .sticky-table {
  margin-top: unset;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem:nth-last-child(3 of .sticky-table) {
  bottom: 126px;
  margin-top: auto;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem:nth-last-child(3 of .sticky-table) + .sticky-table {
  margin-top: unset;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem.sticky-table {
  position: sticky;
  border-top: 1px solid #dee2e6;
  z-index: 1;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem.sticky-table > .p-menuitem-link {
  border: none !important;
  margin: 0;
  width: 100%;
  border-radius: 0 !important;
  background: #eeeeee;
}
.p-vmenu p-tabmenu.editor.editor-advance .p-tabmenu-nav li.p-tabmenuitem.sticky-table > .p-menuitem-link .p-menuitem-img {
  display: none;
}
.p-vmenu p-orderList .p-orderlist-list {
  width: 80px;
  height: calc(100vh - 297px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #f7f7f7;
  overflow-y: auto;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  transition: width 0.3s;
  padding: 0;
  border: 0;
  border-radius: 0;
}
.p-vmenu p-orderList .p-orderlist-list .p-orderlist-item {
  display: unset;
  overflow: unset;
  padding: 0;
  font-weight: 600;
  position: unset;
}
.p-vmenu p-orderList .p-orderlist-list .p-orderlist-item > .p-menuitem-link {
  display: flex;
  border: none;
  border-radius: 0;
  justify-content: center;
  height: 62px;
  flex-direction: column;
  color: #808080;
  background-color: #f7f7f7;
  text-align: center;
  padding: 10px;
  align-items: center;
  position: relative;
}
.p-vmenu p-orderList .p-orderlist-list .p-orderlist-item > .p-menuitem-link .p-menuitem-icon {
  margin-right: 0;
  font-size: 20px;
  margin-bottom: 5px;
}
.p-vmenu p-orderList .p-orderlist-list .p-orderlist-item > .p-menuitem-link .p-menuitem-text {
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 2;
}
.p-vmenu p-orderList .p-orderlist-list .p-orderlist-item > .p-menuitem-link:before {
  content: "";
  font-family: "primeicons";
  font-size: 10px;
  color: #CCCCCC;
  position: absolute;
  top: 24%;
  left: 10%;
  transform: rotate(90deg);
}
.p-vmenu p-orderList .p-orderlist-list .p-orderlist-item:hover > .p-menuitem-link, .p-vmenu p-orderList .p-orderlist-list .p-orderlist-item.p-highlight > .p-menuitem-link {
  background-color: #ffffff !important;
  color: #4394d8 !important;
  z-index: auto;
}
.p-vmenu p-orderList .p-orderlist-list .p-orderlist-item .active-page {
  background-color: #ffffff !important;
  color: #4394d8 !important;
  z-index: auto;
}
.p-vmenu p-orderList .static-menu .p-orderlist-list {
  height: auto;
}
.p-vmenu p-orderList .static-menu .p-orderlist-list .p-orderlist-item:first-child {
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 0;
  cursor: text;
}
.p-vmenu p-orderList .static-menu .p-orderlist-list .p-orderlist-item:first-child > .p-menuitem-link {
  display: flex;
}
.p-vmenu p-orderList .static-menu .p-orderlist-list .p-orderlist-item:first-child > .p-menuitem-link .p-menuitem-text {
  font-size: 12px;
}
.p-vmenu p-orderList .static-menu .p-orderlist-list .p-orderlist-item:first-child > .p-menuitem-link:before {
  display: none;
}
.p-vmenu p-orderList .static-menu .p-orderlist-list .p-orderlist-item:first-child:hover > .p-menuitem-link, .p-vmenu p-orderList .static-menu .p-orderlist-list .p-orderlist-item:first-child.p-highlight > .p-menuitem-link {
  color: #808080 !important;
  background-color: #f7f7f7 !important;
  cursor: text !important;
}
.p-vmenu p-orderList .static-menu .p-orderlist-list .p-orderlist-item:nth-child(2) {
  position: sticky;
  top: 5.15rem;
  z-index: 1;
  background: #f7f7f7;
  border-bottom: 1px solid #dee2e6;
}
.p-vmenu p-orderList .static-menu .p-orderlist-list .p-orderlist-item:nth-child(2):hover {
  background: transparent !important;
}
.p-vmenu p-orderList .static-menu .p-orderlist-list .p-orderlist-item:nth-child(2) > .p-menuitem-link {
  display: flex;
  border: 2px dotted #cccccc !important;
  margin: 8px;
  padding: 4px;
  border-radius: 4px !important;
  z-index: inherit;
}
.p-vmenu p-orderList .static-menu .p-orderlist-list .p-orderlist-item:nth-child(2) > .p-menuitem-link:before {
  display: none;
}
.p-vmenu p-orderList .static-menu .p-orderlist-list .p-orderlist-item:nth-child(2) > .p-menuitem-link .p-menuitem-text {
  white-space: unset;
  line-height: 1.25;
}
.p-vmenu p-orderList .p-orderlist-controls, .p-vmenu p-orderList .p-orderlist-header {
  display: none;
}

.p-orderlist-item {
  display: unset;
  overflow: unset;
  padding: 0;
  font-weight: 600;
}
.p-orderlist-item > .p-menuitem-link {
  display: flex;
  border: none;
  border-radius: 0;
  justify-content: center;
  height: 62px;
  flex-direction: column;
  background-color: #ffffff;
  color: #4394d8;
  text-align: center;
  padding: 10px;
  align-items: center;
}
.p-orderlist-item > .p-menuitem-link .p-menuitem-icon {
  margin-right: 0;
  font-size: 20px;
  margin-bottom: 5px;
}
.p-orderlist-item > .p-menuitem-link .p-menuitem-text {
  font-size: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 2;
}

/* Dropdown Menu */
.p-menuitem.menu-blue .p-menuitem-link .p-menuitem-icon,
.p-menuitem.menu-blue .p-menuitem-link .p-menuitem-text {
  color: #4394d8 !important;
}
.p-menuitem.menu-magenta .p-menuitem-link .p-menuitem-icon,
.p-menuitem.menu-magenta .p-menuitem-link .p-menuitem-text {
  color: #d8439c !important;
}
.p-menuitem.menu-green .p-menuitem-link .p-menuitem-icon,
.p-menuitem.menu-green .p-menuitem-link .p-menuitem-text {
  color: #56d5e1 !important;
}
.p-menuitem.menu-static span {
  display: flex;
  align-items: center;
}

/* Login & Register*/
.login-body {
  display: flex;
  height: 100vh;
}
.login-body .login-content {
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
}
.login-body .login-content .login-panel {
  width: 30%;
  height: 100%;
  overflow: auto;
  padding: 3.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #f7f7f7 !important;
  align-items: initial;
}
.login-body .login-content .login-panel .login-panel-content {
  width: 100%;
  padding: 0;
}
.login-body .login-content .login-panel .login-panel-content .login-header {
  margin-bottom: 3rem;
}
.login-body .login-content .login-panel .login-panel-content .login-header .logo {
  justify-content: unset;
  margin-bottom: 1.75rem;
}
.login-body .login-content .login-panel .login-panel-content .login-header .logo img {
  width: auto;
  height: 30px;
}
.login-body .login-content .login-panel.right {
  width: 70%;
  align-items: unset;
  padding: 7rem 5rem 2rem;
  background-color: #ffffff !important;
}
.login-body .login-content .login-panel.right .login-panel-content {
  display: flex;
  flex-direction: column;
}
.login-body .login-content .login-panel.right .login-panel-content h4 {
  font-size: 2.5rem;
  color: #084b83;
}
.login-body .login-content .login-panel.right .login-panel-content p {
  font-size: 1rem;
  color: #084b83 !important;
}
.login-body .login-content .login-panel.right .login-panel-content .logo img {
  height: 25px;
  width: auto;
}
.login-body .login-content .login-panel-sifa {
  width: 50%;
  height: 100%;
  overflow: auto;
  padding: 3.5rem 10rem;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #f7f7f7 !important;
  align-items: initial;
}
.login-body .login-content .login-panel-sifa .login-panel-content {
  width: 100%;
  padding: 0;
}
.login-body .login-content .login-panel-sifa .login-panel-content .login-header {
  margin-bottom: 3rem;
}
.login-body .login-content .login-panel-sifa .login-panel-content .login-header .logo {
  justify-content: unset;
  margin-bottom: 1.75rem;
}
.login-body .login-content .login-panel-sifa .login-panel-content .login-header .logo img {
  width: auto;
  height: 40px;
}
.login-body .login-content .login-panel-sifa .login-panel-content i {
  text-align: right;
  float: right;
  padding-left: 5px;
  padding-right: 5px;
  right: 10px;
  font-size: 16px;
  color: #8a8a8a;
  margin-top: -30px;
}
.login-body .login-content .login-panel-sifa.right {
  width: 50%;
  align-items: unset;
  padding: 10rem;
  background-color: #ffffff !important;
  color: #ffffff !important;
}
.login-body .login-content .login-panel-sifa.right .logo img {
  height: 25px;
  width: auto;
}
.login-body .login-content .login-panel-sifa.right .login-panel-content {
  display: flex;
  flex-direction: column;
}

/* Dashboard Chart */
.card.box {
  border: 1px solid #e1e1e1;
  border-radius: 6px !important;
  box-shadow: none;
  padding: 0.833rem;
  min-width: auto;
}
.card.box .card-header {
  border-bottom: 1px solid #c4c4c4;
  padding: 0;
  margin-bottom: 30px;
}
.card.box .card-header .card-title h3 {
  color: #084b83;
  font-weight: 300;
  margin-bottom: 20px;
  margin-left: 20px;
}
.card.box.overview-box h1 {
  font-weight: 400;
  font-size: 32px;
}
.card.box.overview-box h2 {
  color: #084b83;
  font-weight: 400;
  font-size: 20px;
}
.card.box.overview-box h6 {
  color: #8a8a8a;
  font-weight: 400;
}
.card.box.overview-box > div + * {
  text-align: right;
}
.card.box.overview-box .img-cont {
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  margin-right: 20px;
  height: 50px;
  width: 50px;
}
.card.box.overview-box .title-cont {
  font-weight: 300;
  color: #444444;
}

/* Project Grid */
.project-dataview .p-dataview-content-na {
  background: center top/contain no-repeat url(/assets/images/img_project-list_new.svg);
  height: 550px;
  background-position: center;
}

.project-grid-item {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 0px 15px;
  background: #ffffff;
  cursor: pointer;
}
.project-grid-item .item-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 23px;
}

/* Support Grid */
.support-dataview {
  background: bottom right/contain no-repeat url(/assets/images/img_support.svg);
}

.billing-display {
  margin-top: 4vh;
}

.billing-table .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr > th:last-child,
.billing-table .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > td:last-child {
  position: sticky;
  right: 0;
  background-color: #FFFFFF;
}
.billing-table .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-thead > tr > th:nth-last-child(2),
.billing-table .p-datatable .p-datatable-wrapper .p-datatable-table .p-datatable-tbody > tr > td:nth-last-child(2) {
  position: sticky;
  right: 116px;
  background-color: #FFFFFF;
  box-shadow: -3px 0px 3px rgba(0, 0, 0, 0.1);
}

/* Sidebar Page Option */
.page-option.p-sidebar {
  width: 35rem;
  height: calc(100vh - 209px);
  position: fixed;
  right: calc(80px + 312px);
  padding: 0;
  background: #efefef;
  border: none;
  top: 110px;
  border-right: 1px solid #dee2e6;
  z-index: 2 !important;
}
.page-option.p-sidebar .p-sidebar-header {
  display: none;
}
.page-option.p-sidebar .p-sidebar-content {
  padding: 0;
}
.page-option.p-sidebar + .p-sidebar {
  right: calc(80px + 312px + 35rem);
  z-index: 4 !important;
}
.page-option.p-sidebar + .p-sidebar .p-card .p-card-header {
  box-shadow: inset 0 6px 9px -6px rgba(0, 0, 0, 0.25), 0px 2px 8px rgba(0, 0, 0, 0.05);
}
.page-option.p-sidebar + .p-component-overlay.p-sidebar-mask {
  background: transparent !important;
  z-index: 3 !important;
}

.page-option .p-card {
  box-shadow: none;
  background: #efefef;
  border-radius: 0;
}
.page-option .p-card .p-card-header {
  padding: 1rem;
  position: sticky;
  top: 0;
  z-index: 3;
  background: #efefef;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
}
.page-option .p-card .p-card-header .page-header {
  display: flex;
  align-items: center;
}
.page-option .p-card .p-card-header .page-header .title-text {
  font-size: 1.333rem;
  font-weight: 600;
  color: #084b83;
  margin-right: 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 20rem;
}
.page-option .p-card .p-card-header .right-action {
  float: right;
}
.page-option .p-card .p-card-body {
  padding: 0;
}
.page-option .p-card .p-card-body .p-card-content {
  padding-top: 0;
  padding-bottom: 68px;
}
.page-option .p-card .p-card-body .p-card-footer {
  bottom: 44px;
  position: fixed;
  background: #e5e5e5;
  width: 35em;
  padding: 1rem;
  text-align: center;
  z-index: 2;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
}
.page-option .p-card.card-more {
  border-radius: 6px;
}
.page-option .p-card.card-more .p-card-body .p-card-content {
  padding: 10px;
}
.page-option.hide-header .p-card-content {
  padding: 0 !important;
}
.page-option.hide-header .p-card-content .grid.mt-2,
.page-option.hide-header .p-card-content p-divider {
  display: none;
}

.p-card.card-grid {
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
}
.p-card.card-grid .p-card-header {
  position: relative;
  z-index: auto;
  top: unset;
  background: transparent;
  padding: 0;
  height: 150px;
}
.p-card.card-grid .p-card-header img {
  border-radius: 10px 10px 0 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  text-align: center;
  overflow: hidden;
  background: #eff8fe;
}
.p-card.card-grid .p-card-title {
  font-size: 14px;
  font-weight: 500;
  color: #444;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.icon-pack {
  background: #efefef;
  margin: 8px;
  border-radius: 8px;
  padding: 8px;
}
.icon-pack .icon-preview {
  height: 80px;
  width: 80px;
  background-color: #f7f7f7;
}
.icon-pack .icon-list .p-scrollpanel .p-scrollpanel-content {
  height: 90px;
  width: 100%;
  padding: 0;
}
.icon-pack .icon-list .p-scrollpanel .p-scrollpanel-content a {
  color: #444444;
}

.comp-temp .comp-list .comp-icon {
  background: #fff;
  padding: 0.833rem;
  border-radius: 0.833rem;
}
.comp-temp .comp-list .comp-icon.filtered {
  background: #efefef;
}
.comp-temp .comp-list:hover .comp-icon {
  background: #fff;
}
.comp-temp .comp-list:hover .filtered {
  filter: none;
}

.label-info {
  display: flex;
  align-items: center;
}
.label-info i {
  margin-left: 6px;
}

.overlay-info {
  background: #ffffff;
  border: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  max-width: 250px;
  border-radius: 0.833rem;
}
.overlay-info .p-overlaypanel-content {
  padding: 0;
}
.overlay-info::before, .overlay-info::after {
  display: none;
}
.overlay-info .p-card {
  box-shadow: none;
  border-radius: 0.833rem;
  background-color: #f9f9f9;
}
.overlay-info .p-card img {
  border-start-start-radius: 0.833rem;
  border-start-end-radius: 0.833rem;
}
.overlay-info .p-card .p-card-title {
  font-size: 1.3rem;
  font-weight: 500;
  color: #444444;
}
.overlay-info .p-card .p-card-body {
  padding-top: 0.6rem;
}
.overlay-info.overlay-dropdown {
  padding: 1rem;
  width: 170px;
  max-height: 300px;
  overflow: auto;
}

/* Mobile: General */
/* Mobile: Phone Screen */
.smartphone,
.iphone {
  position: relative;
  width: 22rem;
  height: 42rem;
  margin: auto;
  border: 1rem #e5e5e5 solid;
  border-radius: 3.332rem;
  background: #ffffff;
}
.smartphone .content,
.iphone .content {
  background: #FFFFFF;
  width: 100%;
  overflow: auto;
  height: 100%;
  border-radius: 2.32rem;
}
.smartphone .content::-webkit-scrollbar,
.iphone .content::-webkit-scrollbar {
  display: none;
}

/* Android */
.smartphone:before {
  width: 2rem;
}

/* Iphone */
.iphone:before {
  width: 10rem;
}

/* banner */
.banner {
  position: relative;
  width: 40rem;
  height: 42rem;
  margin: auto;
  border: 0.1rem #e5e5e5 solid;
  border-radius: 0.432rem;
  background: #ffffff;
}
.banner .content {
  background: #f7f7f7;
  width: 100%;
  overflow: auto;
  height: 100%;
}
.banner .content::-webkit-scrollbar {
  display: none;
}

/* Mobile: Tab Preview Switch */
.tab-preview .p-tabmenu.p-component .p-tabmenu-nav {
  border-radius: 10px;
  border: 1px solid transparent !important;
  background: #f7f7f7;
  padding: 6px 4px;
}
.tab-preview .p-tabmenu.p-component .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: none;
  border-radius: 6px;
  margin: 0 2px;
  color: #c4c4c4;
  padding: 10px;
  background: inherit;
}
.tab-preview .p-tabmenu.p-component .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0;
}
.tab-preview .p-tabmenu.p-component .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: #8a8a8a;
  background: #ffffff;
}
.tab-preview .p-tabmenu.p-component .p-tabmenu-nav a:focus {
  z-index: 0;
}

/* Mobile: Header Page */
.mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  height: 3.7485rem;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.05);
  vertical-align: middle;
  background: var(--primaryColor);
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
.mobile-header .left-comp {
  display: flex;
  max-width: 15.5rem;
  align-items: baseline;
}
.mobile-header .left-comp.detail-width {
  max-width: 13.5rem;
}
.mobile-header .left-comp .title-page {
  margin-bottom: 0;
  font-size: 1.333rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--fontColor);
}
.mobile-header .right-comp {
  display: flex;
  justify-content: right;
  position: absolute;
  right: 0.5rem;
}
.mobile-header .p-button-icon-only {
  max-width: fit-content;
  max-height: fit-content;
  padding: 0;
  margin: 0.35rem;
  color: var(--fontColor) !important;
}
.mobile-header .p-button-icon-only:hover {
  background-color: transparent !important;
}
.mobile-header .p-button-icon-only:focus {
  box-shadow: unset !important;
}

/* Admin: Header Banner */
.banner-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  height: 3.7485rem;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.05);
  vertical-align: middle;
  background: var(--primaryColor);
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0 1rem;
}
.banner-header .left-comp {
  display: flex;
  max-width: 15.5rem;
  align-items: baseline;
}
.banner-header .left-comp.detail-width {
  max-width: 13.5rem;
}
.banner-header .left-comp .title-page {
  margin-bottom: 0;
  font-size: 1.333rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--fontColor);
}
.banner-header .right-comp {
  display: flex;
  justify-content: right;
  position: absolute;
  right: 0.5rem;
}
.banner-header .p-button-icon-only {
  max-width: fit-content;
  max-height: fit-content;
  padding: 1rem;
  margin: 0.15rem;
  color: #1a1717 !important;
}
.banner-header .p-button-icon-only:hover {
  background-color: transparent !important;
}
.banner-header .p-button-icon-only:focus {
  box-shadow: unset !important;
}

/* Mobile: Modal */
.modal-mobile .p-dialog {
  box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.05);
  width: 100%;
  margin: 0 !important;
  border-radius: 22px 22px 36px 36px;
}
.modal-mobile .p-dialog .p-dialog-header {
  border-top-right-radius: 22px;
  border-top-left-radius: 22px;
  border: 0;
  font-size: 1rem;
  font-weight: 600;
  color: #444444;
}
.modal-mobile .p-dialog .p-dialog-header .p-dialog-header-close {
  position: absolute;
  right: 10px;
}
.modal-mobile .p-dialog .p-dialog-content {
  border-bottom-right-radius: 2.334rem;
  border-bottom-left-radius: 2.334rem;
}
.modal-mobile .modal-top.p-dialog {
  height: 3.7485rem;
  box-shadow: none;
  border-radius: 36px 36px 0 0;
}
.modal-mobile .modal-top.p-dialog .p-dialog-content {
  padding: 0.3rem 1rem;
  border-radius: 36px 36px 0 0;
  overflow: unset;
  display: flex;
  background-color: var(--primaryColor);
}
.modal-mobile .modal-top.p-dialog .p-dialog-content .p-button-icon-only {
  color: var(--fontColor) !important;
}
.modal-mobile .modal-top.p-dialog .p-dialog-content .clear-search {
  color: #8a8a8a !important;
  background-color: transparent !important;
  border-color: transparent !important;
}
.modal-mobile .appinfo-logo {
  height: 1.2rem;
}

/* Mobile: Primary Bottom Menu */
.mobile-bottommenu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  height: 4.5815rem;
  box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.05);
  vertical-align: middle;
  background: #ffffff;
  text-align: center;
  z-index: 1;
  padding: 0 0.5rem;
  overflow: auto;
  flex-flow: nowrap;
}
.mobile-bottommenu.bottom-page {
  z-index: 2;
}
.mobile-bottommenu::-webkit-scrollbar {
  display: none;
}
.mobile-bottommenu .col {
  width: 20%;
  cursor: pointer;
}
.mobile-bottommenu .col * {
  cursor: pointer;
}
.mobile-bottommenu i {
  font-size: 20px;
  color: #8a8a8a;
}
.mobile-bottommenu label {
  color: #8a8a8a;
  font-size: 0.833rem;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mobile-bottommenu .text-active {
  color: var(--primaryColor);
}
.mobile-bottommenu .p-badge-dot {
  min-width: 0.667rem !important;
  height: 0.667rem !important;
}

/* Mobile: View on Editor */
.preview-app {
  display: flex;
  align-items: center;
}
.preview-app .mobile-bottommenu {
  position: absolute;
  border-radius: 0px 0px 2.3rem 2.3rem;
}
.preview-app .mobile-header {
  position: absolute;
  border-radius: 2.3rem 2.3rem 0 0;
}
.preview-app .banner-header {
  position: absolute;
  border-radius: 0.432rem 0.432rem 0 0;
}
.preview-app .modal-mobile .p-dialog-mask {
  position: absolute;
}
.preview-app .btn-float {
  position: absolute;
}
.preview-app .splash-screen {
  position: absolute;
  border-radius: 2.3rem;
}
.preview-app .camera-container .camera-focus {
  border-width: 14rem 4rem !important;
  border-radius: 2.3rem;
}
.preview-app .camera-container .qr-scanner video {
  border-radius: 2.3rem;
}

/* Mobile: General Component */
.mobile-comp {
  display: grid;
  padding-top: 4.5815rem;
  padding-bottom: 4.5815rem;
}
.mobile-comp.horz {
  overflow-x: scroll;
  display: flex;
  scroll-snap-type: x mandatory;
}
.mobile-comp.horz > * > * {
  scroll-snap-align: center;
}
.mobile-comp.horz .comp-col-1 {
  grid-template-columns: repeat(1000, 16rem) !important;
}
.mobile-comp.horz .comp-col-2 {
  grid-template-columns: repeat(1000, 12rem) !important;
}
.mobile-comp.horz .comp-col-3 {
  grid-template-columns: repeat(1000, 8rem) !important;
}
.mobile-comp.horz .comp-col-4 {
  grid-template-columns: repeat(1000, 4rem) !important;
}
.mobile-comp::-webkit-scrollbar {
  display: none;
}
.mobile-comp.mobile-form {
  padding-left: 0.833rem;
  padding-right: 0.833rem;
  overflow-wrap: anywhere;
}
.mobile-comp.mobile-form .field {
  margin-bottom: 1rem;
}
.mobile-comp.mobile-form .p-fluid .field:last-child {
  margin-bottom: 2.7525rem;
}
.mobile-comp.mobile-form .p-button {
  padding: 0.6rem 1.5rem;
}
.mobile-comp.mobile-form .p-button .p-button-icon {
  filter: brightness(8);
  display: flex;
  font-size: 1.25rem;
  width: 1.25rem;
}
.mobile-comp.mobile-form .p-float-label label {
  font-size: 1rem;
}
.mobile-comp.mobile-form .p-inputtext:enabled:focus + label,
.mobile-comp.mobile-form .p-float-label input.p-filled ~ label {
  top: 18%;
}
.mobile-comp.mobile-form p-inputnumber .p-inputtext:enabled:focus {
  margin-top: 0;
}
.mobile-comp.mobile-form .p-datepicker {
  right: 0.833rem;
}
.mobile-comp.mobile-form p-calendar.p-inputwrapper-focus ~ label,
.mobile-comp.mobile-form p-calendar.p-inputwrapper-filled ~ label {
  margin-top: 1rem;
}
.mobile-comp.mobile-form .p-inputgroup.upload-field .p-inputtext {
  margin-top: inherit !important;
}
.mobile-comp.mobile-form .p-inputgroup.upload-field .p-fileupload .p-button .p-button-icon {
  margin-right: 0;
}
.mobile-comp.mobile-form p {
  color: #71787F;
}
.mobile-comp.mobile-form p.form-label {
  margin-bottom: 0.33rem;
}
.mobile-comp.mobile-form p.form-label.form-label-title {
  margin-bottom: 1.25rem;
  margin-top: 2rem;
}
.mobile-comp.mobile-form .p-button {
  font-family: "Poppins";
  display: inline-block;
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--fontColor);
}
.mobile-comp.mobile-form .p-button .p-button-label {
  display: none;
}
.mobile-comp.mobile-form a {
  color: var(--primaryColor);
}
.mobile-comp.mobile-form .p-radiobutton .p-radiobutton-box.p-highlight,
.mobile-comp.mobile-form .p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primaryColor);
  background: var(--primaryColor);
}
.mobile-comp.mobile-form .p-button.p-button-secondary2,
.mobile-comp.mobile-form .p-buttonset.p-button-secondary2 > .p-button,
.mobile-comp.mobile-form .p-splitbutton.p-button-secondary2 > .p-button {
  color: #8a8a8a !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}
.mobile-comp.mobile-form .camera-container {
  background-color: #000000;
}
.mobile-comp.mobile-form .camera-container .mobile-header.camera-header {
  z-index: 4;
  background: transparent;
  box-shadow: none;
}
.mobile-comp.mobile-form .camera-container .mobile-header.camera-header .p-button-icon-only, .mobile-comp.mobile-form .camera-container .mobile-header.camera-header .title-page {
  color: #FFFFFF !important;
}
.mobile-comp.mobile-form .camera-container .camera-focus {
  position: absolute;
  z-index: 3;
  border-width: 13.75rem 4rem;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  inset: 0;
}
.mobile-comp.mobile-form .camera-container .camera-focus .camera-desc {
  display: flex;
  position: relative;
  justify-content: center;
  text-align: center;
  top: -4rem;
  color: #CCCCCC;
}
.mobile-comp.mobile-form .camera-container .camera-focus:before {
  content: "";
  /* make the element cover the whole thing */
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* and sit in front */
  z-index: 1;
  /* gradient with a radar like fade */
  background: linear-gradient(90deg, transparent, transparent, rgba(255, 255, 255, 0.25) 55%, rgba(255, 255, 255, 0.5) 56%, transparent, transparent);
  /* make it big enough so when we slide it around still covers whole image */
  background-size: 300% 100%;
  background-repeat: no-repeat;
  /* start with the scan bar just off the left side */
  background-position: 84% center;
  animation: scan 5s infinite ease;
}
.mobile-comp.mobile-form .camera-container .camera-focus .overlay {
  --border-style: 0.35rem solid var(--primaryColor);
  --border-space: -0.15rem;
}
.mobile-comp.mobile-form .camera-container .camera-focus .overlay .overlay-element {
  position: absolute;
  z-index: 1;
  width: 4rem;
  height: 4rem;
}
.mobile-comp.mobile-form .camera-container .camera-focus .overlay .top-left {
  border-left: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  left: var(--border-space);
  border-top-left-radius: 0.5rem;
}
.mobile-comp.mobile-form .camera-container .camera-focus .overlay .top-right {
  border-right: var(--border-style);
  border-top: var(--border-style);
  top: var(--border-space);
  right: var(--border-space);
  border-top-right-radius: 0.5rem;
}
.mobile-comp.mobile-form .camera-container .camera-focus .overlay .bottom-left {
  border-left: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  left: var(--border-space);
  border-bottom-left-radius: 0.5rem;
}
.mobile-comp.mobile-form .camera-container .camera-focus .overlay .bottom-right {
  border-right: var(--border-style);
  border-bottom: var(--border-style);
  bottom: var(--border-space);
  right: var(--border-space);
  border-bottom-right-radius: 0.5rem;
}
@keyframes scan {
  /* finish with the scan bar just off the right side */
  100% {
    background-position: 25% center;
  }
}
.mobile-comp.mobile-form .camera-container .qr-scanner {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mobile-comp.mobile-form .camera-container .qr-scanner .origin-overlay span {
  font-size: 1rem !important;
  font-family: Poppins, sans-serif !important;
  color: var(--primaryColor) !important;
}
.mobile-comp.mobile-form .camera-container .qr-scanner canvas {
  height: inherit;
}
.mobile-comp.mobile-form .p-dropdown .p-dropdown-label.p-placeholder {
  color: #b5babd;
}
.mobile-comp.mobile-form .field-checkbox, .mobile-comp.mobile-form .field-radiobutton {
  margin-bottom: 1.25rem;
  align-items: start;
}
.mobile-comp.mobile-form .field-checkbox:last-child, .mobile-comp.mobile-form .field-radiobutton:last-child {
  margin-bottom: 2rem;
}
.mobile-comp.mobile-form .field-checkbox > label, .mobile-comp.mobile-form .field-radiobutton > label {
  color: #495057;
  line-height: 1.25;
}
.mobile-comp.mobile-form .form-editor .p-editor-container .p-editor-content.ql-snow {
  min-height: 120px;
  background: #FFFFFF;
}
.mobile-comp.mobile-form .form-editor .p-editor-container .p-editor-content.ql-snow .ql-editor {
  line-break: anywhere;
}
.mobile-comp.mobile-form sub.text-magenta {
  vertical-align: baseline;
  margin-left: 0.167rem;
}
.mobile-comp.mobile-form .p-map {
  display: flex;
  width: 100%;
  height: 10rem;
  position: relative;
  border-radius: 0.5rem;
}

.container-info {
  margin-bottom: 0 !important;
}
.container-info .detail-info {
  justify-content: space-between;
  display: flex;
}
.container-info .detail-info .page-info > a {
  color: var(--primaryColor);
}

.profile-card, .container-info {
  margin: 4.5815rem 0.833rem 0.833rem;
}
.profile-card + .mobile-comp, .container-info + .mobile-comp {
  padding-top: 0;
}
.profile-card + .container-info, .container-info + .container-info {
  margin-top: 0;
}

.comp-col {
  display: grid;
  column-gap: 0.0835rem;
}
.comp-col > * {
  margin-bottom: 0.0835rem;
}
.comp-col.comp-col-1 {
  grid-template-columns: repeat(1, 1fr);
}
.comp-col.comp-col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.comp-col.comp-col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.comp-col.comp-col-4 {
  grid-template-columns: repeat(4, 1fr);
}
.comp-col.loose {
  padding: 0 0.833rem;
  column-gap: 0.833rem;
}
.comp-col.loose > * {
  margin-bottom: 0.833rem;
}
.comp-col.rounded > * {
  border-radius: 0.667rem;
}
.comp-col.rounded > * .top-left-label .label-text {
  border-bottom-right-radius: 0.667rem;
}
.comp-col.rounded > * .top-right-label .label-text {
  border-bottom-left-radius: 0.667rem;
}
.comp-col.rounded > * .top-center-label .label-text {
  border-bottom-right-radius: 0.667rem;
  border-bottom-left-radius: 0.667rem;
}
.comp-col.outline > *::after {
  content: "";
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0%;
  width: 100%;
  height: 100%;
  border-radius: 0;
  border: 0.0835rem solid rgba(150, 150, 150, 0.12);
  box-sizing: border-box;
}
.comp-col.outline.rounded > *::after {
  border-radius: 0.667rem;
}
.comp-col.float > * {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 0.5rem;
}
.comp-col.group-list {
  margin: 0 0.833rem 0.833rem;
  padding: 0.33rem 0;
  border-radius: 0.667rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0.2475 0.2475rem 0.833rem;
  background-color: #ffffff;
}
.comp-col.group-list > * {
  margin-right: 0.833rem;
  margin-left: 0.833rem;
}
.comp-col.group-list > *::after {
  content: "";
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0%;
  width: 100%;
  height: 100%;
  border-radius: 0;
  border-bottom: 0.0835rem solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
}
.comp-col.group-list > *:last-child::after {
  border-bottom: none;
}
.comp-col.summary-group-list.comp-col > *, .comp-col.summary-group-list .comp-col > * {
  margin-bottom: 0;
}
.comp-col.summary-group-list.total-bottom > .total-summary-group {
  order: 2;
  bottom: 4.6rem;
  top: unset;
}
.comp-col.summary-group-list .total-summary-group {
  position: sticky;
  position: -webkit-sticky;
  top: 3.75rem;
  z-index: 1;
  border-top: 1px solid #FFFFFF;
}
.comp-col.summary-group-list .total-summary-group .card-content, .comp-col.summary-group-list .total-summary-group .ava-detail {
  background: var(--primaryColor);
  color: var(--fontColor) !important;
}
.comp-col.summary-group-list .summary-group-container .summary-group {
  background: #FFFFFF;
}
.comp-col.summary-group-list .summary-group-container .summary-group .title-summary-group .card-content, .comp-col.summary-group-list .summary-group-container .summary-group .title-summary-group .ava-detail {
  background: var(--secondaryColor);
  color: var(--fontColor) !important;
}
.comp-col.summary-group-list .summary-group-container .summary-group .summary-subgroup-list .title-summary-subgroup .card-content {
  background: #F7F7F7;
}
.comp-col.summary-group-list .summary-group-container .summary-group .summary-subgroup-list .summary-subgroup > * {
  border-bottom: 0.04rem solid #EFEFEF !important;
}
.comp-col.summary-group-list .summary-group-container .summary-group .summary-subgroup-list .summary-subgroup > *:last-child {
  border: 0;
}
.comp-col.summary-group-list .card-content .card-detail .detail-right .avatar > .card-ava-container:last-child {
  min-width: fit-content;
  text-align: right;
}
.comp-col.summary-group-list.card-group {
  padding: 0 0.833rem;
}
.comp-col.summary-group-list.card-group.total-bottom > :first-child {
  order: 2;
  bottom: 5.5rem;
  top: unset;
}
.comp-col.summary-group-list.card-group .total-summary-group {
  top: 4.5rem;
  border-top: none;
  border-radius: 0.667rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 0.5rem;
}
.comp-col.summary-group-list.card-group .summary-group-container .summary-group {
  padding-bottom: 0.5rem;
  margin-bottom: 0.833rem;
  background: transparent;
}
.comp-col.summary-group-list.card-group .summary-group-container .summary-group .title-summary-group {
  border-top-left-radius: 0.667rem;
  border-top-right-radius: 0.667rem;
}
.comp-col.summary-group-list.card-group .summary-group-container .summary-group > * {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 0.5rem;
}
.comp-col.align-right .card-header,
.comp-col.align-right .card-title,
.comp-col.align-right .card-desc {
  text-align: right;
}
.comp-col.align-center .card-header,
.comp-col.align-center .card-title,
.comp-col.align-center .card-desc {
  text-align: center;
}
.comp-col.text-lower .card-title,
.comp-col.text-lower .ava-title {
  text-transform: lowercase !important;
}
.comp-col.text-upper .card-title,
.comp-col.text-upper .ava-title {
  text-transform: uppercase !important;
}
.comp-col.fs-md .card-title {
  font-size: 1.167rem !important;
  line-height: 1.6rem !important;
}
.comp-col.fs-md .card-desc {
  font-size: 1rem;
  line-height: 1.2rem;
}
.comp-col.fs-lg .card-title {
  font-size: 1.333rem !important;
  line-height: 1.7rem !important;
}
.comp-col.fs-lg .card-desc {
  font-size: 1.167rem;
  line-height: 1.6rem;
}
.comp-col.shape-xs .card-img {
  padding-bottom: 33.3333% !important;
}
.comp-col.shape-sm .card-img {
  padding-bottom: 66.6666% !important;
}
.comp-col.shape-md .card-img {
  padding-bottom: 99.9999% !important;
}
.comp-col.shape-lg .card-img {
  padding-bottom: 133.3333% !important;
}
.comp-col.shape-xl .card-img {
  padding-bottom: 166.6666% !important;
}

.btn-back-top {
  position: sticky;
  position: -webkit-sticky;
  top: 3.75rem;
  z-index: 1;
  background: #FFFFFF;
}

.mobile-print {
  zoom: 46.75%;
  box-shadow: 0px -2px 7px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 1rem;
}
.mobile-print .comp-col.summary-group-list .total-summary-group {
  position: unset !important;
  position: unset !important;
}

.card-img {
  position: relative;
}
.card-img .img {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  color: #3b9ef2;
  font-size: 1.333rem;
  text-align: center;
  overflow: hidden;
  background: #eff8fe;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-content {
  display: flex;
  flex-direction: column;
  padding: 0.833rem;
  color: #444444;
  -webkit-box-flex: 1;
  background: #ffffff;
}
.card-content .card-title-container:not(:last-child) {
  margin-bottom: 0.33rem;
}
.card-content .card-title-container .card-header {
  font-weight: 500;
  font-size: 0.667rem;
  line-height: 1rem;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  color: var(--primaryColor);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-content .card-title-container .card-title {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 1rem;
  line-height: 1.2rem;
  font-family: Poppins, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.card-content .card-desc {
  font-weight: 400;
  font-size: 0.833rem;
  line-height: 1rem;
  color: #999999;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-family: Poppins, sans-serif;
}
.card-content .card-desc:not(:last-child) {
  margin-bottom: 0.5rem;
}
.card-content .card-detail {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.card-content .card-detail > :first-child:not(:last-child) {
  margin-left: 0.5rem;
  width: 100%;
}
.card-content .card-detail .detail-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.card-content .card-detail .detail-left + .detail-right {
  min-width: auto;
  max-width: 20%;
  margin-left: 0.5rem;
}
.card-content .card-detail .detail-right {
  display: flex;
  flex-direction: column;
}
.card-content .card-detail .detail-right .avatar {
  align-self: end;
  width: 100%;
}
.card-content .card-detail .detail-right .avatar > .ava-img {
  order: 1;
  margin-left: 0.833rem;
}
.card-content .card-detail .detail-right .avatar > .card-ava-container {
  margin-left: 0;
}
.card-content .card-detail .avatar {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 0.833rem;
  line-height: 1.0625rem;
  font-family: Poppins, sans-serif;
  justify-content: space-between;
}
.card-content .card-detail .avatar > .ava-img {
  width: 2.499rem;
  height: 2.499rem;
  border-radius: 100%;
  object-fit: cover;
  flex-shrink: 0;
  font-size: 1.333rem;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primaryColor);
  background: var(--secondaryColor);
}
.card-content .card-detail .avatar > .ava-img + div {
  margin-left: 0.833rem;
}
.card-content .card-detail .avatar > .ava-img-banner {
  width: 6.499rem;
  height: 3.499rem;
  border-radius: 10%;
  object-fit: cover;
  flex-shrink: 0;
  font-size: 1.333rem;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primaryColor);
  background: var(--secondaryColor);
}
.card-content .card-detail .avatar > .ava-img-banner + div {
  margin-left: 0.833rem;
}
.card-content .card-detail .avatar > div {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-content .card-detail .avatar .card-ava-container {
  display: flex;
  flex-direction: column;
}
.card-content .card-detail .avatar .card-ava-container .ava-title {
  text-transform: capitalize;
  line-height: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-content .card-detail .avatar .card-ava-container .ava-detail {
  font-size: 0.667rem;
  line-height: 1rem;
  color: #999999;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.card-content .card-detail > * {
  min-width: 1.666rem;
  flex-shrink: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.card-content .card-detail > * > * {
  max-width: 100%;
}
.card-content .cart-inputnumber {
  margin-top: 0.5rem;
}
.card-content .cart-inputnumber .pi {
  font-size: 0.667rem;
}
.card-content .cart-inputnumber .p-inputtext {
  font-size: 0.833rem;
  text-align: center;
  padding: 0.167rem;
  background: #F7F7F7;
  width: 2rem;
  border-width: 1px 0px !important;
}
.card-content .cart-inputnumber .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: #E2E2E2;
}
.card-content .cart-inputnumber .p-button {
  color: var(--primaryColor);
  background: #F7F7F7;
  border: 1px solid #E2E2E2;
}
.card-content .cart-inputnumber .p-button.p-button-icon-only {
  width: 1.333rem;
  padding: 0.167rem;
}
.card-content .cart-inputnumber .p-button.p-button-icon-only .p-button-icon {
  filter: none;
  display: inherit;
  font-size: 0.667rem;
  width: auto;
}

.card-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  pointer-events: none;
  overflow: hidden;
  border-radius: inherit;
}
.card-overlay .label-overlay {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.card-overlay .label-overlay .label-content {
  display: flex;
  flex-direction: column;
  min-width: 1.666rem;
  flex-shrink: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
}
.card-overlay .label-overlay .label-content.top-left-label {
  align-items: flex-start;
}
.card-overlay .label-overlay .label-content.top-right-label {
  align-items: flex-end;
}
.card-overlay .label-overlay .label-content.top-center-label {
  align-items: center;
}
.card-overlay .label-overlay .label-content > * {
  max-width: 100%;
  pointer-events: auto;
}
.card-overlay .label-overlay .label-content .label-text {
  max-width: 70%;
  font-weight: 500;
  font-size: 0.667rem;
  line-height: 1rem;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  padding: 0.167rem 1rem 0.167rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none !important;
  background-color: var(--primaryColor);
  color: var(--fontColor);
}

.sticky-bottom {
  position: absolute;
  bottom: 4.6rem;
  width: 100%;
  background-color: var(--primaryColor);
  color: var(--fontColor);
  padding: 0.5rem;
  align-items: center;
}

/* Mobile: List Component */
.card.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.833rem;
  border-radius: 0.833rem;
  box-shadow: 0 0.0835rem 0.33rem #dddddd;
  margin-bottom: 1rem;
}
.card.list .card-img {
  width: 3.674rem;
  height: 3.674rem;
  border-radius: 2.505rem;
  margin-right: 0.8rem;
}
.card.list .card-title {
  margin-bottom: 0.5rem;
}
.card.list .card-text {
  font-size: 0.65rem;
}

.card-list {
  position: relative;
}
.card-list.card-list-default {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.card-list.card-list-default .card-detail .avatar > .ava-img {
  width: 3.332rem;
  height: 3.332rem;
}
.card-list.card-list-default .card-detail .avatar .card-ava-container .ava-title {
  font-size: 1rem;
  line-height: 1.6rem;
}
.card-list.card-list-default .card-detail .avatar .card-ava-container .ava-detail {
  font-size: 0.833rem;
  line-height: 1rem;
}
.card-list.card-list-default .card-detail > :first-child:not(:last-child) {
  margin-right: 0.4175rem;
}
.card-list.card-list-default .card-detail > * {
  min-width: 3.332rem;
}
.card-list.card-list-default.cart-list .avatar {
  width: 100%;
}
.card-list.card-list-default.cart-list .avatar .ava-img {
  border-radius: 0.33rem;
}
.card-list.card-list-default.cart-list .avatar .card-ava-container {
  width: 100%;
}
.card-list.card-list-default.cart-list .avatar .card-ava-container .ava-price {
  color: var(--primaryColor);
}
.card-list.list-type1:nth-child(3n+1) {
  border-left: 4px solid #ff7b54;
}
.card-list.list-type1:nth-child(3n+2) {
  border-left: 4px solid #14cfff;
}
.card-list.list-type1:nth-child(3n+3) {
  border-left: 4px solid #6ed5a1;
}
.card-list.list-type1:last-child {
  margin-bottom: 0.833rem;
}
.card-list.list-type1 .avatar > .ava-img {
  border-radius: 0.33rem;
}

/* Mobile: Grid Component */
.card-grid {
  position: relative;
}
.card-grid.card-grid-default {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.card-grid.card-grid-default .card-img {
  width: 100%;
  padding-bottom: 33.3333%;
}
.card-grid.card-grid-default .card-content {
  background-color: white;
  color: #444444;
  flex-grow: 1;
}
.card-grid.card-grid-overlay .card-img {
  padding-bottom: 33.3333%;
  overflow: hidden;
  border-radius: inherit;
}
.card-grid.card-grid-overlay .card-img .img {
  border-radius: inherit;
}
.card-grid.card-grid-overlay .card-overlay {
  background: rgba(0, 0, 0, 0.4);
}
.card-grid.card-grid-overlay .card-overlay .card-content {
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: column;
  color: #ffffff;
  background: transparent;
}
.card-grid.card-grid-overlay .card-overlay .card-content .card-header,
.card-grid.card-grid-overlay .card-overlay .card-content .card-desc,
.card-grid.card-grid-overlay .card-overlay .card-content .avatar .ava-detail {
  color: #ffffff;
}

/* Mobile: Details Component */
.card-list-details .card-content {
  padding: 0.833rem 0;
}
.card-list-details .card-content .card-detail .avatar {
  display: block;
  width: 100%;
}
.card-list-details .card-content .card-detail .avatar .card-ava-container {
  padding: 0 1.167rem;
}
.card-list-details .card-content .card-detail .avatar .card-ava-container > * {
  margin-top: 0.5rem !important;
}
.card-list-details .card-content .card-detail .avatar .card-ava-container .ava-title {
  line-height: 1.6rem;
  font-size: 1.167rem;
  font-weight: 600;
  white-space: normal;
  text-overflow: unset;
  overflow-wrap: anywhere;
}
.card-list-details .card-content .card-detail .avatar .card-ava-container .ava-detail {
  line-height: 1.2rem;
  white-space: normal;
  text-overflow: unset;
}
.card-list-details .card-content .card-detail .avatar .card-ava-container .ava-detail.link {
  color: var(--primaryColor);
}
.card-list-details .card-content .card-detail .avatar .card-ava-container .ava-detail .editor-content-only.p-editor-container .p-editor-content .ql-editor {
  font-size: 0.833rem;
  color: #999999;
}
.card-list-details .card-content .card-detail .avatar .card-ava-container .ava-detail .media-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.card-list-details .card-content .card-detail .avatar .card-ava-container .ava-detail .media-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.card-list-details .card-content .card-detail .avatar .card-ava-container .ava-detail .map-detail {
  display: flex;
  width: 100%;
  height: 10rem;
  position: relative;
  border-radius: 0.5rem;
  z-index: 0;
}
.card-list-details .card-content .card-detail .avatar .card-ava-container .detail-image {
  flex-shrink: 0;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  height: 100%;
  width: 100%;
}
.card-list-details .card-content .card-detail .avatar .card-ava-container hr {
  border-width: 0.0835rem 0 0 0;
  margin: 0.5rem 0;
}
.card-list-details .card-content .card-detail .avatar .card-ava-container .btn-details {
  width: 100%;
  display: block;
  margin: 0.5rem 0;
  text-overflow: ellipsis;
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
}

.avatar-string {
  vertical-align: middle;
  width: 4.175rem;
  height: 4.175rem;
  border-radius: 50%;
  text-align: center;
  background: aliceblue;
  font-weight: bolder;
  font-size: x-large;
  padding: 1.4195rem;
}

.m-cal .p-calendar .p-datepicker {
  max-width: 143%;
  min-height: 100%;
}
.m-cal .p-datepicker table td {
  padding: 0;
}
.m-cal .p-datepicker table td > span {
  width: 2.35rem;
  height: 2rem;
}

.p-inputgroup.mobile-search {
  border: 1px solid #e2e2e2;
  border-radius: 2rem;
  padding: 0.25rem;
  background-color: #ffffff;
}
.p-inputgroup.mobile-search .p-inputtext {
  border: none;
  border-radius: 2rem;
}
.p-inputgroup.mobile-search .p-button-icon-only, .p-inputgroup.mobile-search .p-button-icon-only:hover {
  border-radius: 2rem;
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--fontColor);
}
.p-inputgroup.mobile-search .clear-search {
  position: absolute;
  right: 6.825rem;
  z-index: 1;
}
.p-inputgroup.banner-search {
  border: 1px solid #e2e2e2;
  border-radius: 0.5rem;
  padding: 0.05rem;
  background-color: #ffffff;
  height: 3rem;
}
.p-inputgroup.banner-search .p-inputtext {
  border: none;
  border-radius: 0.5rem;
}
.p-inputgroup.banner-search .p-button-icon-only {
  border-radius: 2rem;
  height: 2.5rem;
  width: 2.5rem;
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--fontColor);
}
.p-inputgroup.banner-search .clear-search {
  position: absolute;
  right: 2.825rem;
  z-index: 1;
  color: #8a8a8a !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.form-inline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: pre;
}
.form-inline > div {
  padding: 0;
  padding-right: 0.5rem;
  white-space: normal;
}
.form-inline sub {
  display: block;
}

.left-sidebar {
  position: fixed;
  overflow: hidden;
  z-index: 2;
  width: 250px;
  background-color: #f7f7f7;
}
.left-sidebar .menu-logo {
  padding: 15px 10px;
  border-radius: unset;
  text-align: center;
  margin-bottom: 0 !important;
  line-height: 0;
  border-right: 1px solid #dee2e6;
  background-color: #ffffff;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
  position: sticky;
  z-index: 1;
}
.left-sidebar .menu-list {
  border-radius: unset;
  padding: 0;
  height: calc(100vh - 219px) !important;
  overflow-y: auto;
}
.left-sidebar .menu-list .p-panelmenu .p-panelmenu .p-menuitem-text {
  line-break: anywhere;
  padding-right: 20px;
}
.left-sidebar .menu-list .p-panelmenu .p-panelmenu-header > a {
  border-radius: 0 !important;
  color: #444444;
  font-weight: 400;
  background-color: #f7f7f7;
}
.left-sidebar .menu-list .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  display: inline;
  text-align: right;
  float: right;
  padding-left: 5px;
  position: absolute;
  right: 10px;
  font-size: 12px;
  color: #8a8a8a;
}
.left-sidebar .menu-list .p-panelmenu .p-panelmenu-header > a:hover, .left-sidebar .menu-list .p-panelmenu .p-panelmenu-header > a[aria-expanded=true] {
  border-color: #dee2e6;
  background-color: #e5e5e5;
  color: #444;
}
.left-sidebar .menu-list .p-panelmenu .p-panelmenu-content {
  padding: 0;
}
.left-sidebar .menu-list .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 1rem 1rem 1rem 3rem;
  border-radius: 0;
  transition: box-shadow 0.15s;
  -webkit-user-select: none;
  user-select: none;
  background-color: #efefef;
}
.left-sidebar .menu-list .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text,
.left-sidebar .menu-list .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: #444444;
}
.left-sidebar .menu-list .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link.p-menuitem-link-active {
  background-color: #ffffff;
}
.left-sidebar .menu-list .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link.p-menuitem-link-active .p-menuitem-text,
.left-sidebar .menu-list .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link.p-menuitem-link-active .p-menuitem-icon {
  color: #084b83;
}
.left-sidebar .menu-list .p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:hover {
  border-color: #dee2e6;
  background-color: #e5e5e5;
  color: #444;
}
.left-sidebar .left-sidebar-button {
  position: absolute;
  display: flex;
  height: 100%;
  top: 0;
  align-items: center;
  left: 20px;
  cursor: pointer;
}
.left-sidebar.hide-sidebar {
  width: 50px;
}
.left-sidebar.hide-sidebar .menu-logo {
  padding: 1.75rem;
}
.left-sidebar.hide-sidebar .menu-logo img {
  display: none;
}
.left-sidebar.hide-sidebar .menu-list {
  height: calc(100vh - 195px) !important;
  overflow-x: hidden;
}
.left-sidebar.hide-sidebar .menu-list .p-panelmenu .p-menuitem-text,
.left-sidebar.hide-sidebar .menu-list .p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  display: none;
}
.left-sidebar.hide-sidebar .menu-list .p-panelmenu .p-menuitem-link,
.left-sidebar.hide-sidebar .menu-list .p-panelmenu .p-panelmenu-header-link {
  padding: 1.25rem !important;
}
.left-sidebar.hide-sidebar .menu-list .p-panelmenu .p-menuitem-link .p-menuitem-icon,
.left-sidebar.hide-sidebar .menu-list .p-panelmenu .p-menuitem-link .p-panelmenu-icon,
.left-sidebar.hide-sidebar .menu-list .p-panelmenu .p-panelmenu-header-link .p-menuitem-icon,
.left-sidebar.hide-sidebar .menu-list .p-panelmenu .p-panelmenu-header-link .p-panelmenu-icon {
  margin: auto;
}
.left-sidebar.hide-sidebar .menu-list .p-panelmenu .p-menuitem-link:hover .p-menuitem-text,
.left-sidebar.hide-sidebar .menu-list .p-panelmenu .p-panelmenu-header-link:hover .p-menuitem-text {
  display: block;
  position: fixed;
  z-index: 1;
  left: 40px;
  width: auto;
  background-color: rgba(73, 80, 87, 0.9);
  color: #ffffff !important;
  text-align: center;
  border-radius: 4px;
  padding: 5px 10px;
}
.left-sidebar.hide-sidebar + .content-box {
  margin-left: 50px;
  width: calc(100vw - 50px);
}

.content-box {
  margin-left: 250px;
  width: calc(100vw - 250px);
  overflow: hidden;
}

.project-properties {
  top: 110px;
  position: fixed;
  width: calc(100vw - 80px - 261px);
  height: calc(100vh - 12.75em);
  right: calc(80px + 261px);
  border-radius: 0;
  border: 1px solid #cccccc;
}
.project-properties.p-dialog .p-dialog-header,
.project-properties.p-dialog .p-dialog-content {
  border-radius: 0;
}
.project-properties.p-dialog .p-dialog-content {
  height: 100%;
}
.project-properties.p-dialog .p-dialog-footer {
  border-top: 1px solid #e9ecef;
  padding: 1rem;
  text-align: right;
}

::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: inherit;
}

::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 25px;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar {
  width: 0;
  height: 7px;
}

.user-image {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  object-fit: cover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.card-image {
  max-width: 100%;
  max-height: 250px;
  border-radius: 8px;
  object-fit: cover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.videoLocal {
  width: 100%;
  height: 400px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
}

.local {
  position: fixed;
  z-index: 1;
}

.remote {
  position: relative;
  bottom: 390px;
  left: 10px;
  width: 150px;
  height: 200px;
  margin-bottom: -200px;
}
.remote > div {
  border-radius: 5px;
}

.unremote {
  width: 100%;
  height: 400px;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
}

.videoLocal-fullscreen {
  width: 100%;
  height: calc(100vh - 11rem);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  background: #000000;
}
.videoLocal-fullscreen video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain !important;
}

.remote-fullscreen {
  position: relative;
  bottom: 390px;
  left: 10px;
  width: 150px;
  height: 200px;
  margin-bottom: -200px;
}
.remote-fullscreen video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain !important;
}

.unremote-fullscreen {
  width: 100%;
  height: calc(100vh - 19rem);
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
}
.unremote-fullscreen video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain !important;
}

.label-black,
.label-telur-asin,
.label-telur-asin-muda,
.label-biru-muda,
.label-grey,
.label-softgrey,
.label-ungu,
.label-red,
.label-yellow,
.label-green,
button.label.p-button-danger {
  padding: 0.5rem 1.5rem;
  color: white;
  border-radius: 4px;
  border-color: transparent;
  display: flex;
  margin: auto;
  width: fit-content;
  text-align: center;
}

.label-black {
  background: #000000;
}

.label-telur-asin {
  background: #387f88;
}

.label-telur-asin-muda {
  background: #60beac;
}

.label-biru-muda {
  background: #589ceb;
}

.label-grey {
  background: #808080;
}

.label-softgrey {
  background: #f7f7f7;
  color: #a2a2a2;
}

.label-ungu {
  background: #8c81ed;
}

.label-red {
  background: #dc3545;
}

.label-yellow {
  background: #e6cb03;
}

.label-green {
  background: #ffc107;
}

.tab-editor {
  display: none;
}
.tab-editor .p-tabmenu .p-tabmenu-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.tab-editor .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
  width: 50vw;
}
.tab-editor .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  height: 60px;
  justify-content: center;
  border-radius: unset;
}
.tab-editor .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: rgba(0, 122, 217, 0.05) !important;
  color: #444 !important;
  border-color: #dee2e6;
}

.tabview-standard.p-tabview .p-tabview-nav {
  display: table;
  width: 100%;
}
.tabview-standard.p-tabview .p-tabview-nav li {
  margin-right: 0;
  display: table-cell;
}
.tabview-standard.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  justify-content: center;
  font-size: 14px;
  padding: 12px;
  background: transparent;
}
.tabview-standard.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.tabview-standard.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #4394d8;
  color: #4394d8;
}
.tabview-standard.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  display: none;
}
.tabview-standard.p-tabview .p-tabview-panels {
  background: transparent;
}
.tabview-standard.tab-sticky-modal .p-tabview-panels {
  max-height: calc(100vh - 300px);
  overflow: auto;
}
.tabview-standard.tab-sticky-modal .footer-sticky {
  position: absolute;
  left: 0;
  bottom: -5.5rem;
  width: 100%;
  padding: 1.5rem 2.5rem;
  background: #ffffff;
  border-top: 1px solid #dee2e6 !important;
  border-radius: 0 0 4px 4px;
  text-align: right;
}

.tabview-admin-standard.p-tabview .p-tabview-nav-content {
  width: 100%;
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
}
.tabview-admin-standard.p-tabview .p-tabview-nav {
  display: table;
  width: auto;
}
.tabview-admin-standard.p-tabview .p-tabview-nav li {
  margin-right: 0;
  display: table-cell;
}
.tabview-admin-standard.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  font-size: 12px;
  padding: 12px;
  font-weight: 500;
  background: transparent;
}
.tabview-admin-standard.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}
.tabview-admin-standard.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: #ffffff;
  border-color: #4394d8;
  color: #4394d8;
}
.tabview-admin-standard.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  display: none;
}
.tabview-admin-standard.p-tabview .p-tabview-panels {
  background: transparent;
}

.p-timeline.p-timeline-vertical .p-timeline-event-opposite {
  display: none;
}
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  margin-bottom: 20px;
}

.custom-marker {
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  z-index: 1;
}

.right-toolbar {
  top: 35px;
  position: relative;
  align-items: center;
  justify-content: right;
  margin-top: -30px;
  z-index: 1;
}

.p-tooltip .p-tooltip-text {
  overflow-wrap: anywhere;
}

.p-fileupload.custom-upload .p-fileupload-buttonbar {
  display: none;
}
.p-fileupload.custom-upload .p-fileupload-content {
  border-radius: 4px;
  padding: 0;
}
.p-fileupload.custom-upload .p-fileupload-content .p-fileupload-files + * {
  display: none;
}

.p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

.p-card.card-project-template {
  box-shadow: none;
  cursor: pointer;
  margin: 8px;
  margin-bottom: 0;
}
.p-card.card-project-template .p-card-header {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  padding: 40px;
}
.p-card.card-project-template .p-card-header .img-template {
  height: auto;
  max-width: 80px;
  width: -webkit-fill-available;
  object-fit: cover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.p-card.card-project-template .p-card-body h5 {
  white-space: nowrap;
  overflow: inherit;
  text-overflow: ellipsis;
}
.p-card.card-project-template .p-card-body .p-card-subtitle {
  font-size: 14px;
  margin: 0;
}
.p-card.card-project-template:hover .p-card-header {
  transition: all 0.2s;
  background-color: #eff8fd;
  border-color: transparent;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
}
.p-card.card-project-template:hover .p-card-header .img-template {
  background-color: #eff8fd;
}

.p-blockui.p-component-overlay {
  z-index: 3;
}

@keyframes custom-progress-spinner-color {
  100%, 0% {
    stroke: #81ecd8;
  }
  40% {
    stroke: #62c9e2;
  }
  66% {
    stroke: #5e8bea;
  }
  80%, 90% {
    stroke: #084b83;
  }
}
.qrcode img {
  width: 100%;
  display: flex;
}

.splash-screen {
  animation-name: bg-animation;
  animation-duration: 6s;
  position: fixed;
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  overflow: hidden;
  transition: all 0.3s;
  border-radius: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
}
.splash-screen.bg-app {
  animation-name: none;
  animation-duration: none;
  position: absolute;
  visibility: visible;
  opacity: 1;
  z-index: auto;
  height: 100%;
  min-height: 100%;
  width: 100%;
  min-width: 100%;
  overflow: auto;
  transition: none;
}
.splash-screen .img-app {
  max-height: 8rem;
  max-width: 10rem;
  position: absolute;
}
.splash-screen .splash-powered {
  background: #ffffff;
  position: absolute;
  bottom: 3rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}
.splash-screen .splash-powered p {
  font-size: 0.6rem !important;
}
.splash-screen .splash-powered img {
  height: 1rem;
}

.img-app {
  max-height: 8rem;
  max-width: 8rem;
  position: relative;
  display: flex;
  margin: auto;
  margin-bottom: 4rem;
}

.pricing-plan-table .p-datatable-wrapper {
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.pricing-plan-table table tr > * {
  width: 33.3%;
  text-align: center !important;
  border-width: 1px 0 !important;
}
.pricing-plan-table table tr > td:nth-child(1) {
  color: #444444;
  padding-left: 3rem;
  text-align: left !important;
  font-weight: initial !important;
}
.pricing-plan-table table tr > td:nth-child(1).section-title {
  font-weight: 800 !important;
  padding-left: 1rem;
}
.pricing-plan-table table tr > td:nth-child(2) {
  background: #f7f7f7;
  color: #8a8a8a;
}
.pricing-plan-table table tr > td:nth-child(3) {
  background: #e3effa;
  color: #3b9ef2;
}
.pricing-plan-table table tr:first-child {
  position: sticky;
  top: 0;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
}
.pricing-plan-table table tr:first-child > * {
  border: none !important;
  background: #ffffff !important;
}
.pricing-plan-table table tr:first-child > * h2 {
  margin: 0;
}
.pricing-plan-table table tr:first-child > * small {
  font-size: 1.125rem;
  font-weight: 300;
}
.pricing-plan-table table tr:first-child > * p {
  font-size: 0.875rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
  color: #757575;
}
.pricing-plan-table table tr:first-child > td:nth-child(3) h2 {
  color: #222222;
}

.user-menu .p-listbox {
  position: sticky;
  top: 3rem;
}
.user-menu .p-listbox .card.box {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.user-menu .p-listbox .p-listbox-item {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 1.5rem !important;
  margin-bottom: 1.5rem;
}
.user-menu .p-listbox .p-listbox-item:last-child {
  border-bottom: none;
  padding-bottom: 0 !important;
  margin-bottom: 0;
}
.user-menu .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  background: transparent;
  cursor: pointer;
  color: #4394d8 !important;
}
.user-menu > p-tabview > .p-tabview > .p-tabview-nav-container {
  display: none;
}

.p-orderlist.order-details .p-orderlist-controls, .p-orderlist.order-details .p-orderlist-header {
  display: none;
}
.p-orderlist.order-details .p-orderlist-list {
  border: none;
  background: transparent;
  padding: 0;
  min-height: unset;
}
.p-orderlist.order-details .p-orderlist-list .p-orderlist-item {
  border: 1px solid transparent;
  padding: 0;
  overflow: unset;
  position: unset;
  margin-bottom: 2px;
}
.p-orderlist.order-details .p-orderlist-list .p-orderlist-item .p-card.card-details .p-card-content > .grid {
  flex-wrap: nowrap;
}
.p-orderlist.order-details .p-orderlist-list .p-orderlist-item .p-card.card-details .p-card-content > .grid:before {
  content: "";
  font-family: "primeicons";
  font-size: 8px;
  color: #CCCCCC;
  display: flex;
  margin-right: 8px;
  transform: rotate(90deg);
}
.p-orderlist.order-details .p-orderlist-list .p-orderlist-item .p-card.card-details .p-card-content > .grid > span {
  overflow: hidden;
  max-width: 19rem;
  min-width: 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
}
.p-orderlist.order-details .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover, .p-orderlist.order-details .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item.p-highlight {
  border: 1px solid #4494d7;
  border-radius: 4px;
}
.p-orderlist.order-details .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover .p-card.card-details, .p-orderlist.order-details .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item.p-highlight .p-card.card-details {
  background: #ffffff;
}

.p-orderlist-item.cdk-drag-preview {
  height: auto !important;
  padding: 0;
  border-radius: 4px;
}

.p-card.card-details {
  border-radius: 4px;
}
.p-card.card-details .p-card-body {
  padding: 0;
}
.p-card.card-details .p-card-body .p-card-content {
  padding: 6px;
}
.p-card.card-details .p-card-body .p-card-content img {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.2475rem;
  background-color: #ffffff;
  border-radius: 2px;
  margin-right: 6px;
}
.p-card.card-details .p-card-body .p-card-content button {
  margin-left: auto;
  width: auto;
}

.p-overlaypanel.notif-overlay-panel {
  top: 36px !important;
  max-width: 100%;
  min-height: auto;
  max-height: 500px;
  overflow: auto;
}
.p-overlaypanel.notif-overlay-panel .notif-list {
  display: flex;
  align-items: normal;
  border: none;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 0 !important;
  background-color: #eff8fd;
}
.p-overlaypanel.notif-overlay-panel .notif-list .custom-marker {
  position: absolute;
}
.p-overlaypanel.notif-overlay-panel .notif-list .notif-detail {
  width: 100%;
  display: block;
  padding: 3px 0 0 36px;
}
.p-overlaypanel.notif-overlay-panel a:last-child .notif-list {
  border-bottom: none;
}

.p-galleria .p-galleria-close {
  position: absolute;
  top: 0;
  right: 0;
}

.project-banner .p-galleria-item a {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.project-banner .p-galleria-item a .img-banner {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 6px;
}
.project-banner.mobile-galleria .p-galleria-item {
  height: 120px;
}
.project-banner .p-galleria-item-nav {
  position: absolute;
  height: 100%;
  top: 0;
}

.editor-content-only.p-editor-container .p-editor-toolbar {
  display: none;
}
.editor-content-only.p-editor-container .p-editor-content {
  border: none;
}
.editor-content-only.p-editor-container .p-editor-content .ql-editor {
  padding: 0;
}

.import-table-gsheet.p-datatable.p-datatable-scrollable .p-datatable-loading-overlay + .p-datatable-wrapper {
  height: 100px;
}
.import-table-gsheet.p-datatable.p-datatable-scrollable .p-datatable-wrapper {
  max-height: calc(100vh - 300px);
}
.import-table-gsheet.p-datatable .p-datatable-thead > tr > th:nth-child(2) {
  max-width: 70px;
}
.import-table-gsheet.p-datatable .p-datatable-tbody > tr > td:nth-child(2) {
  max-width: 70px;
  justify-content: center;
}

.import-table-gsheet-preview.p-datatable.p-datatable-scrollable .p-datatable-wrapper {
  max-height: calc(100vh - 360px);
}
.import-table-gsheet-preview.p-datatable .p-datatable-thead > tr > th,
.import-table-gsheet-preview.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  min-width: 150px;
  max-width: unset;
  justify-content: unset;
  overflow-wrap: anywhere;
}
.import-table-gsheet-preview.p-datatable .p-datatable-thead > tr > th > div {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.import-table-gsheet-preview.p-datatable .p-datatable-tbody > tr > td:first-child {
  background: #ffffff;
}

.import-tree-gsheet {
  display: flex;
  max-height: calc(100vh - 290px);
  overflow: auto;
}

.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid {
  background: bottom center/contain no-repeat url(/assets/images/img_project-list_new.svg);
  height: calc(100% - 44px);
}
.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid {
  padding: 30px 36px;
}
.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid .p-toolbar-group-left.mr-3 {
  margin: 0 !important;
}
.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid .p-card.card-more {
  background-color: #FFFFFF !important;
}
.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid .p-card.card-more .p-card-body .p-card-content {
  padding: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #dee2e6 !important;
}
.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid .p-card.card-more .p-card-body .p-card-content > .grid.mt-2, .fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid .p-card.card-more .p-card-body .p-card-content > p-divider {
  display: none;
}
.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid .p-card.card-more .p-card-body .p-card-content ui-fibr-form-input {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid .p-card.card-more .p-card-body .p-card-content ui-fibr-form-input > p {
  width: 40%;
}
.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid .p-card.card-more .p-card-body .p-card-content ui-fibr-form-input > p span {
  color: #d8439c;
}
.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid .p-card.card-more .p-card-body .p-card-content ui-fibr-form-input > div {
  width: 60%;
}
.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid .p-card.card-more .p-card-body .p-card-content ui-fibr-form-input > input {
  width: 60%;
  height: fit-content;
}
.fibr-portal ui-fibr-change-password ui-fibr-dynamic-form > .grid form.grid .p-card.card-more .p-card-body .p-card-content ui-fibr-form-input ui-fibr-validator {
  width: 100%;
  text-align: right;
}

ui-fibr-question {
  display: block;
  position: absolute;
  top: -55px;
  left: 0;
  background: #F7F7F7;
  z-index: 9;
}

.p-inputswitch .p-inputswitch-slider {
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  border-radius: 100%;
}

.message-flat .p-message {
  border-width: 0;
}

.shepherd-button {
  margin-right: 0.5rem !important;
}
.shepherd-button.p-button.p-button-secondary2 {
  margin-right: auto !important;
}

.shepherd-footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  padding: 0 2rem 2rem;
}
.shepherd-footer .shepherd-button:last-child {
  margin-right: 0 !important;
}

.shepherd-cancel-icon {
  background: transparent;
  border: none;
  color: rgba(128, 128, 128, 0.75);
  cursor: pointer;
  font-size: 2em;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: color 0.5s ease;
}
.shepherd-cancel-icon:hover {
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-has-title .shepherd-content .shepherd-cancel-icon {
  color: rgba(128, 128, 128, 0.75);
}

.shepherd-title {
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex: 1 0 auto;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.shepherd-header {
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  line-height: 2em;
  padding: 0.75rem 0.75rem 0;
}

.shepherd-has-title .shepherd-content .shepherd-header {
  background: #ffffff;
  padding: 2rem 2rem 0;
}

.shepherd-text {
  color: rgba(0, 0, 0, 0.75);
  font-size: 1rem;
  line-height: 1.3em;
  padding: 2rem;
}
.shepherd-text p {
  margin-top: 0;
}
.shepherd-text p:last-child {
  margin-bottom: 0;
}

.shepherd-content {
  border-radius: 5px;
  outline: none;
  padding: 0;
}

.shepherd-element {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  opacity: 0;
  outline: none;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
}
.shepherd-element.shepherd-enabled {
  opacity: 1;
  visibility: visible;
}
.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.shepherd-element[data-popper-placement^=right] {
  margin-left: 1rem;
}
.shepherd-element[data-popper-placement^=left] {
  margin-left: -1rem;
}
.shepherd-element[data-popper-placement^=top] > .shepherd-arrow {
  bottom: -8px;
}
.shepherd-element[data-popper-placement^=bottom] > .shepherd-arrow {
  top: -8px;
}
.shepherd-element[data-popper-placement^=left] > .shepherd-arrow {
  right: -8px;
}
.shepherd-element[data-popper-placement^=right] > .shepherd-arrow {
  left: -8px;
}
.shepherd-element.shepherd-centered > .shepherd-arrow {
  opacity: 0;
}
.shepherd-element.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow:before {
  background-color: #e6e6e6;
}

.shepherd-element, .shepherd-element *,
.shepherd-element:after, .shepherd-element:before {
  box-sizing: border-box;
}

.shepherd-arrow {
  height: 16px;
  position: absolute;
  width: 16px;
  z-index: -1;
}
.shepherd-arrow:before {
  height: 16px;
  position: absolute;
  width: 16px;
  z-index: -1;
  background: #fff;
  content: "";
  transform: rotate(45deg);
}

.shepherd-target-click-disabled.shepherd-enabled.shepherd-target,
.shepherd-target-click-disabled.shepherd-enabled.shepherd-target * {
  pointer-events: none;
}

.shepherd-modal-overlay-container {
  height: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out, height 0ms 0.3s, opacity 0.3s 0ms;
  width: 100vw;
  z-index: 9997;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible {
  height: 100vh;
  opacity: 0.5;
  transform: translateZ(0);
  transition: all 0.3s ease-out, height 0s 0s, opacity 0.3s 0s;
}
.shepherd-modal-overlay-container.shepherd-modal-is-visible path {
  pointer-events: all;
}

@keyframes bg-animation {
  0% {
    visibility: visible;
    opacity: 1;
  }
  50% {
    visibility: visible;
    opacity: 1;
  }
  99% {
    visibility: visible;
    opacity: 0;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
@media screen and (min-width: 992px) {
  .mt-min {
    margin-top: -10rem;
  }

  .sticky-phone .btn-fullscreen {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background: #CCCCCC;
    justify-content: center;
    height: 2.5rem;
    align-items: center;
    cursor: pointer;
  }
  .sticky-phone .btn-fullscreen + .content {
    padding-top: 2.5rem;
    position: relative;
  }
  .sticky-phone .btn-fullscreen + .content .mobile-header {
    top: 2.5rem;
  }
  .sticky-phone .btn-fullscreen + .content .btn-back-top {
    top: 6.25rem;
  }
  .sticky-phone .btn-fullscreen + .content .comp-col.summary-group-list.card-group .total-summary-group {
    top: 7rem;
  }
}
@media (max-width: 1024px) {
  .login-body .login-content .login-panel .login-panel-content {
    width: 100%;
  }
  .login-body .login-content .login-panel-sifa .login-panel-content {
    width: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  #default-toolbar {
    display: none !important;
  }

  #dropdown-toolbar {
    display: block !important;
  }
}
@media only screen and (min-width: 769px) {
  .data-content,
.preview-content {
    display: flex !important;
  }

  .fibr-portal ui-fibr-change-password ui-fibr-dynamic-form form.grid > .col-12.page-option {
    width: 51%;
    margin: auto;
  }
  .fibr-portal ui-fibr-change-password ui-fibr-dynamic-form form.grid > .col-12.text-right {
    width: 50%;
    margin: auto;
  }
}
@media (max-width: 768px) {
  .login-body {
    height: 100%;
  }
  .login-body .login-content {
    width: 100%;
    flex-direction: row;
  }
  .login-body .login-content .login-panel {
    width: 100% !important;
    overflow: none;
    padding: 3.5rem;
  }
  .login-body .login-content .login-panel .login-panel-content {
    width: 100%;
  }
  .login-body .login-content .login-panel-sifa {
    width: 100% !important;
    overflow: none;
    padding: 3.5rem;
  }
  .login-body .login-content .login-panel-sifa .login-panel-content {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .w-260 {
    width: calc(40vw) !important;
  }

  .tab-editor {
    display: flex !important;
  }

  .editor.table-data {
    width: calc(100vw - 130px);
  }

  .data-table,
.preview-app {
    height: calc(100vh - 214px) !important;
    width: calc(100vw - 80px);
  }

  .page-option.p-sidebar {
    right: 0;
    left: 80px;
    top: 170px;
    height: calc(100vh - 269px);
  }

  .sticky-phone .menu-subtitle {
    display: none;
  }

  .tab-editor + .app-container .p-vmenu p-tabmenu .p-tabmenu-nav {
    height: calc(100vh - 213px);
  }

  .project-properties {
    width: calc(100vw);
    right: 0;
  }

  .sub-header .p-toolbar.p-component {
    z-index: 2;
  }
}
@media only screen and (max-width: 541px) {
  .layout-app {
    margin-top: 40px;
  }

  .top-65 {
    top: 80px !important;
  }

  .data-table,
.preview-app {
    height: calc(100vh - 253px) !important;
  }

  .page-option.p-sidebar {
    top: 210px;
    width: calc(100vw - 80px);
    height: calc(100vh - 309px);
  }
  .page-option .p-card .p-card-body .p-card-footer {
    width: calc(100vw - 80px);
  }

  .content-box {
    margin-left: 50px;
    width: calc(100vw - 50px);
  }

  .remote {
    width: 75px;
    height: 100px;
    margin-bottom: -100px;
  }

  .right-toolbar {
    margin-bottom: 50px;
  }

  .tab-editor + .app-container .p-vmenu p-tabmenu .p-tabmenu-nav {
    height: calc(100vh - 253px);
  }

  .sub-header.editor .p-toolbar.p-component {
    height: 94px;
  }

  .project-properties {
    top: 150px;
    height: calc(100vh - 16.1em);
  }

  .tabview-standard.tab-sticky-modal .p-tabview-panels {
    max-height: calc(100vh - 390px);
  }

  .summary-card {
    flex-direction: column;
    row-gap: 1.5rem;
  }

  .footer .p-footer {
    justify-content: center;
  }
}
@media (max-width: 490px) {
  .display-user {
    display: none !important;
  }
}